import { runInAction, makeAutoObservable } from 'mobx';
import axios from 'axios';
import { defaultListPage } from 'common/config';

class EarningListStore {
  loadingStats = false;

  earningStatsInfo = {
    total_paid: '',
    unpaid: '',
    earnings_today: '',
    earnings_yesterday: '',
  };

  loadingList = false;

  historyList = [];

  historyListTotal = 0;

  historyListPage = defaultListPage;

  constructor() {
    makeAutoObservable(this); //升级mobx 6之后禁止使用装饰器，然后需要使用makeAutoObservable
  }

  getEarningHistoryList = async (coinType, params = {}) => {
    this.loadingList = true;
    let url = `/account/earn-history/multi-addr`;
    if (coinType && coinType.toLowerCase() === 'bcha') {
      url = `/account/bcha/earn-history`;
    }
    const res = await axios.get(url, {
      params: {
        ...this.historyListPage,
        reason: 1,
        is_decimal: 1,
        record_type: coinType,
        ...params,
      },
    });
    if (res && res.data) {
      runInAction(() => {
        this.historyList = res.data;
        this.loadingList = false;
      });
    }
  };

  getEarningStatsInfo = async (coinType, params = {}) => {
    this.loadingStats = true;
    let url = `/account/earn-stats`;
    if (coinType && coinType.toLowerCase() === 'bcha') {
      url = `/account/bcha/earn-stats`;
    }
    const res = await axios.get(url, {
      params: {
        is_decimal: 1,
        record_type: coinType,
        ...params,
      },
    });
    if (res && res.data) {
      runInAction(() => {
        this.earningStatsInfo = res.data;
        this.loadingStats = false;
      });
    }
  };

  setHistoryListPage = (page, page_size) => {
    this.historyListPage = {
      page,
      page_size,
    };
  };

  resetHistoryListPage = () => {
    this.historyListPage = defaultListPage;
  };
}

const earningListStore = new EarningListStore();

export { earningListStore };
