import React, { Component } from 'react';
import BtcModal from '../BtcModal';

class BtcConfirm extends Component {
  render() {
    const { confirmMessage, ...confirmProps } = this.props;

    return (
      <BtcModal maskClosable={false} {...confirmProps}>
        <div>{confirmMessage}</div>
      </BtcModal>
    );
  }
}
export default BtcConfirm;
