import React, { useState, useEffect } from 'react';
import { Pagination } from 'antd';
import { defaultPageSizeOptions } from 'common/config';
import Ts from 'components/LocaleTranslator';
import './index.scss';

const BtcPagination = ({
  current,
  total,
  pageSize: customPageSize,
  onChange,
  isShowTotal = true,
  pageSizeOptions,
  align = 'right',
  showQuickJumper = true,
  showSizeChanger = true,
  isSimple, //only display prev/next
  hideOnSinglePage = false,
  variant = 'normal', //'noraml'|'upgrade'// 改版过渡方案，upgrade为新样式 2021-09-14
  className,
}) => {
  const [pageSize, setPageSize] = useState(customPageSize);
  useEffect(() => {
    setPageSize(customPageSize);
  }, [customPageSize]);
  if (!current || !pageSize) {
    return null;
  }

  return (
    <div
      className={`pagination align-${align} pagination-${variant} ${className}`}
    >
      <Pagination
        defaultCurrent={1}
        defaultPageSize={10}
        current={current}
        showQuickJumper={variant === 'upgrade' ? false : showQuickJumper}
        showSizeChanger={showSizeChanger}
        total={total}
        pageSize={Number(pageSize)}
        showTotal={(total, range) =>
          isShowTotal ? (
            <Ts id="pagination.totalInfo" values={{ total }} />
          ) : null
        }
        pageSizeOptions={
          pageSizeOptions ? pageSizeOptions : defaultPageSizeOptions
        }
        onChange={(page, size) => {
          onChange(Number(size) !== Number(pageSize) ? 1 : page, size);
          setPageSize(size);
        }}
        showLessItems={true}
        simple={isSimple}
        hideOnSinglePage={hideOnSinglePage}
      />
    </div>
  );
};

export default BtcPagination;
