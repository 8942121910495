import flatten from 'flat';
const pageMessage = {
  pages: {
    dashboard: {
      EarningsM: '{coinType} 每M收益',
      accountEarning: '账户收益',
      active: '活跃',
      activeMiner: '活跃矿机',
      amoutPaid: '已支付',
      announcement: '通知公告',
      cannotConnect: '无法连接?',
      urlConfigGpu: ' 支持普通显卡矿机',
      urlConfigNicehash: ' 为Nicehash特别优化',
      urlConfigGpuDcr: ' 已知支持蚂蚁矿机DR3，Claymore，gominer',
      urlConfigNicehashDcr: ' 已知支持Nicehash，芯动科技等矿机',
      customerTitle: '售后服务',
      dailyHashrate: '日算力',
      dashboard: '用户面板',
      earning: '{coinType} 收益',
      eventConnected: '已连接',
      eventDisconnected: '断开连接',
      eventMiner: '矿机',
      eventName: '条消息',
      eventSkip: '已跳过',
      eventTitle: '事件通知',
      eventlatestLogs: '以下为历史消息',
      hastrateChart: '算力图表',
      history: '支付记录',
      hour: '{n} 小时',
      hours: '小时',
      inActive: '非活跃',
      lastPaymentTime: '上一次支付时间',
      markAllRead: '全部标记为已读',
      minerContent:
        '进入挖矿软件配置界面，填入您的子账户（密码建议留空）和挖矿地址，保存后运行exe程序即可，矿机将在一分钟内自动添加到矿池网站页面。',
      minerNameStand:
        '矿机名规则为：子账户+英文句号+编号，例如，您的子账户是{sub_account}, 可以设置矿机名为{sub_account}.001、{sub_account}.002，以此类推，一个矿机名对应一台矿机。',
      minerName: '矿机名设置参考："{sub_account}.001", "{sub_account}.002".',
      miner: '矿机',
      miner1: 'I 类矿机',
      miner2: 'II 类矿机',
      miners: '矿机数',
      miningAddress: '挖矿地址',
      miningEarnings: '{coinType} 算力收益',
      minute: '分钟',
      more: '更多',
      networkDiff: '当前难度',
      networkHashrate: '全网算力',
      networkStatus: '网络状态',
      nextDifficult: '预测下次难度',
      noMiner: '您需要添加矿机到矿池，然后开始挖矿。推荐使用CloverPool',
      btcTools: '代理软件。',
      noEvent: '暂无事件通知!',
      nowHashrate: '当前算力',
      pendingPayouts: '待确认支付',
      poolHashrate: '矿池算力',
      realHashrate: '{  coinType} 实时算力',
      realtime: '实时',
      timeRemain: '距离调整还剩',
      title: '用户面板 - CloverPool',
      today: '今日预估',
      unpaid: '未支付',
      yesterday: '昨日实收',
      yesterdayEarnTip: '昨日收益是指UTC时间前一天0:00 - 24:00的收益。',
      todayEarnTip: 'SHA256算力BTC挖矿收益预估',
      charts: {
        hashrate: '算力',
        minerCharts: '矿机算例曲线',
        myCharts: '我的算力曲线',
        poolCharts: '矿池算力曲线',
        reject: '拒绝率',
        time: '时间',
      },
      yesterday_earn: '昨日收益',
      yesterday_hashhrate: '昨日有效算力(不含拒绝)',
      earnings_per_100T: '折合每100T收益',
      //职责声明
      statement:
        '* 您不得通过我们提供或建议的方式以外的任何方式使用（或尝试使用）此服务，并且您明确保证不会通过任何违法或不合法方式使用（或尝试使用）此服务，或为支持任何违法或不合法活动的目的使用此服务。 进入本网站并注册此服务即表示您承诺并保证您是根据您所在司法辖区法律可以合法获得此项服务的人士。',
      // 收益预估
      estimatedEarnings: {
        title: '算力收益预估',
        hours24Earnings: '最近24小时预估',
        tip24: '根据币种最近24小时平均挖矿难度计算',
        realTimeEarnings: '实时预估',
        realTimeEarningsTip: '根据币种最新挖矿难度计算',
      },
    },
    subAccount: {
      title: '创建子账户 - CloverPool',
      createWorker: '创建子账户',
      noNode: '您还未选择节点!',
      noCoinType: '您还未选择币种!',
      selectRegion: '选择地区',
      setWorker: '设置子账户名称',
      enterWorker: '请输入子账户名称',
      enterAddress: '请输入收款地址',
      coinTypeEx:
        'CloverPool现已支持 {supportCoins} 挖矿，更多币种即将推出，敬请期待。',
      regionEx:
        'CloverPool在全球都部署服务器，选择最近的服务器能获得最好的体验。',
      workerEx:
        '您设置的子账户将绑定于所选地区。您最多可设置2000个子账户。这一子账户名称加上矿机编号即为矿机设置页的矿机名（worker name），如: "{name}.001"，"{name}.002"。',
      workerCaution:
        '* 请注意，同一子账户不支持跨地区挖矿，您创建于当前地区的子账户在其他区域不存在。如果您在其他地区拥有矿机，请重新创建对应地区的子账户。',
      setAddress: '填写提币地址',
      save: '创建并挖矿',
      verifyWorker: '子账户仅支持由3-20位字母或数字组成',
      option: '选填',
      'optional-BTC':
        '注：比特币现金（BCH）旧格式地址与比特币（BTC）地址相似，请谨慎填写，以免造成丢币损失。',
      'optional-BCH':
        '注：比特币现金（BCH）旧格式地址与比特币（BTC）地址相似，请谨慎填写，以免造成丢币损失。推荐使用新格式地址',
      'optional-UBTC':
        '注：比特币现金（BCH）旧格式地址与比特联储（UBTC）地址相似，请谨慎填写，以免造成丢币损失。',
      'optional-SBTC':
        '注：比特币现金（BCH）旧格式地址与超级比特币（SBTC）地址相似，请谨慎填写，以免造成丢币损失。',
      'optional-coin': '注：请谨慎填写 { coin_type } 地址，以免造成丢币损失。',
      selectCoinType: '选择币种',
      automatic: '自动生成',
      recommandWallet: '推荐使用CloverPool钱包',
      withdrawToExchange: '提现至交易所',
      withdrawToWallet: '提现至钱包',
    },
    tools: {
      // title: '工具 - CloverPool',
      // tools: '工具',
      // fppsPps: 'FPPS与传统PPS动态对比工具',
      // aboutFpps: 'FPPS比传统PPS好在哪？',
      // charts: '动态数据 一目了然',
      // watchCharts: '点击对比',
      // miniCalc: '挖矿计算器',
      // miniCalcExpress: '结合难度与币价，通过算力、功耗、电费计算挖矿利润',
      // miniMiniCalc: '迷你挖矿计算器',
      // miniMiniCalcExpress: '快速计算挖矿利润，仅考虑算力、难度与币价',
      // strat: '开始',
      // btcTool: 'CloverPool批量工具',
      // btcToolIntroduce: '批量处理矿机后台配置，方便 快捷',
      // download: '点击下载'
      btcSmartAgent: 'BTC 智能代理',
      agentExpress: '高效透明 <br/> 单台矿机可见',
      agentExpressBanner:
        '高效透明 <i style="margin-right:20px"></i> 单台矿机可见',
      bit: 'bit',
      document: 'windows 版帮助文档',
      poolApp: 'CloverPool App  全功能App',
      poolRealTime: '实时查看矿池、矿机数据',
      poolOpeation: '支持矿机删除、修改等操作',
      poolComming: '已上线推送报警功能',
      poolDownlaod: '点击下载',
      title: '工具 - CloverPool',
      tools: '工具',
      fppsPps: 'FPPS与传统PPS动态对比工具',
      aboutFpps: 'FPPS比传统PPS好在哪？',
      charts: '动态数据 一目了然',
      watchCharts: '点击对比',
      miniCalc: '挖矿计算器',
      miniCalcExpress: '结合难度与币价，通过算力、功耗、电费计算挖矿利润',
      miniMiniCalc: '迷你挖矿计算器',
      miniMiniCalcExpress: '快速计算挖矿利润，仅考虑算力、难度与币价',
      start: '开始',
      btcTool: 'CloverPool 矿机批量工具',
      btcToolIntroduce: '批量处理矿机后台配置，方便 快捷',
      download: '点击下载',
      ipToolsTitle: 'CloverPool矿机IP批量修改工具',
      ipToolsDes: '支持全系列蚂蚁矿机、占用IP修改、配置直连矿池',
    },
    miners: {
      createGroup: '创建新分组',
      deleteGroupTitle: '删除分组',
      editGroupTitle: '编辑分组',
      all: '全部',
      default: '未分组',
      addGroup: '创建新分组',
      addGroupInfo: '注意：选中的矿机将自动添加到新分组中',
      groupNameLabel: '分组名',
      addGroupEmpty: '组名称不能为空',
      groupRejected: '实时拒绝率', // Real time rejection rate
      groupNameLimit: '分组名必须在 3 到 20 个字之间',
      inactive: '不活跃',
      active: '活跃',
      dead: '失效',
      moveTo: '移动到',
      remove: '删除',

      allTooltip: '包含：活跃矿机 ({active}) + 不活跃矿机（{inactive}）',
      inactiveTooltip: '超过10分钟未提交计算结果（Share）的矿机数量。',
      activeTooltip: '正常提交计算结果（Share）的矿机数量',
      deadTooltip: '超过24小时未提交计算结果（Share）的矿机数量',

      home: '首页',
      miners: '矿机',

      workerName: '矿机名',
      Hashrate: '实时算力',
      Rejected: '实时拒绝率',
      dailyHashrate: '日算力',
      firstShare: '首次连接时间',
      LastShare: '最近提交时间',
      groupName: '分组名',

      delMillTitle: '确认要删除这些矿机信息吗？',
      delMillContext: '该操作会删除您选中的所有矿机，请谨慎操作。',
      delMillFailed: '删除失败',

      delGroupTitle: '你确定删除 “{name}” 分组吗？',
      delGroupContext: '分组内矿机将全部移动到未分组中',

      moveTitle: '移动矿机',
      notChecked: '请勾选矿机,支持批量操作',
      moveContext: '该操作会移动所有选中矿机到 {groupName} 组',
      moveFaild: '不能移动到当前分组',

      searchPlaceholder: '输入矿机名',
      reportTime: '此报告生成时间',
      refresh: '刷新',
      editGroupName: '修改分组名称',
      workListEmpty: '该分组未检测到矿机',
      exist: '该分组已存在',
      goOldVersion: '返回旧版',
      dataExport: `“{name}” 分组算力数据导出`,
      exportTip:
        '请选择需要导出的数据的时间区间，最多只能导出30天数据。导出的文件格式为CSV。',
      exportTip2:
        '当日算力的统计时间为00:00 - 23:59，其中今日算力的统计时间为00:00 - 导出时间。',
    },
    minerInfo: {
      miners: '矿机',
      hashrate: '单机算力',
      runningCondition: '运行状况',
      min: '分钟',
      hour: '{n}小时',
      hashrateAxis: '算力',
      rejected: '拒绝率',
      status: '状态',
      lastShare: '最近提交时间',
      lastShareIP: '最近提交IP',
      hashrateChart: '算力图表',
      realTime: '实时',
      trans: {
        seconds: '秒',
        mins: '分钟',
        hours: '小时',
        ago: '前',
        ACTIVE: '活跃',
        INACTIVE: '不活跃',
        DEAD: '失效',
      },
    },
    earningHistory: {
      title: '收益历史 - CloverPool',
      earn: '挖矿收益',
      Unpaid: '未支付',
      EarningsToday: '预估已挖',
      EarningsYesterday: '昨日收益',
      Time: '挖矿日期',
      NetworkDifficulty: '全网难度',
      Earnings: '收益',
      Payment: '支付时间',
      payWithOTCAmount: '以 {otcType} 结算收益: {amount}',
      Mode: '模式',
      Address: '地址',
      PaidAmount: '收益数额',
      yesterday: '昨日收益是指UTC时间前一天0:00 - 24:00的收益。',
      unpaidReason: {
        PENDING_NON_ADDRESS: '未设定收款地址，将存入余额。',
        PENDING_CHANGED_ADDRESS:
          '地址改动后将在48小时后开始支付。警告：如果地址并非由你本人改动，你的账号（邮箱/手机）可能已被攻破。请马上使用其他邮箱或手机号注册新账户，将矿机切换到新账户。',
        PENDING_NOT_ENOUGH:
          '金额小于您设置的起付金额（默认 :limit ），将存入余额。',
        PENDING_POOL_SETTLE_FAULT:
          '因矿池原因，导致这部分金额误发，后续将不再发放。',
      },
      depositeAmount: '存入余额',
      diffChg: '难度变化: ',
      equalsPps: '折合PPS',
      Chg: '日变动',
      status: '支付状态',
      paid: '已支付',
      waitingPay: '待支付',
      reason: '原因',
      fppsMoreEarn: '{mode} 额外收益:',
      fppsContain: '相比PPS模式，{mode}模式收益增加约{pps96}',
      noEarningsInformation: '暂无收益信息',
      earningDetail: '收益详情',
      Equivalent: '等值数额',
      extraProfits: '额外收益',
      autoSwap: '闪兑结算',
      original: '原币结算',
      Swapped: '已兑换',
      swapping: '兑换中',
      otcEarningTip:
        '闪兑结算机枪模式目标币种收益根据Matrixport账单日实际闪兑汇率计算',
      originEarningTip:
        '原币结算机枪模式目标币种收益根据账单日8:00AM Matrixport的闪兑汇率计算',
      exportEarningsData: '收益数据导出',
      exportTip: '最多可支持180天数据，导出的文件格式为XLS。',
      originStatusTip:
        '收益已通过闪兑功能兑换为目标币种收益，详情通过机枪模式下收益页面查询',
      swappingTip: '当前收益正进行兑换操作或由于收益过低未达到兑换最低金额',
      swappedTip:
        '收益已通过闪兑功能兑换为目标币种收益，详情通过机枪模式下收益页面查询',
      // upgrade TODO 收益改版新增
      settlementHashrate: '结算算力',
      addressRatio: '地址比例',
      addressEarnings: '地址收益',
      paymentTime: '支付时间',
      EarningsTodayTip:
        '从UTC的0点到现在，预计已经挖出的PPS收益数量，可能与您的实际收益存在偏差。',
      TimeTip:
        '挖矿日期采用的是UTC时间，例如2020年1月1日是指UTC时间2020年01月01日 00:00:00 - 2020年01月02日 00:00:00。',
      EarningsTip:
        '收益数额由「CloverPool结算中心」根据多项因素综合计算得出：收益分配方式、全网难度、结算算力、区块基础奖励、区块交易费奖励以及矿池费率等等，与您在收益计算器中计算的理论收益可能存在偏差。',
      // upgrade TODO 邀请新增
      inviteEarning: '返佣收益',
      inviteWorkerAccount: '矿工子账户',
      inviteRebateRatio: '返佣比例',
      inviteRebateAmount: '返佣数额',
      rebateExportTip: '可导出最多60天数据,导出的文件格式为XLS',
    },
    poolStats: {
      title: '矿池统计 - CloverPool',
      poolStats: '统计',
      hashrate: '算力',
      coinType: '币种',
      miners: '矿机',
      found: '挖矿所得',
      hashrateChart: '算力图表',
      blocksRelayed: '广播区块',
      height: '高度',
      time: '时间',
      blocks: '区块',
      blockHash: '区块哈希',
      ddaScore: 'DAA分数',
      reward: '区块奖励',
      day: '日',
      region: '报块地区',
      lucky: '幸运值',
      btc: '比特币',
      ltc: '莱特币',
      nmc: '域名币',
      zec: '零币',
      bcc: '比特币现金',
      bch: '比特币现金',
      eth: '以太坊',
      etc: '以太经典',
      dcr: 'DCR',
      doge: '狗狗币',
      grin: 'GRIN',
      sbtc: '超级比特币',
      ubtc: '比特币联储',
    },

    // 绑定守护者邀请链接
    guardianBind: {
      bindingGuardianInvitation: '绑定共享账户邀请链接',
      advance: '高级',
      guardianAccountManagement: '共享账户管理:',
      confirmGuardianBind: '确定守护并绑定账户',
      remark: '备注名称',
      enterRemark: '输入备注名称',
      suggestName:
        '推荐使用对方的名字，方便统一管理。若不填写将默认使用对方的手机号或电子邮箱。',
      guardian: '绑定',
      cancel: '取消',
      confirm: '确定由',
      manageView: '管理维护您的所有子账户？',
      suggestUsingName:
        '推荐使用对方的名字，方便统一管理。若不填写将默认使用对方的手机号或电子邮箱。',
      sms: '手机',
      mail: '邮箱',
      code: '{mode}验证码',
      send: '获取',
    },
    account: {
      subAccountManage: '子账户管理',
      currentAccount: '账户',
      locationRegion:
        '您当前位于 <strong>{coin}</strong> <strong>{region}</strong> 服务器',
      locationEx:
        '每个子账户对应固定的币种和区域，一旦创建完成，币种和区域将无法修改。如果有其他需要，可以创建新的子账户。',
      general: '通用版管理',
      advance: '专业版管理',
      create: '新建子账号',
      algorithm: ' 算法',
      oneButtonSwitch: '一键切换',
      addOthersCoins: '添加其它算法币种',
      supportCoins: '支持',
      hiddenSubAccounts: '隐藏子账户管理',
      hashAlert: '运行良好',
      viewHideSubAccount: '查看隐藏子账户',
      addSubAccount: '添加子账户',
      recover: '还原',
      latestAlert: {
        hashrate: '实际算力 {symbol} {expect} {unit}',
        miners: '实际矿机数量 {symbol} {expect} 台',
      },
    },
    settings: {
      subAccount: '子账户',
      currency: '币种',
      region: '地区',
      walletAddressAndMinimumPayment: '收款地址',
      walletAddress: '收款地址',
      shareData: '共享账户',
      shareDataTip:
        '您可以将全部或部分子账户信息分享给他人，他人仅可查看数据； 您也可以只读方式接收他人的共享。',
      alertSettings: '警报设置',
      mywatcher: '观察者设置',
      apikey: 'API KEY',
      payWithBtc: '以BTC结算',
      //wallet address & minimum payment
      setAddress: '请设置 {coinType} 收款地址',
      address: '收款地址',
      createAddress: '新建地址',
      modifyAddress: '更改地址',
      modify: '修改',
      minPay: '起付金额',
      lastUpdateTime: '地址修改时间',
      updateBitconAddressTip:
        '注意：为了资金安全，本次操作后48小时内暂停打款，您的收益将作为余额，在48小时后的第一个结算日支付。',

      old: '旧地址',
      new: '新地址',
      continue: '下一步',
      addAddress: '填写',
      enterCode: '请填写验证码',
      newBit: '您的新地址',
      way: '我们将发送验证码来确认此次修改 , 请选择验证方式:',
      sendVerifyCode: '验证码已发送到{contactType}',
      sms: '手机',
      mail: '邮箱',
      mailVia: '发送验证码到邮箱',
      smsVia: '发送验证码到手机',
      errorLater: '请稍后再试 ...',
      updateSuccess: '地址修改成功',
      // 多地址收款
      multAddress: {
        tag: '支持多地址',
        addMultAddress: '添加地址',
        adjustProportion: '调整比例',
        adjustMinPay: '调整起付额',
        address: '地址',
        proportion: '比例',
        remark: '备注',
        lastUpdateTime: '修改时间',
        operation: '操作',
        emptyText: '未添加收款地址',
        emptyTextForMult: '暂无数据，最多可添加20个地址',
        cancel: '取消',
        nextStep: '下一步',
        delete: '删除',
        modify: '修改',
        newAddressPlaceholder: '新地址',
        addressMaxCountTip:
          '当前一个子账户最多可添加{maxCount}个收款地址，如需要支持更多地址，请联系客服 support@connectbtc.com。',
        updateBitconAddressTip:
          '为了资金安全，本次操作后应付收益将暂停支付，统一在48小时后的第一个结算日完成支付。',
        emptyRemark: '未添加备注',
        noAddressForAdjust: '尚未添加地址，请添加地址后再调整比例或起付额。',
        balanceMoveTip:
          '当前子账号的未支付的数字货币将转到「新地址1」中，请确认无误后再操作。',
        confirmModalTitle: '安全验证',
        authentication: '身份验证',
        operationConfirm: '确认操作',
        addAddressConfirmTip: '您正在添加{count}个收款地址',
        modifyProportionConfirmTip: '您正在调整各个收款地址的比例',
        deleteAddressConfirmTip: '您正在删除地址<br/>{address}',
        selectBalanceMoveAddress: '选择接收数字货币的地址',
        selectBalanceMoveAddressTip:
          '正在删除的地址的未支付金额为{balance}{coinType}，本次操作后将于24小时内转入以上选择的新地址。',
        cannotDeleteAddress: '无法删除该地址',
        deleteAddressRule: '仅可删除收款比例等于0.00%的地址。',
        // deleteAddressRuleDetail:
        //   '1. 收款比例等于0.00%；<br/>2. 同子账号下的机枪挖矿地址未设置为本地址。',
        // usedBySmart:
        //   '当前子账号的机枪挖矿选择的是当前地址，本次修改将同步修改机枪挖矿地址。',
        operationSuccess: '操作成功',
        validate: {
          emptyAddress: '请填写新地址',
          emptyAddressForUpdate: '地址不能为空',
          emptyProportion: '请填写地址',
          emptyProportioAfter: '的比例',
          proportionInvalidate:
            '每个地址的比例必须大于或等于0%，所有比例之和必须等于100%',
          maxCount: '每个子账号最多可设置20个地址',
          duplicateAddressPrefix: '同一个地址只能添加1次，地址',
          duplicateAddressSuffix: '被添加了多次。',
          limitAddress: 'This type of address is not supported at this time',
        },
      },

      //min pay
      currentMinPay: '当前起付金额:',
      minPayAllSubAccount: '所有比特币(BTC)子账户使用相同起付金额',

      //Share to watcher
      watcher: '观察者',
      availableWatchers: '当前可用',
      watcherExpress:
        '* 观察者可以通过以下链接访问你的数据，但无法操作、改动你的账户。',
      createWatcher: '创建新的观察者',
      modifyWatcher: '编辑权限',
      authoritySettings: '权限设置',
      setWatcherName: '为观察者设置一个备注名',
      watcherName: '观察者备注名',
      watcherNameTip: '字符限制 2~20',
      notEmptyWatcherNote: '观察者备注名不能为空',
      tips: '提示：',
      tip_dashboard: '1、「用户面板」不包括“未支付”、“已支付数量”等收益信息；',
      tip_earning: '2、「收益信息」包括各地址的“未支付”、“已支付数量”等信息。',
      moreSettings: '更多设置',
      guardianRemark:
        '您可以将全部或部分子账号信息（包括算力、矿机等）共享给其它用户，该用户仅可以查看但无法改动任何信息',
      guardian: '共享账户',
      shareAccountsSetting: '共享账户',
      accessKey: 'Access Key',
      generateAK: '生成 Access Key',
      puid: 'PUID',
      apiDoc: 'API 文档',
      confirmDeleteWatcher: '确认删除该观察者账户?',
      watcherAuths_Dashboard: '用户面板',
      watcherAuths_Dashboard_Tip: '(不含收益信息)',
      watcherAuths_Miners: '矿机页面',
      watcherAuths_Earnings: '收益信息',
      watcherAuths_GetCoin: '赠币',

      // Alert setting
      alertItem: '报警项设置',
      alertContacts: '报警联系人',
      hashrateAlert: '算力报警',
      minerAlert: '矿机数量报警',
      alertFrequency: '报警频率',
      alertFrequencyTip:
        '由于政策原因，部分国家地区的手机短信报警频次固定为24小时一次，请知悉。',
      warnMeWhenHashrate: '算力警戒线≤',
      warnMeWhenActiveMiner: '活跃矿机数量警戒线≤',
      dontWarnMeTwice: '两次报警最小间隔',
      hours: '小时',
      apply: '应用',
      operateFailed: '操作失败!',
      contactsList: '联系人列表',
      cancel: '取消',
      ok: '确定',
      save: '保存',
      reset: '重置',
      newContact: '新增联系人',
      editContact: '修改联系人',
      deleteContact: '删除联系人',
      confirmDeleteContact: '确认删除该报警联系人?',
      name: '称谓/备注',
      phone: '电话',
      email: '邮箱',
      validateAlert: {
        nameEmpty: '称谓/备注不能为空',
        phoneRegionFormat: '国际区号格式不正确',
        phoneFormat: '电话格式不正确',
        emailFormat: '邮箱格式不正确',
        contactEmpty: 'email 和 电话号码 必须填写一个',
      },
      max_email_contacts: '最多支持10个邮件联系人',
      max_phone_contacts: '最多支持5个电话联系人',
      enabled_alert_need_hashrate: '未检测到算力，无法开启报警。',
      alertItemUpdateSuccess: '报警项设置成功',

      done: '提交',

      okay: '确定',
      emptyAddress: '地址不能为空',
      invalidAddress: '错误的地址',
      emptyCode: '验证码不能为空',
      wrongCode: '验证码错误',
      emailVia: '发送邮件到',
      textVia: '发送短信到',
      help: '帮助',

      contact: '联系方式:',
      close: '关闭',
      receivedress: '收款地址:',

      todayUpdateMinPay: '从今日起挖矿收益的起付金额已更改为:{minPay}',
      updateAddress: '地址修改时间:',
      // pay with btc
      payWithBtcTip:
        '* 开启以BTC结算之后，您的挖矿收益将在每天的固定时间折算成比特币，体现在收益记录中，并支付到以下独立的BTC结算地址上，一般情况下为每天上午的12:00，特殊情况时会另行通知。',
      convertedCurrency: '兑换币种: ',
      setBtcAddressTip: '请设置BTC收款地址',
      btcAddress: '比特币地址: ',
      conversionRate: '参考汇率',
      convert: '兑换',
      getValue: '您将得到 ',
      conversionTip: '* 兑换汇率仅供参考，以实际结算结果为准.',
      lastUpdate: '最后更新于: ',
      on: '开',
      off: '关',
      turnOnPaywith: '确定要开启以 BTC 支付吗?',
      turnOffPaywith: '确定要关闭以 BTC 支付吗?',
      payOn: '开启',
      payOff: '关闭',
      resend: '重新发送',
      send: '发送验证码',
      resendTip: '如未收到验证码，烦请等待一会儿，或者多试几次',

      // 一键切换 One Switch
      oneSwitch: {
        attention: '请注意',
        optionCurrentMode: '请选择切换模式',
        switch: '您即将切换算力到 {destMode}',
        switchAnotherMining: '正在切换到 {destMode} 模式中...',
        ethSwitchWarning:
          '警告：若您的矿机不支持切换DAG，会导致收益为零。需手动重启矿机，才可恢复正常。（已知蚂蚁矿机E3不支持，由ETC切换到ETH，需手动重启）',
        ethSwitchWarningLinkTxt: '详情请点击',
        hashsHaveSwitched: '算力已切换到 {destMode} 挖矿;',
        checkSubAccount:
          '请查看子账号 {subAccountName}- {subAccountRegionNode}',
        enter: '进入',
      },
      // 机枪收款地址
      smartAddress: {
        // autoSwapSettlement: '闪兑结算',
        original: '原币结算',
        autoTip: '机枪收益币种每日兑换为目标币种进行结算',
        originalTip: '机枪收益以机枪支持币种BTC、BCH、BSV进行结算',
        originalCurrency: '来源币种',
        targetCurrency: '目标币种',
        earningsInSmartPool: '机枪收益',
        activated: '已开启',
        unactivated: '已关闭',
        modifyTip:
          '机枪闪兑结算目标币种与同子账户下相同币种收款地址关联并保持一致',
        autoHistory: '是否闪兑历史收益？',
        notice1:
          '为了安全原因，修改地址后48小时内不予支付，您的收益将作为余额，在48小时后的第一个结算日支付。',
        notice2:
          '开启闪兑功能，将在当日结算中将机枪挖矿收益自动兑换为目标币种，点击“确认”将视为同意{fundLink}。',
        notice3:
          '机枪模式下的收款地址与同子账户下币种收益地址关联并保持一致(如设置了多地址，将与第一个地址保持一致）',
        originalNotice1:
          '机枪模式下的收款地址与同子账户下币种收益地址关联并保持一致(如设置了多地址，将与第一个地址保持一致）',
        currentPayoutMode: '当前结算模式: {mode}',
        fundLink: `《CloverPool资金兑换授权协议》`,
        originBtn: '开启原币结算',
        otcBtn: '开启闪兑结算',
        otcSuccess: '机枪模式闪兑结算功能已开启，将以BTC作为收益付款币种。',
        otcModalNotice2: `开启闪兑历史收益后，账户中历史收益参与闪兑；未开启闪兑历史收益，仅最近结算周期内收益参与闪兑。`,
        originSuccess:
          '机枪模式原币结算功能已开启，将以算力实际挖矿币种作为收益付款币种。',
        // 2021-06-04新增
        mode_otc: '闪兑结算',
        mode_origin: '原币结算',
        settlementMode: '结算模式',
        settlementModeDescription: '结算模式说明',
        originDescription:
          '原币结算：结算为实际挖矿的币种，币种范围为BTC、BCH和BSV。',
        autoSwapDescription:
          '闪兑结算：先根据机枪算法进行BTC/BCH/BSV三个币种动态挖矿，最后统一兑换为BTC进行结算。',
        settlementSwitch: '结算模式切换',
        originalSettlement: '原币结算',
        autoSwapSettlement: '闪兑结算',
        sha256SmartPoolCurrency: 'Sha256机枪币种',
        currency: '币种',
        address: '地址',
        addressRules: '地址规则',
        switchAddress: '切换地址',
        noAddressTip: '同子账户下的{coin}币种未添加地址',
        noAddressSelected: '未选择地址',
        selectFrom: '从同子账户下的{coin}地址列表中选择',
        settingTip:
          '提示：如果您要将「机枪挖矿收款地址」与「同子账户下单币种挖矿地址」区分开，请到单币种地址管理中添加一个新的地址，并将收款比例设置为0，然后回到此处切换为新地址即可。',
      },
    },
    homepage: {
      btcSmartAgent: 'BTC智能代理',
      agentExpress: '高效透明&emsp;&emsp;&emsp;单台矿机可见',
      bit64: 'Win7及以上 64位',
      bit32: 'WinXP 32位',
      document: 'windows版帮助文档',
      accessLinuxVersion: 'Linux版本安装直接访问',
      cuttingEdgeTechnology: '领先的核心技术',
      latestArchitecture: '最新原创架构<br/>俯瞰百家所长',
      openSourceCode: '代码开源<br/>公开公平',
      highTestPressure: '超高压力测试<br/>坚若磐石',
      lowOrphanedRate: '超低孤块率<br/>快如闪电',
      transparentAgent: '全新配套软件<br/>透明代理',
      standardsServices: '矿业服务新标准',
      customerHotline: '客服电话',
      highHashrateConsultation: '大客户招商',
      professionalCustomerService: '真正有专业客服的矿池',
      bestVipServer: '业内最高规格VIP服务器',
      oneToOne: '业内顶尖研究者一对一分析案例',
      appAvailable: '矿池App让你躺着管理',
      earningsTimelyEveryday: '准点打款 风雨无阻',
      transnationalTeamOperation: '首个由跨国团队支持的矿池',
      currencies: '币种',
      dailyEarnings: '日收益',
      hashrate: '算力',
      hashrateChart: '算力图表',
      explorer: '浏览器',
      prices: '币价',
      earningsModels: '收益模式',
      minPayment: '最小起付金额',
      help: '帮助',
      notePaytime: '每日 0:00-8:00 (UTC)支付昨日收益',
      yuanKwhElectric: '元/千瓦·时(电费)',
      todayEarnings: '日收益约:',
      piece: '台',
      signIn: '登录',
      signUp: '注册',
      power: '功耗',
      hashrateProfitEst: '算力收益参考',
      hashrateProfitEstTips: '可能与您的实际收益存在偏差，仅供参考。',
      miningProfitEst: '主流矿机收益参考',
      fee: '费率',
      desc: '全球领先的多币种综合性服务矿池',
      tutorial: '教程',
      diff: '当前难度',
      nextDifficultyEstimated: '预计下次难度',
      dateToNextDifficulty: '下次难度剩余时间',
      Algorithm: '算法',
      backup: '备用端口',
      whyMe: {
        title: '为什么选择CloverPool(原BTC.com)',
        leadingTechnology: '领先技术',
        convenientManagement: '便捷管理',
        revenueAssurance: '收益保证',
        professionalServices: '专业服务',
        desc1: `代码开源、免费矿机矿场管理软件、官方定制版超频固件大幅提升收益、区块链数据和技术服务提供商。`,
        desc2:
          '简洁的App和网页端操作，手机号/邮箱轻松注册账户，独立的报警功能、收益地址、矿机分组与共享数据的观察者链接。',
        desc3: `真实透明的挖矿数据，先进的FPPS收益模式，强大的结算中心，准点打款风雨无阻。`,
        desc4: `真正拥有专业客服团队的矿池，业内最高规格的VIP定制化服务，业内顶尖研究者一对一分析案例。`,
      },
      app: {
        title: '(原BTC.com) App',
        description: '全球领先的多币种综合矿池',
        property1: '支持多币种挖矿，多子帐户管理，观察者链接分享轻松管理',
        property2: '币种全面数据实时展示，更快掌握挖矿动态',
        property3: '全新交互界面，使用体验更加流畅',
      },
      coinList: {
        eth: {
          earningsModelsTip: {
            FPPS: 'ETH的结算模式为FPPS/PPS+双模式，当前模式为FPPS模式；平台会根据链上交易费用、本矿池算力份额、市场行情、用户收益等综合因素调整结算模式。',
            'PPS+':
              'ETH的结算模式为FPPS/PPS+双模式，当前模式为PPS+模式；平台会根据链上交易费用、本矿池算力份额、市场行情、用户收益等综合因素调整结算模式。',
          },
        },
        ltc: {
          giftCoin: '送DOGE、BEL',
        },
      },
      globalZone: '全球区',
      zilDiff1: '分片',
      zilDiff2: 'DS',
      etcMinerAddressTips: '只挖ETC的设备连接该挖矿地址',
      zilMinerAddressTips: 'ETC和ZIL双挖的设备连接该挖矿地址',
    },
    advanceAccount: {
      title: '子账户管理 - CloverPool',
      all: '全部币种',
      unit: '单位',
      subAccount: '子账户',
      coin: '币种',
      hashrate: '算力',
      allMiners: '全部矿机',
      activeMiners: '活跃',
      inactiveMiners: '非活跃',
      deadMiners: '失效',
      region: '地区',
      more: '更多',
      setting: '设置',
      totalHashrate: '总算力',
      search: '搜索',
      status: '状态',
      hidden: '已隐藏',
      shareTome: '共享给我的',
      hide: '隐藏',
      recover: '恢复',
      delete: '删除',
      refersh: '刷新',
      export: '导出',
      name: '名称',
      note: '备注',
      manageShareAccount: '管理共享子账户',
    },
    getCoin: {
      tip: {
        btc: '即日起在CloverPool挖矿(比特币现金，比特币)的同时免费赠送域名币(NMC), 亦来云(Elastos)。',
        bch: '即日起在CloverPool挖矿(比特币现金，比特币)的同时免费赠送域名币(NMC), 亦来云(Elastos)。',
        ltc: '即日起在CloverPool挖矿(莱特币LTC)的同时免费赠送狗狗币(Doge)，以PPLNS方式结算。',
        bcha: `<p>BCHA（BCHABC）是BCH的分叉币，相关介绍请访问 <a href="https://www.bitcoinabc.org/" target="__blank">https://www.bitcoinabc.org/</a>。</p>
          <p><b>提现时间:</b><br/>2020年12月7日上午08:00 到 2021年1月7日上午08:00 (新加坡时间)
          </p>`,
      },
      beta: '内测中',
      whatsThis: '这是什么?',
      address: '地址:',
      newAddress: '新地址',
      addressComplete: '为了保证您的收益不受任何损失，请尽快填写免费赠币地址。',
      bchaAddressComplete:
        '2021年1月7日后，未填写地址的用户，BCHA将清零，不再补发。为了保证您的收益不受任何损失，请尽快填写免费赠币地址。',
    },
    watcher: {
      expired: '观察者权限已过期',
      incorrect: '观察者链接错误',
    },
    activity: {
      pointsToday: '今日积分',
      pointsSum: '累计积分',
      ranking: '排名',
      earnings: '收益',
    },
    minerRanking: {
      title: '矿机收益排名',
      electric: '日电费',
      dailyEarning: '日收益',
      priceShutdown: '关机币价',
      ALL: '全部',
      CNY: '人民币 CNY',
      USD: '美元 USD',
      desc: '收益由高到低',
      asc: '收益由低到高',
      electricPercent: '电费占比',
      searchTip: '请输入矿机',
      updated: '更新于',
      coin: '币种',
      emptyShow: '没有符合条件的矿机',
      rank: '排名',
      miner: '矿机',
      power: '功耗',
      hashrate: '算力',
      rev24h: '日产出',
      energy: '电费支出',
      incomeWithdoge: '计入DOGE收益',
      incomeWithdogeAndBel: '计入DOGE和BEL收益',
    },
  },
};
const pageMessageFlatten = flatten(pageMessage);
export default pageMessageFlatten;
