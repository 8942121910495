import flatten from 'flat';
const inviteMessage = {
  invite: {
    inviteTitle: '초대 보상',
    bindPhoneTip:
      '영구적으로 유효한 상금 초대, 양방향 커미션 리베이트에 참여하려면 휴대 전화 번호를 바인딩하십시오.<br>제발<span style="font-weight:500;color:#333"> 개인 센터 -> 계정 설정 </span>휴대폰 번호를 묶습니다.',
    needStep: '한 걸음만 더 가면',
    createInviteAccountLoadingMessage:
      '독점 초대 코드 및 독점 하위 계정 생성 중...',
    heading:
      '친구를 초대하여 함께 마이닝하고 <br><span style="color:#0A66C2"> 0.2% </span>리베이트 보상을 받으세요',
    cutoff:
      '초대받은 사람에 대한 수수료<span style="color:#0A66C2"> 5% </span>할인',
    ruleDetails: '규칙 세부정보',
    totalRebate: '총 리베이트',
    totalRebateTip:
      '커미션은 암호화폐로 정산되며, 법정화폐 평가는 각 지급 암호화폐에 결제일 0시(UTC+0)의 환율을 곱한 후 합산하는 방식입니다.',
    details: '세부',
    totalAccept: '총 초대 대상자',
    minedAccept: '채굴된 초대자',
    inviteCode: '초대 코드',
    copy: '복사',
    inviteLink: '초대 링크',
    acceptInviteCode: '초대 코드',
    acceptInviteCodeTip:
      '귀하는 초대된 광부이며 마이닝 풀 요금에서 5% 할인을 받습니다. 풀 대시보드 > 수익 > 수익 내역에서 확인할 수 있습니다.',
    generatePoster: '포스터 생성',
    inviteRecord: '초대 기록',
    export: '내 보내다',
    rebateRecord: '리베이트 기록',
    invitee: '초대받은 사람들',
    inviteeStatus: '초대받은 사람 상태',
    'inviteeStatus-unlocked': '해고',
    'inviteeStatus-registered': '등기',
    'inviteeStatus-mined': '채광 한',
    registerTime: '등록 시간',
    miningTime: '채굴 시작 시간',
    miningTimeTip:
      '초대받은 사람이 초대를 받은 후 첫날 채굴을 시작하면 UTC 시간이 적용됩니다.',
    coinType: '통화',
    region: '광산 지역',
    inviteSubAccount: '리베이트 하위 계정',
    rebatePercent: '리베이트 비율',
    op: '작동하다',
    originCoinCount: '수량',
    specialSubAccountTitle:
      '커미션 리베이트를 받을 수 있는 전용 하위 계정을 만들었습니다.초청자가 채굴을 시작한 후 채굴 수익의 커미션 리베이트는 다른 통화와 다른 파티션으로 해당 하위 계정에 정산됩니다.',
    specialSubAccountTip:
      '기본적으로 BTC 통화의 미국 및 유럽 전용 하위 계정이 생성되며 초대받은 사람이 다른 통화에 대한 마이닝 하위 계정을 생성하면 해당하는 전용 하위 계정이 생성됩니다.',
    rebateOpAddress: '주소 관리',
    rebateOpDetail: '리베이트 세부정보',
    downloadPoster: '다운로드',
    noData: '데이터 없음',
    gotoBind: '바인딩으로 이동',
    boundPhone: '나는 그것을 묶었다',
    headtips: '이벤트',
  },
};
const inviteMessageFlatten = flatten(inviteMessage);
export default inviteMessageFlatten;
