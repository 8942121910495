import React from 'react';
import './index.scss';
const RadioGroup = ({ children }) => {
  return <div className="btc-radion-button-group">{children}</div>;
};

const RadioButtonItem = ({
  value,
  className = '',
  onClick,
  isActive,
  disabled = false,
  children,
}) => {
  return (
    <button
      key={value}
      className={`btc-radion-button-item ${isActive ? 'active' : ''} ${
        disabled ? 'disabled' : ''
      } ${className}`}
      onClick={() => {
        onClick(value);
      }}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
export default RadioGroup;

export { RadioButtonItem, RadioGroup };
