import flatten from 'flat';
const inviteMessage = {
  invite: {
    inviteTitle: 'Invitation',
    bindPhoneTip:
      'Liez le numéro de téléphone pour participer, les deux parties reçoivent une commission, valable en permanence.<br>S\'il vous plaît allez à<span style="font-weight:500;color:#333"> Centre d\'utilisateur -> Paramètres du compte </span>pour lier votre numéro de téléphone.',
    needStep: 'Un pas de plus',
    createInviteAccountLoadingMessage:
      "Création du code d'invitation et du sous-compte...",
    heading:
      'Invitez des amis à miner ensemble et obtenez <br>une remise de <span style="color:#0A66C2"> 0,2% </span>',
    cutoff:
      '<span style="color:#0A66C2">5% </span> de réduction sur la commission pour les invités',
    ruleDetails: 'Détails de la règle',
    totalRebate: 'Commissions totales',
    totalRebateTip:
      "La commission est réglée dans la crypto-monnaie, voici la valorisation de la monnaie fiduciaire, la méthode d'évaluation consiste à multiplier chaque crypto-monnaie payable par le taux de change de 0 heures (UTC+0) à la date de règlement, puis ajouter la somme.",
    details: 'Des détails',
    totalAccept: 'Invités',
    minedAccept: 'Invités minés',
    inviteCode: "code d'invitation",
    copy: 'Copie',
    inviteLink: "Lien d'invitation",
    acceptInviteCode: 'Code invité',
    acceptInviteCodeTip:
      "Vous êtes un mineur invité et bénéficiez d'une remise de 5% sur le tarif du pool de minage. Vous pouvez le vérifier dans le tableau de bord du pool > Gains > Détails des gains.",
    generatePoster: 'Générer une affiche',
    inviteRecord: "Dossier d'invitation",
    export: 'Exportation',
    rebateRecord: 'Registre des commissions',
    invitee: 'Invité',
    inviteeStatus: 'Statut',
    'inviteeStatus-unlocked': 'Rejeté',
    'inviteeStatus-registered': 'Inscrit',
    'inviteeStatus-mined': 'Miné',
    registerTime: 'Temps invité',
    miningTime: "Commencer l'exploitation minière",
    miningTimeTip:
      "Lorsque l'invité commence à extraire le premier jour après avoir reçu votre invitation, l'heure UTC est adoptée.",
    coinType: 'Monnaie',
    region: 'Région minière',
    inviteSubAccount: 'Sous-compte de commission',
    rebatePercent: 'Taux de commission',
    op: 'Fonctionner',
    originCoinCount: 'Montant',
    specialSubAccountTitle:
      'Nous avons créé des sous-comptes spéciaux pour que vous receviez une récompense de commission. Une fois que vos invités ont commencé à miner, la commission des revenus miniers sera versée sur vos sous-comptes correspondants dans différentes devises et différentes régions.',
    specialSubAccountTip:
      "Par défaut, des sous-comptes de commission pour les États-Unis et l'Europe de la devise BTC sont générés. Lorsque votre invité crée un sous-compte de minage pour d'autres devises, nous générerons un sous-compte spécial correspondant pour vous.",
    rebateOpAddress: 'Gestion des adresses',
    rebateOpDetail: 'Détails des commissions',
    downloadPoster: 'Télécharger',
    noData: 'Pas de données',
    gotoBind: 'Aller lier',
    boundPhone: "je l'ai lié",
    headtips: 'Événement',
  },
};
const inviteMessageFlatten = flatten(inviteMessage);
export default inviteMessageFlatten;
