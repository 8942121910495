import { runInAction, makeAutoObservable } from 'mobx';
import axios from 'axios';
import { defaultListPage } from 'common/config';

const defaultStatsInfo = {
  total_paid: '-',
  unpaid: '-',
  earnings_today: '-',
  earnings_yesterday: '-',
};
class MergeMiningStore {
  loadingMergeMiningConfig = true;
  mergeMiningConfig = null;
  loadingStats = false;

  earningStatsInfo = defaultStatsInfo;

  loadingList = false;

  historyList = [];

  historyListTotal = 0;

  historyListPage = defaultListPage;

  poolInfo = {};

  constructor() {
    makeAutoObservable(this); //升级mobx 6之后禁止使用装饰器，然后需要使用makeAutoObservable
  }

  getMergeMiningCoinConfig = async (triggerLoading = true) => {
    if (triggerLoading) {
      this.loadingMergeMiningConfig = true;
    }
    const res = await axios.get(`/sub-account/merge-mining/config`);
    if (res && res.data) {
      runInAction(() => {
        this.mergeMiningConfig = res.data;
        this.loadingMergeMiningConfig = false;
      });
    } else {
      this.loadingMergeMiningConfig = false;
    }
  };

  getEarningHistoryList = async (coinType) => {
    this.loadingList = true;
    let url = `/account/earn-history/multi-addr`;
    const res = await axios.get(url, {
      params: {
        ...this.historyListPage,
        reason: 1,
        is_decimal: 1,
        record_type: coinType,
      },
    });
    if (res && res.data) {
      runInAction(() => {
        this.historyList = res.data;
        this.loadingList = false;
      });
    }
  };

  getEarningStatsInfo = async (coinType) => {
    this.loadingStats = true;
    this.earningStatsInfo = defaultStatsInfo;
    let url = `/account/earn-stats`;
    const res = await axios.get(url, {
      params: {
        is_decimal: 1,
        record_type: coinType,
      },
    });
    if (res && res.data) {
      runInAction(() => {
        this.earningStatsInfo = res.data;
        this.loadingStats = false;
      });
    }
  };

  setHistoryListPage = (page, page_size) => {
    this.historyListPage = {
      page,
      page_size,
    };
  };

  resetHistoryListPage = () => {
    this.historyListPage = defaultListPage;
  };

  /**
   * 矿池挖某币种的信息，包括支付起付金额、支付方式、支付时间等
   */
  getPoolMiningInfo = async (coin_type) => {
    const res = await axios.get(`/pool/info`, {
      params: {
        coin_type,
      },
    });
    if (res && res.data) {
      runInAction(() => {
        this.poolInfo = res.data;
      });
    }
  };
}

const mergeMiningStore = new MergeMiningStore();

export { mergeMiningStore };
