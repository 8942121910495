import { HASHRATE_UNIT_MAPPING } from './constants';

/**
 *  每日总电费
 * @param {number} powerNum 功耗
 * @param {number} electricity 每W电费  xx/kWh
 */
const getDailyElectricity = (powerNum, electricity) => {
  const result = (powerNum * electricity * 24) / 1000;
  return result;
};

/**
 *  每日收益
 *  法币算力收益 - 电费
    = 法币每T收益 * 算力 - 每日总电费
 * @param {number} income 法币每T收益
 * @param {number} hashrate 算力
 * @param {number} dailyElectricity 电费
 */
const getDailyEarning = (income, incomeHashrateUnit, hashrate) => {
  return (
    (income * hashrate) /
    (incomeHashrateUnit ? HASHRATE_UNIT_MAPPING[incomeHashrateUnit] : 1)
  );
};

/**
 * 关机币价 = 电费/(币收益 * 算力)
 * @param {number} incomeCoin 每日币收益
 * @param {string} incomeHashrateUnit 算力量级
 * @param {算力} hashrate 算力值
 * @param {number} dailyElectricity 电费
 */
const getPriceShutdown = (
  incomeCoin,
  incomeHashrateUnit,
  hashrate,
  dailyElectricity,
) => {
  let result;
  let profit =
    (incomeCoin * hashrate) /
    (incomeHashrateUnit ? HASHRATE_UNIT_MAPPING[incomeHashrateUnit] : 1);

  result = dailyElectricity / profit;
  return result;
};

export { getDailyElectricity, getDailyEarning, getPriceShutdown };
