import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { getDefaultLang } from 'common/utils/lang';
import de from './i18/locales/de';
import en from './i18/locales/en';
import es from './i18/locales/es';
import fr from './i18/locales/fr';
import ja from './i18/locales/ja';
import ko from './i18/locales/ko';
import ru from './i18/locales/ru';
import tr from './i18/locales/tr';
import zhCN from './i18/locales/zh-CN';
import zhHK from './i18/locales/zh-HK';

const backendProps =
  process.env.NODE_ENV === 'production'
    ? {
        backend: { loadPath: `/locales/{{lng}}/{{ns}}.json` },
      }
    : null;

const supportLanguages = [
  'de',
  'en',
  'es',
  'fr',
  'ja',
  'ko',
  'ru',
  'tr',
  'zh-CN',
  'zh-HK',
];

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      de,
      en,
      es,
      fr,
      ja,
      ko,
      ru,
      tr,
      'zh-CN': zhCN,
      'zh-HK': zhHK,
    },
    debug: false,
    supportedLngs: supportLanguages,
    fallbackLng: 'en',
    ns: [
      'common',
      'dashboard',
      'earnings',
      'giftcoin',
      'miners',
      'invite',
      'welcome',
      'pages',
      'pagination',
      'seo',
      'account',
      'ethForkGuide',
      'homepage',
    ],
    defaultNS: 'common',
    lng: getDefaultLang(),
    load: 'currentOnly',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ...backendProps,
  });

export default i18n;
