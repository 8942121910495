import appLocaleData from 'react-intl/locale-data/tr';
import common from './tr.common';
import pages from './tr.pages';
import pagination from './tr.pagination';
import invite from './tr.invite';
// deploy to pre
window.appLocale = {
  messages: Object.assign({}, common, pages, pagination, invite),

  locale: 'tr',
  data: appLocaleData,
};

export default window.appLocale;
