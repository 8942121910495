import React from 'react';
import LoadingWrap from '../LoadingWrap';
import './index.scss';

const BannerCardItem = (props) => {
  const { label, value } = props;
  return (
    <div className="banner-card-item">
      <h2>{label}</h2>
      <div className="card-item-text">{value}</div>
    </div>
  );
};
export { BannerCardItem };

const BannerCard = (props) => {
  const {
    style,
    title,
    children,
    loading,
    showSplit = true,
    onClick, //可点击鼠标放上去有动效
    footerContent,
  } = props;
  return (
    <div
      className={`banner-card ${onClick ? 'banner-card-link' : ''}`}
      style={{ width: 300, height: 100, ...style }}
      onClick={onClick}
    >
      <LoadingWrap loading={loading}>
        <div
          className="banner-card-header"
          style={!showSplit ? { border: 'none' } : null}
        >
          <div className="banner-card-title">{title}</div>
        </div>
        <div className="banner-card-body">{children}</div>
        <div className="banner-card-footer">{footerContent}</div>
      </LoadingWrap>
    </div>
  );
};
export { BannerCard };

export default BannerCard;
