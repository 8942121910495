import React from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from 'antd';
import {
  upperCase,
  lowerCase,
  formatNumber,
  getLocaleConfig,
  numberWithCommas,
} from 'common/utils';
import { action } from 'mobx';
import { coinsConfig } from 'common/config';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import { LoadingIcon } from 'components/widget';
import SvgIcon from 'components/SvgIcon';
import styles from '../index.module.scss';

const getAccountHashrate = (puid, coin, hashrateMiners) => {
  const { hashrateScale, hashrateUnit } = coinsConfig[coin.toLowerCase()];
  let accountHashrate = null;
  if (hashrateMiners) {
    accountHashrate = {
      ...{
        shares_15m: '0.00',
        shares_1d_unit: hashrateScale,
        hashrate_suffix: hashrateUnit,
        workers_active: 0,
        workers_dead: 0,
        workers_inactive: 0,
        workers: 0,
        is_current: 0,
        latest_alert: {
          trans: '',
        },
      },
      ...accountHashrate,
      ...hashrateMiners[puid],
    };
  }

  return accountHashrate;
};

const LoadingItem = ({ loading, isCurrentLogin, children }) => {
  if (loading) {
    return (
      <LoadingIcon size="sm" color={isCurrentLogin ? '#fff' : '#1A7EE3'} />
    );
  } else return children;
};

// alertKey = hashrate | miners
const LatestAlert = ({ alertKey, latestAlert, className }) => {
  if (alertKey === latestAlert?.type && latestAlert?.trans) {
    return (
      <Tooltip title={latestAlert.trans}>
        <span className={className} style={{ position: 'absolute', right: 0 }}>
          <SvgIcon iconName="icon_alert" size="sm" className="margin-left-xs" />
        </span>
      </Tooltip>
    );
  }
  return null;
};

const AccountCoinItem = observer(
  ({
    puid,
    coin,
    isCurrentLogin,
    accountItemInfo,
    isSha256,
    isSiblingSha256,
    isCurrentSha256,
    afterChangeAccount,
  }) => {
    const { userStore, appStore } = useStores();
    const { t } = useTranslation('account');

    const { hashrateMiners, changeAccount } = userStore;
    const { lang } = appStore;

    const isLoadingHahrate = !hashrateMiners[puid];
    let hashrate = getAccountHashrate(puid, coin, hashrateMiners);

    const handleEnterAccount = () => {
      const serverLang = getLocaleConfig(lang).serverLang;
      changeAccount({
        ...accountItemInfo,
        region_conf: {
          text: {
            [serverLang]: accountItemInfo.region_text,
          },
        },
      });

      afterChangeAccount();
    };

    const minerAlertCls =
      hashrate?.latest_alert?.type === 'miners'
        ? isCurrentLogin
          ? 'error-text-minor'
          : 'error-text'
        : '';
    const hashrateAlertCls =
      hashrate?.latest_alert?.type === 'hashrate'
        ? isCurrentLogin
          ? 'error-text-minor'
          : 'error-text'
        : '';

    return (
      <div
        className={`${styles.coinItem} ${
          isCurrentLogin ? styles.isCurrentLogin : ''
        } ${isSiblingSha256 ? styles.isSibling : ''}`}
        onClick={action(handleEnterAccount)}
      >
        {isSha256 && (
          <span
            className={`${styles.isSha256} ${
              isCurrentSha256 ? styles.currentSha256Symbol : ''
            }`}
          ></span>
        )}
        <span className={styles.coinName}>
          <img
            alt={coin.toLowerCase()}
            src={coinsConfig[lowerCase(coin)].icon}
          />
          <span>
            {coinsConfig[lowerCase(coin)].showName || upperCase(coin)}
          </span>
        </span>
        {/* TODO shares_15m_unit */}
        <span className={styles.hashrate}>
          <LoadingItem
            loading={isLoadingHahrate}
            isCurrentLogin={isCurrentLogin}
          >
            <span className={`${hashrateAlertCls}`}>
              {`${numberWithCommas(formatNumber(hashrate.shares_15m, 2))} ${
                hashrate.shares_15m_unit
              }${hashrate.hashrate_suffix}`}
            </span>

            <LatestAlert
              alertKey="hashrate"
              latestAlert={hashrate.latest_alert}
              className={hashrateAlertCls}
            />
          </LoadingItem>
        </span>

        <span className={`${styles.miners}`}>
          <LoadingItem
            loading={isLoadingHahrate}
            isCurrentLogin={isCurrentLogin}
          >
            <Tooltip
              title={t('panel.minerStatus', {
                active: numberWithCommas(hashrate.workers_active),
                inactive: numberWithCommas(hashrate.workers_inactive),
                dead: numberWithCommas(hashrate.workers_dead),
              })}
            >
              <span className={minerAlertCls}>
                <span className={`success-text ${minerAlertCls}`}>
                  {numberWithCommas(hashrate.workers_active)}
                </span>
                &nbsp;/&nbsp;
                <span
                  className={`${
                    isCurrentLogin ? 'error-text-minor' : 'error-text'
                  }  ${minerAlertCls}`}
                >
                  {numberWithCommas(hashrate.workers_inactive)}
                </span>
                &nbsp;/&nbsp;
                <span className={`${minerAlertCls ?? 'secondary-text'}`}>
                  {numberWithCommas(hashrate.workers_dead)}
                </span>
              </span>
            </Tooltip>
            <LatestAlert
              alertKey="miners"
              latestAlert={hashrate.latest_alert}
              className={minerAlertCls}
            />
          </LoadingItem>
        </span>
        <span className={styles.miningStatus}>
          <LoadingItem
            loading={isLoadingHahrate}
            isCurrentLogin={isCurrentLogin}
          >
            {/* {!hashrate.latest_alert?.trans && !hashrate.latest_alert?.type
              ? t('panel.Mining')
              : ''} */}
            {hashrate?.shares_15m ? t('panel.Mining') : ''}
          </LoadingItem>
        </span>
      </div>
    );
  },
);

export default AccountCoinItem;
