import React from 'react';
import LoadingIcon from '../LoadingIcon';
const loadingContainer = {
  marginTop: 100,
  marginBottom: 100,
};

const loadingWrapperStyle = {
  width: '100%',
  height: 'inherit',
  background: '#fff',
  display: 'flex',
  justifyContent: 'center',
};

const PageLoadingWrap = (props) => {
  const { loading, children } = props;
  if (loading) {
    return (
      <div style={loadingWrapperStyle}>
        <div style={{ ...loadingContainer }}>
          <LoadingIcon />
        </div>
      </div>
    );
  } else {
    return children;
  }
};
export default PageLoadingWrap;
