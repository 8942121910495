import flatten from 'flat';
const paginationMessage = {
  pagination: {
    first: 'primera página',
    last: 'última página',
    pageSize: '{pageSize}entrada/página',
    currentPage: 'página número{current}，en total{total}página(s)',
    totalInfo: 'en total{total}página(s)',
    goto: 'saltar a',
  },
};
const paginationMessageFlatten = flatten(paginationMessage);
export default paginationMessageFlatten;
