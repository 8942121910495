import {
  observable,
  action,
  computed,
  runInAction,
  makeObservable,
} from 'mobx';
import axios from 'axios';

import ReactGA from 'react-ga';
class SubAccountStore {
  @observable
  loading = false;

  @observable
  loadingCreate = false;

  @observable puid = null; //当前在子账号列表点击设置的子账号puid

  @observable
  currentCoin = 'BTC';

  @observable
  currentAlgorithm = 'sha256';

  @observable
  algorithmList = {}; //币种算法列表

  @observable
  regionList = []; //区域列表

  @observable
  coinTypeList = []; //所有已支持的币种

  @observable
  currentRegion = 'Beijing';

  @observable
  createFeedback = '';

  @observable
  validate = {
    subAccountName: '',
    withdrawAddress: '',
    opTimeLimit: '',
  };
  @observable
  noHashrateChecked = true;

  @action
  getAccountInitData = async (subaccount_name) => {
    this.loading = true;
    const res = await axios.get(`/account/sub-account/create/init`, {
      params: {
        subaccount_name,
      },
    });
    if (res && res.data) {
      runInAction(() => {
        const { algorithm_list, region_arr, coin_type_list } = res.data;
        if (algorithm_list && algorithm_list.sha256) {
          if (algorithm_list.sha256.BSV) {
            delete algorithm_list.sha256.BSV;
          }
        } // BSV 遭受51%攻击，经讨论还是要隐藏BSV
        this.algorithmList = algorithm_list;
        this.regionList = region_arr;
        this.coinTypeList = coin_type_list.filter(
          (coin) =>
            coin.toLowerCase() !== 'bsv' && coin.toLowerCase() !== 'ckb',
        );
        this.loading = false;
      });
    }
  };

  constructor() {
    makeObservable(this);
  }

  // 所有支持币种的字符串
  @computed get supportCoins() {
    return this.coinTypeList.join('、');
  }

  // 将算法币种区域进行解析，剔除算法，解析后格式如下
  /*{
      [coin]:{
        [region]:{
          default_url
          ping_url,
          region,
          region_name,
          text,
          user_enabled
        }
      }
    }*/
  @computed get coinsRegionMap() {
    const mapper = {};

    Object.keys(this.algorithmList).forEach((alg) => {
      Object.keys(this.algorithmList[alg]).forEach((coin) => {
        if (!mapper[coin]) {
          mapper[coin] = this.algorithmList[alg][coin];
        }
      });
    });

    return mapper;
  }

  // 所有支持币种的字符串
  @computed get currentRegionNode() {
    return this.algorithmList[this.currentAlgorithm][this.currentCoin][
      this.currentRegion
    ].region_name;
  }

  @action
  setCurrentCoin = (coin) => {
    this.currentCoin = coin;
  };

  @action
  setCurrentAlgorithm = (algorithm) => {
    this.currentAlgorithm = algorithm;
  };

  @action
  setCurrentRegion = (region) => {
    this.currentRegion = region;
  };

  /*
  
  region_node: cn
worker_name: rere
coin_type: BTC
bitcoin_address: 
lang: en
access_key: IKlmzCJIoZPYBYk8R3jieZPXwdTDrKs8dQxF6Orx
puid: 325863
  */
  // @action
  // create = async (
  //   subAccountName,
  //   withdrawAddress,
  //   successCallback,
  //   errorCallback,
  //   clickCreateTime,
  // ) => {
  @action
  create = async (
    subAccountName,
    coinType,
    regionNode,
    successCallback,
    errorCallback,
    clickCreateTime,
  ) => {
    try {
      const res = await axios.post(`/account/sub-account/create`, {
        // region_node: this.currentRegionNode,
        // worker_name: subAccountName,
        // coin_type: this.currentCoin,
        // bitcoin_address: withdrawAddress,
        worker_name: subAccountName,
        coin_type: coinType,
        region_node: regionNode,
        bitcoin_address: '',
      });
      if (res && res.data) {
        runInAction(() => {
          if (res.data.status === true) {
            successCallback && successCallback(res.data.puid);
          } else {
            /* 为了兼容后端不规范的接口格式 ⊙﹏⊙ ， 
            eg:
              {
                data:{
                  status:'这个名称已经被使用'
                },
                err_no:0
              }
            */
            if (typeof res.data.status === 'string') {
              // message.error(res.data.status);
              errorCallback && errorCallback(res.data.status);
              this.loadingCreate = false;
              // 创建失败记录
              const submitTimeCost = Date.now() - clickCreateTime; // 点击按钮到响应时间差
              this.sendEvent(
                'submitSubaccount',
                'TimeCostFromSubmitToResponse',
                submitTimeCost / 1000,
              );
            }
          }
        });
      }
      this.loadingCreate = false;
    } catch (error) {
      // 为了兼容后端不规范的接口格式 ⊙﹏⊙
      if (error.msg && typeof error.msg === 'string') {
        errorCallback && errorCallback(error.msg);
        this.createFeedback = error.msg;
      } else if (typeof error === 'object') {
        let { subAccountName, withdrawAddress, opTimeLimit } = this.validate;
        if (error.data) {
          if (error.data.bitcoin_address) {
            withdrawAddress = error.data.bitcoin_address;
          }
          if (error.data.worker_name) {
            subAccountName = error.data.worker_name;
          }
        }

        // if (msgData.worker_name) {
        //   subAccountName = msgData.worker_name;
        // }
        this.validate = { withdrawAddress, opTimeLimit, subAccountName };
        errorCallback && errorCallback(error.msg);
        // this.createFeedback = Object.keys(error.msg)
        //   .map(t => error.msg[t])
        //   .join(' <br>');
      }
      this.loadingCreate = false;
    }
  };

  @action
  setCreateLoading = (loading) => {
    this.loadingCreate = loading;
  };

  sendEvent = (eventAction, eventLabel, eventValue) => {
    ReactGA.event(
      {
        category: 'SubAccount',
        action: eventAction,
        label: eventLabel,
        value: eventValue,
      },
      ['poolGA'],
    );
    global._hmt &&
      global._hmt.push([
        '_trackEvent',
        'SubAccount',
        eventAction,
        eventLabel,
        eventValue,
      ]);
  };

  @action
  resetFeedback = () => {
    this.createFeedback = '';
  };

  @action
  reSetValidate = () => {
    this.validate = {
      subAccountName: '',
      withdrawAddress: '',
      opTimeLimit: '',
    };
  };

  @action
  setValidate = (validate) => {
    this.validate = validate;
  };
  /**
   * 改变当前在子账号列表点击设置的子账号puid
   */
  @action
  setCurrentPuid = (puid) => {
    this.puid = puid;
  };
  /**
   * 0算力隐藏子账户
   */
  @action
  setNoHashrateChecked = (hashrate) => {
    this.noHashrateChecked = hashrate;
  };
}

const subAccountStore = new SubAccountStore();

export { subAccountStore };
