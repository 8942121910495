import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
// import { Alert } from 'antd';
// import Ts from 'components/LocaleTranslator';
import routes from '../../pages/index';
import Navigation from './Navigation';
import NavigationMobile from './NavigationMobile';
import OpRegion from './OpRegion';
import UserNavigation from '../UserNavigation';
import './index.scss';
import { mobile as isMobile } from 'common/utils/browserDetect';

@withRouter
@inject('store')
@observer
class TopHeader extends Component {
  constructor(props) {
    super(props);
    this.appStore = this.props.store.appStore;
    this.userStore = this.props.store.userStore;
    this.isHome = this.props.isHome;
  }

  state = {
    path: '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let path = nextProps.location.pathname;
    return {
      path,
    };
  }

  componentDidMount() {
    this.setState({
      path: this.props.location.pathname,
    });
  }

  render() {
    const { isAuthenticated } = this.userStore;
    const { headerClass } = this.props;
    const { path } = this.state;

    // 当前是否在需要用户权限的管理模块路由
    let isCurrentInAuthPath = false;
    const currentPath = routes.filter(
      (item) =>
        item.path === path ||
        item.path.indexOf(
          path.split('/').length > 1 ? path.split('/')[1] : path,
        ) > -1,
    );
    if (currentPath && currentPath.length > 0) {
      const isCurrentNeedAuth = !!currentPath[0].requiresAuth;
      if (isCurrentNeedAuth) isCurrentInAuthPath = true;
    }

    const notShowUserNavigationPaths = [
      '/invite',
      '/welcome',
      '/advance-account',
      '/guardian',
    ]; // 对于需要权限且不需要展示二级导航的路由

    return (
      <div
        className={`nav-container ${
          this.isHome && isMobile ? 'mobile-nav-container' : ''
        }`}
      >
        <div className="nav-bar">
          <div className="nav-bar-inner">
            {(!this.isHome || !isMobile) && <Navigation></Navigation>}
            {this.isHome && isMobile && (
              <NavigationMobile
                opRegion={
                  <OpRegion
                    isPoolManageActive={
                      isCurrentInAuthPath &&
                      !notShowUserNavigationPaths.includes(path) // 邀请页面矿池管理按钮置不活跃状态
                    }
                  ></OpRegion>
                }
              ></NavigationMobile>
            )}
            {(!this.isHome || !isMobile) && (
              <OpRegion
                isPoolManageActive={
                  isCurrentInAuthPath &&
                  !notShowUserNavigationPaths.includes(path) // 邀请页面矿池管理按钮置不活跃状态
                }
              ></OpRegion>
            )}
          </div>

          {/* <div>
            <UserNavigation />
          </div> */}
        </div>
        {isAuthenticated && (
          <UserNavigation
            className="header-nav"
            isDisplay={
              isCurrentInAuthPath && !notShowUserNavigationPaths.includes(path)
            }
          />
        )}
      </div>
    );
  }
}
export default TopHeader;
