import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
const sizeMap = {
  lg: 32,
  md: 24,
  sm: 20,
};
const LoadingIcon = ({ size = 'md', color = '#1A7EE3', marginRight = 0 }) => (
  <LoadingOutlined
    style={{ fontSize: sizeMap[size], color, marginRight }}
    spin
  />
);
export default LoadingIcon;
