import flatten from 'flat';
const paginationMessage = {
  pagination: {
    first: '首頁',
    last: '尾頁',
    pageSize: '{pageSize}條/頁',
    currentPage: '第{current}頁，共{total}頁',
    totalInfo: '共{total}條',
    goto: '跳轉至',
  },
};
const paginationMessageFlatten = flatten(paginationMessage);
export default paginationMessageFlatten;
