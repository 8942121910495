import React, { useState, Fragment, useCallback } from 'react';
import { observer } from 'mobx-react';
import Ts from 'components/LocaleTranslator';
import { Dropdown, Tooltip } from 'antd';
import { useStores } from 'stores';
import SvgIcon from 'components/SvgIcon';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import './index.scss';
import AccountListPop from '../../AccountListPop';
import { getLocaleConfig } from 'common/utils';
import { coinsConfig } from 'common/config';
const UserList = observer(() => {
  const { userStore, appStore } = useStores();
  const [visible, setVisible] = useState(false);

  const onUserClick = () => {
    setVisible(!visible);
  };

  const onUserVisibleChange = (visible) => {
    setVisible(visible);

    if (visible) {
      userStore.getSubAccountList();
      const listDom = document.querySelector('.user-content__user-list');

      if (listDom) {
        setTimeout(() => {
          if (listDom) listDom.scrollTo(0, 0);
        }, 0);
      }
    }
  };

  const accountDisplay = useCallback(() => {
    const { accountInfo, isSmartPool } = userStore;
    const { lang } = appStore;
    let name = accountInfo.name;
    if (name && name.length > 9) {
      name = `${name.slice(0, 3)}...${name.slice(name.length - 4)}`;
    }
    let coinType = accountInfo.coin_type;
    if (isSmartPool) {
      coinType = <Ts id="common.smartPool" />;
    }
    //isGlogalZone
    let regionText = accountInfo.region_text
      ? accountInfo.region_text
      : accountInfo.region_conf?.text?.[getLocaleConfig(lang).serverLang];
    const coinsConfigData = coinsConfig[coinType.toLowerCase()];
    let isGlobalZone = coinsConfigData?.isGlobalZone;
    return (
      <span>
        <span title={accountInfo.name}>{name}</span>
        {
          <>
            {accountInfo.coin_type && ' @'}{' '}
            <span>{coinsConfigData.showName || coinType}</span>
          </>
        }
        {isGlobalZone && ` - `}
        {isGlobalZone ? (
          <Ts id="pages.homepage.globalZone" />
        ) : (
          regionText && ` - ` + regionText
        )}
      </span>
    );
  }, [userStore, appStore]);

  const readonlyUserDisplay = (account) => {
    const { isUserReadOnly, isWatcher, isShared } = userStore;
    if (isUserReadOnly) {
      if (isWatcher) {
        return (
          <Fragment>
            <span>
              {account} (
              <Ts id="common.header.watcher" /> )
            </span>
            <Tooltip title={<Ts id="common.header.watcherPermission" />}>
              <span className="secondary-text gap-l line-height-1 vertical-correct">
                <SvgIcon iconName="icon_tooltips" size="xxs" />
              </span>
            </Tooltip>
          </Fragment>
        );
      } else if (isShared) {
        return (
          <Fragment>
            <span>
              {account} (
              <Ts id="common.header.guardian" /> )
            </span>
            <Tooltip title={<Ts id="common.header.guardianPermission" />}>
              <span className="secondary-text gap-l  line-height-1 vertical-correct">
                <SvgIcon iconName="icon_tooltips" size="xxs" />
              </span>
            </Tooltip>
          </Fragment>
        );
      }
    }
    return null;
  };

  const { isUserReadOnly } = userStore;
  const account = accountDisplay();
  const readonlyUser = readonlyUserDisplay(account);

  return isUserReadOnly ? (
    <div className={`header-right-user readonly`}>{readonlyUser}</div>
  ) : (
    <Dropdown
      overlayClassName="header__user-dropdown"
      visible={visible}
      overlay={
        <AccountListPop
          visible={visible}
          togglePopup={(visible) => setVisible(visible)}
        />
      }
      overlayStyle={{ zIndex: 999 }}
      placement="bottomLeft"
      trigger={['click']}
      onVisibleChange={onUserVisibleChange}
    >
      <div className={`header-right-user ${visible ? 'active' : ''}`}>
        <div onClick={onUserClick} className="header__user-dropdown-trigger">
          <span>{account}</span>
          <CaretDownOutlined />
        </div>
      </div>
    </Dropdown>
  );
});

export default UserList;
