import flatten from 'flat';
const inviteMessage = {
  invite: {
    inviteTitle: 'Davet Ödülü',
    bindPhoneTip:
      'Katılmak için telefon numarasını bağladığınızda her iki taraf da komisyon alır ve kalıcı olarak geçerlidir.<br>Lütfen git <span style="font-weight:500;color:#333"> Kişisel Merkez -> Hesap Ayarları</span> telefon numaranızı bağlamak için.',
    needStep: 'Bir adım daha',
    createInviteAccountLoadingMessage:
      'Özel davet kodunuz ve alt hesabınız oluşturuluyor...',
    heading:
      'Arkadaşlarınızı birlikte madencilik yapmaya davet edin <br>vev <span style="color:#0A66C2">0.2% </span>indirim ödülü kazanın',
    cutoff:
      'Davetliler için <span style="color:#0A66C2">5% indirim</span> komisyonu',
    ruleDetails: 'Kural Ayrıntıları',
    totalRebate: 'Toplam Komisyon',
    details: 'Detaylar',
    totalAccept: 'Davetliler',
    minedAccept: 'Madencilik Yapan Davetliler',
    inviteCode: 'Davet Kodu',
    copy: 'Kopyala',
    inviteLink: 'Davet Bağlantısı',
    acceptInviteCode: 'Davet Edilen Kodu',
    generatePoster: 'Poster Oluştur',
    inviteRecord: 'Davet Kaydı',
    export: 'Dışa Aktar',
    rebateRecord: 'Komisyon Kaydı',
    invitee: 'Davetli',
    inviteeStatus: 'Durum',
    'inviteeStatus-unlocked': 'Çıkarılan',
    'inviteeStatus-registered': 'Kayıtlı',
    'inviteeStatus-mined': 'Kazılan',
    registerTime: 'Davet Edilme Zamanı',
    miningTime: 'Madenciliğe Başla',
    coinType: 'Para Birimi',
    region: 'Maden Bölgesi',
    inviteSubAccount: 'Komisyon Alt Hesabı',
    rebatePercent: 'Komisyon Oranı',
    op: 'İşlem Yap',
    originCoinCount: 'Tutar ',
    specialSubAccountTitle:
      'Komisyon ödülü alabilmeniz için bazı özel alt hesaplar oluşturduk. Davetlileriniz madenciliğe başladıktan sonra, madencilik kazançlarının komisyonu, farklı para birimlerindeki ve farklı bölgelerdeki ilgili alt hesaplarınıza ödenecektir.',
    specialSubAccountTip:
      'Varsayılan olarak, BTC para biriminin ABD ve Avrupa için komisyon alt hesapları oluşturulur. Davetliniz diğer para birimleri için bir madencilik alt hesabı oluşturduğunda, sizin için buna karşılık gelen özel bir alt hesap oluşturacağız.',
    rebateOpAddress: 'Adres Yönetimi',
    rebateOpDetail: 'Komisyon Ayrıntıları',
    downloadPoster: 'İndirmek',
    noData: 'Veri yok',
    gotoBind: 'bağlamaya git',
    boundPhone: 'onu bağladım',
    headtips: 'Etkinlik',
  },
};
const inviteMessageFlatten = flatten(inviteMessage);
export default inviteMessageFlatten;
