import { observable, action, computed, makeObservable } from 'mobx';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { getDefaultLang } from 'common/utils/lang';
class AppStore {
  @observable
  lang;

  constructor() {
    makeObservable(this);
    this.lang = getDefaultLang();
  }

  @computed get zenLang() {
    return this.lang.toLowerCase();
  }

  @action
  setLocaleLang(lang) {
    this.lang = lang;
    document.getElementsByTagName('html')[0].lang = lang;
    localStorage.setItem('lang', lang);
    dayjs.locale(lang === 'en' ? 'en' : 'zh-cn');
  }
  @action
  setAppLocale(appLocale) {
    this.appLocale = appLocale;
  }

  // 获取所有区base domain
  // TODO: 临时使用，分离完成后应该只有一个domain
  @action
  getAllUrlConfig = () =>
    new Promise(async (resolve, reject) => {
      // 获取所有的节点对应的信息
      try {
        const response = await axios.get(`public/v1/all-url-config`);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
}

const appStore = new AppStore();

export { appStore };
