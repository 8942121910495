import React from 'react';

import { appStore } from './AppStore';
import { dashboardStore } from './DashboardStore';
import { minersStore } from './MinersStore';
import { proxyMinersStore } from './ProxyMinersStore';
import { minerInfoStore } from './MinerInfoStore';
import userStore from './UserStore';
import { earningHistoryStore } from './EarningHistoryStore';
import { poolStatsStore } from './PoolStatsStore';
import { guardianStore } from './GuardianStore';
import { guardianBindStore } from './GuardianBindStore';
import { subAccountStore } from './SubAccountStore';
import { settingsStore } from './SettingsStore';
import { homepageStore } from './HomepageStore';
import { minerRankingStore } from './MinerRankingStore';
import { forkCoinStore } from './ForkCoinStore';
import { inviteStore } from './InviteStore';
import { earningListStore } from './EarningListStore';
import { mergeMiningStore } from './MergeMiningStore';
import { inviteEarningStore } from './InviteEarningStore';
import { ethForkStore } from './EthForkStore';
import { secondCoinSettingsStore } from './SecondCoinSettingsStore';

const stores = {
  appStore,
  dashboardStore,
  minersStore,
  proxyMinersStore,
  minerInfoStore,
  userStore,
  earningHistoryStore,
  poolStatsStore,
  guardianStore,
  guardianBindStore,
  subAccountStore,
  settingsStore,
  homepageStore,
  minerRankingStore,
  mergeMiningStore,
  forkCoinStore,
  inviteStore,
  earningListStore,
  inviteEarningStore,
  ethForkStore,
  secondCoinSettingsStore,
};

// For Class Components
export default stores;

// For Function Components
const StoresContext = React.createContext(stores);
export const useStores = () => React.useContext(StoresContext);
