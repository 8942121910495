import appLocaleData from 'react-intl/locale-data/ru';
import common from './ru-RU.common';
import pages from './ru-RU.pages';
import invite from './ru-RU.invite';
// import pagination from './en-US.pagination';

window.appLocale = {
  messages: Object.assign({}, common, pages, invite),

  locale: 'ru',
  data: appLocaleData,
};

export default window.appLocale;
