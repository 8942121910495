import SvgIcon from 'components/SvgIcon';
import { Copy, LoadingWrap } from 'components/widget';
import qs from 'qs';
import { Dropdown, Menu } from 'antd';
import Ts from 'components/LocaleTranslator';
import { computed, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link, withRouter } from 'react-router-dom';
import { formatNumber, truncString } from 'common/utils';
import './index.scss';
@withRouter
@inject('store')
@observer
class Setting extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
    this.appStore = this.props.store.appStore;
    this.userStore = this.props.store.userStore;
    this.settingsStore = this.props.store.settingsStore;
    this.dashboardStore = this.props.store.dashboardStore;
    this.subAccountStore = this.props.store.subAccountStore;
    this.state = {};
    this.topContentRef = React.createRef();
  }

  static defaultProps = {
    isManage: false,
  };

  componentDidMount() {
    window.addEventListener('click', this.handleClick);
    this.startViewTime = Date.now();
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick);
  }

  logout = () => {
    this.userStore.logout();
    this.props.history.push('/');
  };

  handleMenuClick = (event, key) => {
    this.subAccountStore.setCurrentPuid(null);
    this.clickEvent(key);
    if (event) {
      event.stopPropagation();
    }
  };

  handleClick = (e) => {
    // 在设置下拉框顶部区域（地址、余额）点击时，不收起下拉框，否则收起
    if (
      this.topContentRef.current &&
      this.topContentRef.current.contains(e.target)
    ) {
      return;
    }
    this.subAccountStore.setCurrentPuid(null);
  };

  handleClickSettings = (e) => {
    const { isManage, puid } = this.props;

    if (this.settingVisible) {
      this.subAccountStore.setCurrentPuid(null);
    } else {
      this.subAccountStore.setCurrentPuid(
        isManage ? puid : localStorage.getItem('puid'),
      );
    }
    if (!this.settingVisible) {
      this.userStore.getAccountInfo(() => {
        this.setState({ loadingAccount: false });
      });
    }
    this.clickEvent('Clicksetting');
    e.stopPropagation();
  };
  @computed get settingVisible() {
    const { puid, isManage } = this.props;
    if (isManage) {
      return this.subAccountStore.puid === puid;
    }
    return this.subAccountStore.puid === puid.toString();
  }
  clickEvent = (eventName) => {
    const { accountInfo } = this.userStore;
    this.clickRowTime = Date.now();
    const viewTimeCost = this.clickRowTime - this.startViewTime; // 载入到点击按钮时间差
    if (accountInfo.coin_type && accountInfo.region_conf.text) {
      this.sendEvent(
        eventName,
        'TimeCostFromViewTo' + eventName,
        parseInt(viewTimeCost / 1000),
      );
    }
  };

  sendEvent = (eventAction, eventLabel, eventValue) => {
    ReactGA.event(
      {
        category: 'Account',
        action: eventAction,
        label: eventLabel,
        value: eventValue,
      },
      ['poolGA'],
    );
    global._hmt &&
      global._hmt.push([
        '_trackEvent',
        'Account',
        eventAction,
        eventLabel,
        eventValue,
      ]);
  };

  getAddressSettingDisplay = (accountInfo) => {
    if (
      accountInfo.multi_address_list &&
      accountInfo.multi_address_list.length > 1
    ) {
      return <Ts id="common.setting.multAddressEnabled" className="font-sm" />;
    } else if (!accountInfo.address) {
      return (
        <Link
          to={`/settings/address?puid=${accountInfo.puid}`}
          className="address-link"
        >
          <Ts
            id="common.setting.setAddress"
            values={{ coinType: accountInfo.coin_type }}
          />
        </Link>
      );
    } else {
      return (
        <span className="font-sm">
          {truncString(accountInfo.address)}{' '}
          <Copy text={accountInfo.address} disableCopiedTip>
            <span>
              <SvgIcon iconName="icon_copy" clickable />
            </span>
          </Copy>
        </span>
      );
    }
  };

  handleEnterAccountSetting = (pathKey, puid, coinType) => {
    this.userStore.changeAccount({ puid });
    const location = {
      pathname: `/settings/${pathKey}`,
      search: qs.stringify({ puid, coinType }),
    };
    this.props.history.push(location);
  };

  getMenu = () => {
    const { loadingAccount } = this.state;
    const { accountInfo } = this.userStore;
    const { earnStats } = this.dashboardStore;
    const { isManage, puid, coinType, isHiddenAccount } = this.props;
    const balance = earnStats.unpaid
      ? earnStats.unpaid
      : formatNumber(accountInfo.unpaid / 1e8, 8);
    return (
      <div
        className={`setting-content ${!isManage ? 'nav-settings-menu' : ''}`}
        style={{ marginTop: isManage ? 10 : 0 }}
      >
        {isManage && <span className="triangel"></span>}
        {false && (
          <div>
            <div className="address-info" ref={this.topContentRef}>
              <label>
                <Ts id="pages.earningHistory.Unpaid" />
              </label>
              <LoadingWrap loading={loadingAccount}>
                <p>
                  <span>{balance}</span>
                  <span className="gap">{accountInfo.coin_type}</span>
                </p>
              </LoadingWrap>
              <>
                <label>
                  <Ts
                    id="common.setting.coinAddress"
                    values={{ coinType: accountInfo.coin_type }}
                  />
                </label>
                <LoadingWrap loading={loadingAccount}>
                  <p>{this.getAddressSettingDisplay(accountInfo)}</p>
                </LoadingWrap>
              </>
            </div>
            <div className="line" style={{ marginBottom: 0 }} />
          </div>
        )}
        {!isHiddenAccount && (
          <Menu onClick={(e) => this.handleMenuClick(e.domEvent, e.key)}>
            {isManage && (
              <Menu.Item
                key="clickChangeWithdrawAddr"
                onClick={() => {
                  this.handleEnterAccountSetting('address', puid, coinType);
                }}
              >
                <Ts id="common.setting.menu.address" />
              </Menu.Item>
            )}
            <Menu.Item
              key="clickWatcher"
              onClick={() => {
                this.handleEnterAccountSetting('watcher', puid, coinType);
              }}
            >
              <Ts id="common.setting.menu.watcher" />
            </Menu.Item>
            {/* {isManage && (
              <Menu.Item
                key="clickShareData"
                onClick={() => {
                  this.handleEnterAccountSetting('share', puid, coinType);
                }}
              >
                <Ts id="common.setting.menu.shareData" />
              </Menu.Item>
            )} */}
            <Menu.Item
              key="clickAlertSettings"
              onClick={() => {
                this.handleEnterAccountSetting('alert', puid, coinType);
              }}
            >
              <Ts id="common.setting.menu.alertSettings" />
            </Menu.Item>
            <Menu.Item
              key="clickApikey"
              onClick={() => {
                this.handleEnterAccountSetting('apikey', puid, coinType);
              }}
            >
              <Ts id="common.setting.menu.apikey" />
            </Menu.Item>
          </Menu>
        )}
      </div>
    );
  };

  render() {
    const { isManage, isActive = false, puid } = this.props;
    return (
      <div
        id="settingsContainer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (this.settingVisible) {
            this.subAccountStore.setCurrentPuid(null);
          } else {
            this.subAccountStore.setCurrentPuid(
              isManage ? puid : localStorage.getItem('puid'),
            );
          }
        }}
      >
        <Dropdown
          // getPopupContainer={() => document.getElementById('settingsContainer')}
          overlay={this.getMenu()}
          overlayClassName={`setting-dropdown-container ${
            this.props.className ? this.props.className : ''
          }`}
          placement="bottomCenter"
          trigger={['click']}
          visible={this.settingVisible}
          destroyPopupOnHide
        >
          <a
            className={`ant-dropdown-link${!isManage ? ' font-lg' : ''}
            ${isActive ? ' setting-menu-active' : ''}`}
            onClick={this.handleClickSettings}
          >
            {!isManage ? (
              <span>
                <Ts id="common.header.setting" />{' '}
                <SvgIcon
                  iconName="icon_menu_arrow_down"
                  size="menuArrow"
                  className="nav-menu-arrow"
                />
              </span>
            ) : (
              <span className="setting-menu-icon">
                <SvgIcon iconName="icon_subaccount_settingItem"></SvgIcon>
              </span>
            )}
          </a>
        </Dropdown>
      </div>
    );
  }
}

export default Setting;
