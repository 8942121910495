import flatten from 'flat';
const commonMessage = {
  common: {
    locale: 'İngilizce',
    header: {
      home: 'Havuz Evi',
      dashboard: 'Gösterge Paneli',
      getCoin: 'Coin Al',
      help: 'Yardım',
      miners: 'Madenciler',
      poolStats: 'Havuz İstatistikleri',
      service: 'Hizmet',
      tools: 'Araçlar',
      setting: 'Ayarlar',
      guardian: 'Paylaşım',
      guardianPermission:
        'Hesaba salt okuma erişimi olan yetkili bir kullanıcısınız.',
      watcher: 'İzleyici',
      watcherPermission:
        'İzleyici, alt hesaba salt okunur erişimi olan yetkili bir kullanıcıdır.',
      userCenter: 'Kullanıcı Merkezi',
      SignOut: 'Oturumu Kapat',
      accountSetting: 'Hesap Ayarları',
      poolManagePanel: 'Gösterge Paneli',
      watcherMode: 'İzleyici Modu',
      scanToService: 'Yardım almak için QR kodunu tarayın',
      bitdeer: 'Bitdeer',
      hashnest: 'HashNest',
      explorer: 'Blockchain Gezgini',
      app: 'UYGULAMA',
      getBCHA: 'BCHA Çekme',
      candy: 'Candy',
      alertBar:
        '<b>10 Ağustos 22:00-24:00 (UTC+8)</b>\'de CloverPool madencilik havuzunda büyük çapta bir veri bakımı gerçekleştirilecek. Bazı işlevler kullanılamayacaktır ve madencilik hizmeti etkilenmeyecektir. <a href="https://help.cloverpool.com/hc/en-us/articles/900002124306" rel="nofollow noopener noreferrer" target="_blank">Detaylar için tıklayın</a>',
      introTip:
        'Gösterge Paneli, Madenciler, Kazançlar ve Alt hesap ayarları gibi işlevler "Havuz Gösterge Paneli,"nda birleştirilmiştir.',
    },
    nav: {
      'nav-explorer': 'Gezgin',
      'nav-explorer-btc': 'Bitcoin',
      'nav-explorer-eth': 'Ethereum',
      'nav-explorer-bch': 'Bitcoin Cash',
      'nav-explorer-etc': 'Ethereum Classic',
      'nav-explorer-ltc': 'Litecoin',
      'nav-pool': 'Havuz',
      'nav-pool-home': 'Havuz Evi',
      'nav-pool-data': 'Havuz Verileri',
      'nav-pool-candy': 'Candy',
      'nav-pool-tools': 'Araçlar ve Yazılım',
      'nav-pool-cs': 'Müşteri Servisi',
      'nav-wallet': 'Cüzdan',
      'nav-help': 'Yardım',
      'nav-navigation': 'Blockchain Gezintisi',
      'nav-inscriptions': 'Kazımak',
      'nav-app': 'UYGULAMA',
      'nav-collaborate': 'İşbirliği',
      'nav-brc20': 'BRC-20',
      'nav-tools': 'Alet',
      'nav-tools-address-monitoring': 'Address Monitoring',
      'nav-tools-address-detail': 'Address Txn Details',
      'nav-tools-address-report': 'Address Report',
      'nav-tools-address-increaseminerfee': 'Increase Txn Fees',
      'nav-tools-address-txreverse': 'Transaction Reversal',
      'nav-tools-address-doublespend': 'Txn Double-spending',
      'nav-tools-APIService': 'API Service',
      'nav-tools-accelerate': 'Txn Accelerator(CPFP)',
      'nav-tools-acceleratebatch': 'Batch Txns Accelerator',
      'nav-tools-TransactionBroadcast': 'Transaction Broadcast',
      'nav-tools-special': 'Rare Sats Query',
      'nav-tools-receipt': 'Transaction Receipt',
      'nav-tools-pickupsats': 'Transfer Rare Sats',
      'nav-tools-bulkpayment': 'Batch Payment',
      'nav-tools-address-graph': 'Address Graph',
      'nav-runes': 'Runes Mint',
      'nav-runes-explorer': 'Runes Explorer',
      'nav-runes-main': 'Runes',
      tags: {
        'runes-free': '0 Fees and Giveaway GOODS',
        new: 'New',
      },
    },
    menu: {},
    setting: {
      balance: 'Mevcut Bakiye',
      coinAddress: '{coinType} Adresi',
      setAddress: 'Lütfen bir {coinType} Adresi ayarlayın.',
      multAddressEnabled: 'Çok adresli ödeme etkinleştirildi',
      menu: {
        hide: 'Sakla',
        address: 'Cüzdan Adresi',
        minPay: 'Minimum Ödeme',
        payWithBtc: 'BTC ile Öde',
        earingsHistory: 'Kazanç',
        shareData: 'Hisse Hesabı',
        watcher: 'İzleyici Ayarları',
        apikey: 'API KEY',
        alertSettings: 'Uyarı Ayarları',
        onebuttonSwitch: 'Tek Tuşla Geçiş',
        userCenter: 'Kullanıcı Merkezi',
        signOut: 'Oturumu Kapat',
        delete: 'Sil',
      },
      confirm: 'TAMAM',
      cacel: 'İptal',
      deleteConfirm: 'Silme Onayı',
      cannotDelete: 'Alt hesap silinemiyor',
      cannotDeleteView: 'Şu ana görüntülenmekte olan alt hesap silinemez',
      deleteCondition:
        '<span class="subAccountName">{accountMessage}</span> alt hesabı silinemiyor, Yalnızca aşağıdaki iki şartı karşılayan alt hesaplar silinebilir:',
      cannotDeleteReason:
        "1. Alt hesabın son 180 gündeki hash oranı 0'dır; <br/>2. Alt hesabın ödenmemiş kripto para birimi (Hediye coin dahil) 0'dır.",

      deleteName: 'Alt hesabı siliyorsunuz:',
      deleteInfo:
        '* "Tamam"a tıkladıktan sonra alt hesap kalıcı olarak silinecek ve geri alınamayacaktır;<br/>* Silme işleminden sonra alt hesabın hash oranı eğrisi, gelir kaydı ve diğer verileri geri alınamaz.',
      deleteSuccess: 'Alt hesap başarıyla silindi',
    },
    footer: {
      services: 'Hizmetler',
      Products: 'Ürünler',
      help: 'Yardım',
      btcTool: 'BTC Aracı.',
      btcSmartAgent: 'BTC Smart Agent',
      btcComFirmware: 'CloverPool Donanım Yazılımı',
      blockchainExplorer: 'Blockchain Gezgini',
      App: 'UYGULAMA',
      apiDocs: 'API Belgeleri',
      vipApplication: 'VIP Uygulaması',
      cloudMinePoolService: ' Bulut Maden Havuzu Yapı Hizmeti',
      miningTutorial: 'Madencilik Eğitimi',
      FAQ: 'SSS',
      Announcements: 'Duyurular',
      ticketSystem: 'Bilet Sistemi',
    },
    pageTitle: {
      home: 'CloverPool(formerly BTC.com) - Dünyanın önde gelen kapsamlı çoklu para birimi madencilik havuzu',
      dashboard: 'Gösterge Paneli - CloverPool(formerly BTC.com)',
      miners: 'Madenciler - CloverPool(formerly BTC.com)',
      earningHistory: 'Kazanç Geçmişi - CloverPool(formerly BTC.com)',
      poolStats: 'Havuz Verileri - CloverPool(formerly BTC.com)',
      guardian: 'Guardian Davet Bağlantısı - CloverPool(formerly BTC.com)',
      guardianBind:
        'Guardian Bağlama Davet Bağlantısı - CloverPool(formerly BTC.com)',
      subAccount: 'Bir alt hesap oluşturun - CloverPool(formerly BTC.com)',
      subAccountManage: 'Alt Hesap Yönetimi - CloverPool(formerly BTC.com)',
      settings: 'Ayarlar - CloverPool(formerly BTC.com)',
      getCoin: 'Coin Al - CloverPool(formerly BTC.com)',
      tools: 'Araçlar ve Yazılım - CloverPool(formerly BTC.com)',
      bitdeer: 'Bitdeer - CloverPool(formerly BTC.com)',
      watcherExpired:
        'İzleyici yetkisinin süresi doldu - CloverPool(formerly BTC.com)',
      watcherIncorrect:
        'İzleyici bağlantısı hatası - CloverPool(formerly BTC.com)',
      fundAuthorization:
        'Fon Yetkilendirme Sözleşmesi - CloverPool(formerly BTC.com)',
      app: 'Uygulama İndirme - CloverPool(formerly BTC.com)',
      invite: 'Invitation Reward- CloverPool(formerly BTC.com)',
    },
    breadCrumbs: {
      home: 'Ana Sayfa',
      dashboard: 'Gösterge Paneli',
      miners: 'Madenciler',
      earningHistory: 'Kazanç',
      poolStats: 'Havuz Verileri',
      shareAccount: 'Hisse Hesabı Ayarı',
      guardianBindingInvitation: 'Guardian Bağlama Davet Bağlantısı',
      guardianBindingLink: 'Guardian Hesap Yönetimi',
      subAccountManage: 'Alt Hesap Yönetimi - {type}',
      hiddenSubAccounts: 'Gizli Alt Hesap Yönetimi',
      settings: 'Ayarlar',
      getCoin: 'Coin Al',
      tools: 'Araçlar ve Yazılım',
      fundAuthorization: 'Fon Yetkilendirme Sözleşmesi',
    },
    currency: {
      currency: 'USD',
    },
    allSwitchMode: {
      smart_sha256: 'Akıllı Havuz',
      otcNotice: `Hash oranınızı Akıllı Havuz'a geçirmek üzeresiniz, "Tamam"ı tıkladığınızda {fundLink} kısmını okuyup kabul etmiş sayılırsınız`,
      fundLink: `"CloverPool Akıllı Havuz Hizmet Sözleşmesi"`,
      autoTip: `Akıllı Havuz modunu kullanmak, hash oranını CloverPool'a barındıracağınız anlamına gelir; bu da BTC, BCH ve BSV arasında madencilik için en fazla kârı sağlayacak şekilde geçiş yapacaktır.`,
      originalTip:
        'Orijinal para birimi modunu seçerseniz, SHA256 hash, otomatik geçiş yerine yalnızca sizin tarafınızdan seçilen madeni paranın madenciliğini yapacaktır.',
    },
    operation: {
      name: 'İşlem',
      success: 'İşlem başarılı!',
      failed: 'İşlem başarısız!',
    },
    earning: {
      balanceTip:
        "Hesaplama tamamlandıktan sonra, şu durumlar dışında genellikle 24 saat içinde ödemeyi tamamlarız:<br>1.Adres belirlenmemişse; <br>2. Adres belirlenmiş ama biriken ödeme tutarı minimum ödeme eşiğine ulaşmamışsa; <br>3. Adres ekleme, adres değiştirme, adres silme, oran değiştirme gibi işlemler 48 saatlik ödeme askıya alma kuralını tetiklenmişse; <br>4. Risk Kontrol Kuralı tetiklenmişse; <br>5. Para birimi bir hard fork geçirmişse ya da %51'lik saldırı, diğer önemli yükseltmelerle veya olaylarla karşılaşmışsa.",
    },
    meta: {
      keywords:
        'Bitcoin,BTC,BCH,Bitcoin Cash,LTC, Litecoin,ETC,KAS,Kaspa,via,viabtc,f2pool,antpool,foundry,binance,luxor,poolin,pool,miners,worker,machine,tools,mint,brc20,runes,cloverpool,bitdeer,bitmain',
      description:
        'CloverPool, Bitcoin, Litecoin, Bitcoin Cash, Kaspa ve diğer kripto paraların madenciliğini destekleyen madenciler için tamamen yeni bir seçenektir. Çok daha iyi kullanıcı deneyimi, çok daha düşük ücretler ve çok daha güçlü hizmet ile.',
    },
    gotIt: 'Anladım',
    showAll: 'Tümünü Göster',
    foldMe: 'Tümünü Katla',
    copied: 'Kopyalandı',
    more: 'Daha Fazla',
    none: 'Hiçbiri',
    on: 'Açık',
    off: 'Kapalı',
    all: 'Tümü',
    modify: 'Değiştir',
    submit: 'Gönder',
    ok: 'TAMAM',
    cancel: 'İptal',
    new: 'Ekle',
    address: 'Adres',
    notFoundTip: 'Üzgünüz, aradığınız sayfa mevcut değil',
    export: 'Dışa Aktar',
    startDate: 'Başlangıç tarihi',
    endDate: 'Bitiş tarihi',
    smartPool: 'Akıllı Havuz',
    status: 'Durum',
    notice: 'Bildiri',
    yes: 'Evet',
    no: 'Hayır',
    coin: 'Coinler',
    custom: 'Özel',
    hide: 'Sakla',
    less: 'Daha az',
    details: 'Detaylar',
    region: {
      cn: 'Pekin (Çin)',
      sz: 'Shenzhen (Çin)',
      us: 'ABD',
      eu: 'AB',
      sg: 'Singapur',
    },
    LegalStatement:
      "İşbu sözleşmeyle Küba, İran, Kuzey Kore, Suriye,Rusya veya ilgili ülkeler, hükümetler veya uluslararası kuruluşlar tarafından uygulanan veya yönetilen yaptırımlara tabi olan diğer ülkelerde veya bölgelerde ikamet etmediğimi ve Çin Anakarası ÖYB'de ikamet etmediğimi onaylıyorum. CloverPool tarafından sağlanan madencilik havuzu hizmeti, bulunduğum ülke veya bölgedeki yasalara, düzenlemelere ve ilgili politikalara uygundur.",
    LegalStatementConfirm:
      'Yaşadığım ülke veya bölgede CloverPool tarafından sağlanan hizmetlerin kullanılmasının yasa dışı olmasından kaynaklanan veya bundan dolayı meydana gelen tüm yasal riskler ve yükümlülükler tamamen bana aittir.',
  },
};
const commonMessageFlatten = flatten(commonMessage);
export default commonMessageFlatten;
