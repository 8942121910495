import React, { useEffect, useState } from 'react';
import { Radio, Tabs } from 'antd';
import styles from './index.module.scss';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
const { TabPane } = Tabs;

const RadioTab = (props) => {
  const [type, setType] = useState('');
  const { items, defaultType, onChange } = props;

  const location = useLocation();

  const addParams = (path) => {
    let search = location.search;
    let searchParams = new URLSearchParams(search);
    return `${path}?${searchParams.toString()}`;
  };

  useEffect(() => {
    setType(defaultType);
  }, [defaultType]);
  const showRadioTab = (item) => {
    return (
      <label
        value={item.name}
        key={item.name}
        onClick={() => {
          setType(item.name);
          onChange && onChange(item.name);
        }}
        className={
          styles['btn'] + (type === item.name ? ' ' + styles['active'] : '')
        }
      >
        {item.icon && (
          <img
            key={item.icon}
            src={item.icon}
            className={styles['logo']}
            alt={item.icon}
          />
        )}
        <span className={styles['text']}>{item.name}</span>
      </label>
    );
  };
  return (
    <>
      <div className={styles.radiotab} key="RadioTab">
        <div data-toggle="buttons" className={styles['btn-toggle']}>
          {items.map((v, i) => {
            return v.link ? (
              <NavLink to={addParams(v.link)}>{showRadioTab(v)}</NavLink>
            ) : (
              showRadioTab(v)
            );
          })}
        </div>
        {/* <Radio.Group
          value={type}
          key={type}
          onChange={(e) => {
            setType(e.target.value);
            onChange && onChange(e.target.value);
          }}
          className={styles['btn-toggle']}
        >
          {items.map((v, i) => {
            return v.link ? (
              <NavLink to={addParams(v.link)}>{showRadioTab(v)}</NavLink>
            ) : (
              showRadioTab(v)
            );
          })}
        </Radio.Group> */}
      </div>
    </>
  );
};
export default RadioTab;
