import React from 'react';
import { Popover } from 'antd';
import SvgIcon from 'components/SvgIcon';
import { truncString } from 'common/utils';

export const AddressEarningTip = ({ tipText, title, list = [], placement }) => {
  if (list && list.length > 0) {
    return (
      <Popover
        overlayClassName="address-earning-tooltip"
        placement={placement}
        content={
          <div
            style={{
              maxHeight: 300,
              minWidth: 470,
              overflowY: 'auto',
              paddingRight: 9,
            }}
          >
            <h2>{title}</h2>
            <ul>
              {list.map((item, index) => {
                return (
                  <li
                    className="flex justify-between margin-bottom-sm"
                    key={index}
                  >
                    <span className="margin-right-md">
                      {truncString(item.address, 40)}
                    </span>
                    <span>{item.value}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        }
      >
        {tipText ? (
          tipText
        ) : (
          <span className="gap-l secondary-text inline-flex">
            <SvgIcon iconName="icon_details" clickable size="xxs" />
          </span>
        )}
      </Popover>
    );
  } else {
    return null;
  }
};
