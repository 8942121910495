// 分叉币领取糖果
import { observable, action, runInAction, makeObservable } from 'mobx';
import axios from 'axios';
import { defaultListPage } from 'common/config';
class ForkCoinStore {
  @observable
  loadingStats;

  @observable
  earningStatsInfo;

  @observable
  loadingList;

  @observable
  historyList;

  @observable
  historyListTotal;

  @observable
  historyListPage;
  constructor() {
    makeObservable(this);
    this.loadingStats = false;
    this.loadingList = false;
    this.historyList = [];
    this.earningStatsInfo = {
      total_paid: '',
      unpaid: '',
      earnings_today: '',
      earnings_yesterday: '',
    };
    this.historyListPage = defaultListPage;
    this.historyListTotal = 0;
  }
  // 修改BCHA挖矿地址
  @action
  updateBCHAAddress = async (coin, address) => {
    return axios.post(`/account/${coin}/address/update`, {
      new_address: address,
    });
  };

  @action
  getBCHAEarningHistoryList = async (coinType) => {
    this.loadingList = true;
    const res = await axios.get(`/account/${coinType}/earn-history`, {
      params: {
        ...this.historyListPage,
        reason: 1,
        is_decimal: 1,
        record_type: coinType,
      },
    });
    if (res && res.data) {
      runInAction(() => {
        this.historyList = res.data.list;
        this.historyListTotal = res.data.total_count;
        this.loadingList = false;
      });
    }
  };

  @action
  getBCHAEarningStatsInfo = async (coinType) => {
    this.loadingStats = true;
    const res = await axios.get(`/account/${coinType}/earn-stats`, {
      params: {
        is_decimal: 1,
        record_type: coinType,
      },
    });
    if (res && res.data) {
      runInAction(() => {
        this.earningStatsInfo = res.data;
        this.loadingStats = false;
      });
    }
  };

  @action
  setBCHAHistoryListPage = (page, page_size) => {
    this.historyListPage = {
      page,
      page_size,
    };
  };
}

const forkCoinStore = new ForkCoinStore();
export { forkCoinStore };
