import React from 'react';
import { Tooltip } from 'antd';
import SvgIcon from 'components/SvgIcon';

const TooltipIcon = ({
  title,
  className = '',
  children,
  size = 'sm',
  width = 'auto',
  iconColor = 'inherit',
}) => {
  return (
    <Tooltip title={title} overlayStyle={{ minWidth: width }}>
      <span
        className={`${className} inline-flex items-center`}
        style={{ color: iconColor }}
      >
        {children}
        <SvgIcon
          size={size}
          iconName="icon_tooltips"
          className={`${title ? 'pointer-cursor margin-left-xs' : ''}`}
        />
      </span>
    </Tooltip>
  );
};

export default TooltipIcon;
