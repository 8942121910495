import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import './index.scss';
const BreadcrumbBar = (props) => {
  const { title, crumbs } = props;
  const search = window.location.search;

  return (
    <div className="view-width breadcrumbbar">
      <div className="breadcrumbbar-title">{title}</div>
      <div className="breadcrumb">
        <Breadcrumb>
          {crumbs.map((item, index) => {
            if (item.link) {
              return (
                <Breadcrumb.Item key={`${index}${item.text}>`}>
                  {item.link === '/home' ? (
                    <Link
                      to={{
                        pathname: '/',
                        search: search,
                      }}
                      className="link"
                    >
                      {item.text}
                    </Link>
                  ) : (
                    <Link className="link" to={`${item.link}${search}`}>
                      {item.text}
                    </Link>
                  )}
                </Breadcrumb.Item>
              );
            } else {
              return (
                <Breadcrumb.Item key={`${index}${item.text}>`}>
                  {item.text}
                </Breadcrumb.Item>
              );
            }
          })}
        </Breadcrumb>
      </div>
    </div>
  );
};
export default BreadcrumbBar;
