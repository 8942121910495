import appLocaleData from 'react-intl/locale-data/fr';
import common from './fr.common';
import pages from './fr.pages';
import pagination from './fr.pagination';
import invite from './fr.invite';

window.appLocale = {
  messages: Object.assign({}, common, pages, pagination, invite),

  locale: 'fr',
  data: appLocaleData,
};

export default window.appLocale;
