import appLocaleData from 'react-intl/locale-data/zh';
import common from './zh-HK.common';
import pages from './zh-HK.pages';
import pagination from './zh-HK.pagination';
import invite from './zh-HK.invite';

window.appLocale = {
  // 合并所有 messages, 加入组件的 messages
  messages: Object.assign({}, common, pages, pagination, invite),

  // locale
  locale: 'zh-HK',
  // react-intl locale-data
  data: appLocaleData,
};

export default window.appLocale;
