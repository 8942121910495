import {
  observable,
  action,
  runInAction,
  computed,
  makeObservable,
} from 'mobx';
import axios from 'axios';
import { groupBy } from 'lodash';
import Cookies from 'js-cookie';
import queryString from 'qs';
import history from 'common/utils/history';
import qs from 'qs';
import ReactGA from 'react-ga';
import { getSecondCoinParams, isSmartPoolCoin } from 'common/utils';
import { WATCHER_AUTHORITIES_VALUES } from 'common/constants';
const MAIN_DOMAIN_ALL_LOWERCASES =
  process.env.REACT_APP_MAIN_DOMAIN_ALL_LOWERCASES;
const SELF_NO_PROTOCAL = process.env.REACT_APP_SELF_NO_PROTOCAL;
class UserStore {
  __default_account = {
    puid: 0,
    // uid: 0,
    access_key: '',
    coin_type: '',
    current_coin: '',
    user_name: '',
    region: '',
    name: '',
    // user_info: {
    //   mail: '',
    //   phone: {
    //     country: '',
    //     number: '',
    //   },
    //   avatar_pic: '',
    // },
    // TODO: account 目前只能通过v1/account/sub-account/algorithms/morelist 获取
    account: {
      name: '', // 账户名
      have_default: false, // 是否有默认子账户
    },
    region_conf: {
      text: {
        'zh-cn': '',
        en: '',
      },
    },
  };

  __default_payment_info = {
    earnings_before: false,
    earnings_today: 0,
    earnings_yesterday: '',
    earnings_yesterday_payment_time: '',
    earnings_yesterday_stats: '',
    last_payment_time: '',
    pending_payouts: '',
    total_paid: '',
    unpaid: '',
  };

  __default_user_contact_info = {
    uid: '',
    mail: '',
    phone: {
      country: '',
      number: '',
    },
    avatar_pic: '',
  };

  // 当前账户信息
  @observable accountInfo = this.__default_account;
  // 第二币种当前账户信息
  @observable secondCoinAccountInfo = this.__default_account;

  // 当前账户信息payment信息
  @observable paymentInfo = this.__default_payment_info;
  @observable secondCoinPaymentInfo = this.__default_payment_info;
  // 登录用户的用户级别信息（UID、联系方式）
  @observable userContactInfo = this.__default_user_contact_info;
  // 是否登录
  @observable isAuthenticated = false;
  // 是否是观察者,(是观察者情况下，isAuthenticated 会设为 true)
  @observable isWatcher = false;
  // 是否是被共享者,(是被共享者情况下，isAuthenticated 会设为 true)
  @observable isShared = false;
  // 子账户列表，账户区域列表 -> 算法列表 -> 子账户列表
  @observable algorithmAccounts = {
    loading: false,
    firstTimeLoading: true,
    errorMsg: '',
    data: [],
  };

  // puid 对应的算力列表
  // TODO: 目前需要单独请求各个puid，完全没必要，合并到子账户列表中
  @observable hashrateMiners = {};

  // 子账户 专业版
  @observable advanceAccount = {
    loading: false,
    errorMsg: '',
    data: [],
  };

  @observable isRefreshingNewToken = false;

  @observable initializing = false;

  @observable fetchingSubAccount = false;

  // 用户页面权限（目前用户控制观察者链接显示情况）
  @observable userAuthority = {
    page_authorities: [''], //页面级权限
  };

  @observable completedGetUserAuthority = false; //是否已得到观察者用户权限
  @observable loadingGetUserAuthority = false;
  // 用户是否有收益页面权限 默认为 false
  @observable hasUserAuthorityForEarnings = false;
  // 用户是否有矿机页面权限 默认为 false
  @observable hasUserAuthorityForMiners = false;
  // 用户是否有挖矿送币页面权限 默认为 false
  @observable hasUserAuthorityForGetCoin = false;

  constructor() {
    makeObservable(this);
  }

  // 用户只读（观察者、共享者）
  @computed get isUserReadOnly() {
    return this.isWatcher || this.isShared;
  }

  // 子账户列表，由最小维度的子账户组成
  @computed get subAccounts() {
    let result = [];
    this.algorithmAccounts.data.forEach((regions) => {
      regions.algorithms.forEach((algorithm) => {
        result = result.concat(algorithm.coin_accounts);
      });
    });

    return result;
  }

  /**
   * 判断当前账户是否含有子账户
   */
  @computed
  get hasSubAccount() {
    let { puid } = this.accountInfo;
    return !!puid;
  }

  /**
   * 是否是机枪子账户
   *
   * @readonly
   * @memberof UserStore
   */
  @computed
  get isSmartPool() {
    let { coin_type } = this.accountInfo;
    return isSmartPoolCoin(coin_type);
  }

  /**
   * 用户现有联系方式列表
   * @readonly
   */
  @computed
  get userContactTypeList() {
    let contactTypeList = [];
    // 如果没有子账户，account/info 中的 contact 没数据，所以从 sub-account/info 中的user_info 取更保险
    const contact = this.userContactInfo;
    if (!contact) {
      return [];
    }
    if (contact.mail || contact.mail.length > 0) {
      contactTypeList.push({ type: 'mail', value: contact.mail });
    }
    if (contact.phone && contact.phone.number) {
      contactTypeList.push({
        type: 'sms',
        value: contact.phone ? contact.phone.number : '',
      });
    }

    return contactTypeList;
  }

  // 重置store
  initStore = () => {
    runInAction(() => {
      this.accountInfo = this.__default_account;
      this.isAuthenticated = false;
      this.isWatcher = false;
      this.isShared = false;
      this.isRefreshingNewToken = false;
      this.algorithmAccounts = {
        loading: false,
        firstTimeLoading: true,
        errorMsg: '',
        data: [],
      };
      this.hiddenAlgorithmAccounts = {
        loading: false,
        firstTimeLoading: true,
        errorMsg: '',
        data: [],
      };
      this.hashrateMiners = {};
      this.advanceAccount = {
        loading: false,
        errorMsg: '',
        data: [],
      };
    });
  };

  // 读取缓存的用户信息
  getCachedAccount = () => {
    let account = this.accountInfo;
    let storageAccount = localStorage.getItem('accountInfo');
    if (storageAccount) {
      storageAccount = JSON.parse(storageAccount);
      const isCurrent = this.checkCachedAccount(storageAccount);

      if (isCurrent) {
        account = {
          ...account,
          ...storageAccount,
        };
      }
    }

    return account;
  };

  // 检查缓存用户是否是用户中心当前账户
  // 如果不是，清除缓存
  checkCachedAccount = (account) => {
    const uid = Cookies.get(process.env.REACT_APP_USER_UID);
    const cachedUid = account.user_id || account.uid || 0;
    if (uid && uid !== cachedUid.toString()) {
      localStorage.removeItem('accountInfo');
      localStorage.removeItem('puid');
      return false;
    }
    return true;
  };

  // 获取puid/access_key,从localStorage中获取，如果获取不到，跳转到登录页面
  // TODO: 用户中心改造完成后，采用JWT修改
  @action
  initAccount = () => {
    runInAction(() => {
      const self = this;
      this.accountInfo = {
        ...this.accountInfo,
        puid: 0,
        uid: 0,
      };
      const isAuthenticated = this.checkLoginState();
      if (isAuthenticated) {
        if (!this.isUserReadOnly) {
          this.accountInfo = this.getCachedAccount();
        }
        // check login state every 10 min
        // TODO: maybe need shorter
        setInterval(() => {
          self.checkLoginState();
        }, 1000 * 60 * 10);
      }
    });
  };

  /**
   * 检查登录状态
   * 1. 未登录或者用户中心jwt已过期(15天)
   * 2. 矿池校验jwt时间窗口已失效(3小时)
   * 3. 已登录
   *
   * @memberof UserStore
   */
  @action
  checkLoginState = async () => {
    const jwtToken = Cookies.get(process.env.REACT_APP_USER_JWT_TOKEN);
    const userRefreshToken = Cookies.get(
      process.env.REACT_APP_USER_REFRESH_TOKEN,
    );
    const isWatcher = this.checkWatcher();
    const isShared = this.checkShared();
    if (isWatcher) {
      runInAction(() => {
        this.isAuthenticated = true;
        this.isWatcher = true;
      });
    } else if (isShared) {
      runInAction(() => {
        this.isAuthenticated = true;
        this.isShared = true;
      });
    } else if (!jwtToken && !userRefreshToken) {
      Cookies.remove('user-jwt-expire');
      Cookies.remove('pool-jwt-expire');
      // this.jumpToLogin();
      return this.isAuthenticated;
    } else if (userRefreshToken && !jwtToken) {
      // 存在user-refresh-token 但没有 user-jwt-token
      runInAction(() => {
        this.isAuthenticated = true;
      });
      await this.requestJWTToken();
    } else {
      runInAction(() => {
        this.isAuthenticated = true;
      });
    }

    return this.isAuthenticated;
  };

  /**
   * 通过url初步判断是否是观察者
   * 保存access_key到全局变量中
   *
   * @memberof UserStore
   */
  @action
  checkWatcher = () => {
    let params = queryString.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    if (params && params.access_key) {
      if (params.access_key.startsWith('r_')) {
        global.access_key = params.access_key;
        if (params.puid) {
          global.puid = params.puid;
        }
        return true;
      }
    }
    //不是观察者的话打开所有权限，当然是在已登录的情况下, 用户面板默认都打开
    this.hasUserAuthorityForEarnings = true;
    this.hasUserAuthorityForGetCoin = true;
    this.hasUserAuthorityForMiners = true;

    return false;
  };

  /**
   * 通过url初步判断是否是共享者
   * 保存puid到全局变量中
   *
   * @memberof UserStore
   */
  @action
  checkShared = () => {
    let params = queryString.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    if (params && params.puid) {
      if (params.shared === 'true') {
        global.puid = params.puid;
        return true;
      }
    }

    return false;
  };

  /**
   * 由于用户中心登录现采用iframe 登录，矿池这边只用关系post-message后cookie即可
   * 用户中心登录后set user-jwt-token cookie 15天
   * 矿池前端需要每3小时获取新的user-jwt-token
   * 然后设置axios header
   * @memberof UserStore
   */
  @action
  login = (callback) => {
    const inThreeHours = new Date(new Date().getTime() + 3 * 60 * 60 * 1000);
    Cookies.set('pool-jwt-expire', inThreeHours.getTime(), {
      expires: inThreeHours,
    });
    runInAction(() => {
      this.isAuthenticated = true;
    });
    this.getAccount(callback);
  };

  // 清除缓存
  @action
  cleanCache = () => {
    this.initStore();
    localStorage.removeItem('puid');
    localStorage.removeItem('accountInfo');
    localStorage.removeItem('userContactInfo');
    Cookies.remove(process.env.REACT_APP_USER_JWT_TOKEN, {
      path: '',
      domain: '.' + MAIN_DOMAIN_ALL_LOWERCASES,
    });
    Cookies.remove(process.env.REACT_APP_USER_REFRESH_TOKEN, {
      path: '',
      domain: '.' + MAIN_DOMAIN_ALL_LOWERCASES,
    });
    Cookies.remove('user-jwt-expire');
    Cookies.remove('pool-jwt-expire');
  };
  // 登出
  @action
  logout = () => {
    this.cleanCache();
    // axios.get(`auth/api/logout/do`, {
    //   baseURL: process.env.REACT_APP_USER_CENTER
    // });
    // TODO: 由于👆cors问题没解决，临时采用iframe方式
    (function () {
      let i = document.createElement('iframe');
      i.style.display = 'none';
      i.onload = function () {
        i.parentNode.removeChild(i);
      };
      i.src = process.env.REACT_APP_USER_CENTER + '/auth/logout';
      document.body.appendChild(i);
    })();

    this.jumpToLogin();
    ReactGA.event(
      {
        category: 'Account',
        action: 'SignOut',
        label: SELF_NO_PROTOCAL,
      },
      ['globalGA'],
    );
    global._hmt &&
      global._hmt.push(['_trackEvent', 'Account', 'SignOut', SELF_NO_PROTOCAL]);
  };

  // 跳转到登录页面
  jumpToLogin = () => {
    const loginPath = '/';
    if (history.location.pathname !== loginPath) {
      history.push(loginPath);
    }
    runInAction(() => {
      this.isAuthenticated = false;
    });
  };

  // 重新请求新的jwttoken
  requestJWTToken = async (isForce = false) => {
    let refreshToken = Cookies.get(process.env.REACT_APP_USER_REFRESH_TOKEN);
    const jwtToken = Cookies.get(process.env.REACT_APP_USER_JWT_TOKEN);
    let jwtExpireTime = Cookies.get('pool-jwt-expire');
    let jwtTokenExpire = Cookies.get('user-jwt-expire');

    if (refreshToken) {
      if (isForce || !jwtExpireTime || !jwtToken) {
        // TODO: 请求用户中心接口拿到新jwtToken
        try {
          runInAction(() => {
            this.isRefreshingNewToken = true;
          });
          const response = await axios.post(
            `/cas/jwt/refresh-user-token`,
            qs.stringify({
              'user-refresh-token': refreshToken,
            }),
            {
              baseURL: process.env.REACT_APP_USER_CENTER,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            },
          );
          if (response && response.data) {
            if (response.data.err_no > 0) {
              if (response.data.err_msg) {
                throw new Error(response.data.err_msg);
              }
            }
            let expires = 15;
            if (jwtTokenExpire) {
              expires = new Date(jwtTokenExpire);
            }
            const data = response.data.data;
            if (data && data.jwt_token) {
              // user-jwt-token
              Cookies.set(
                process.env.REACT_APP_USER_JWT_TOKEN,
                data.jwt_token,
                {
                  expires: expires,
                  domain: '.' + MAIN_DOMAIN_ALL_LOWERCASES,
                },
              );
              // user-refresh-token user center 用
              Cookies.set(
                process.env.REACT_APP_USER_REFRESH_TOKEN,
                data['refresh-token'],
                {
                  expires: expires,
                  domain: '.' + MAIN_DOMAIN_ALL_LOWERCASES,
                },
              );
              const jwtTokenExpire = Cookies.get('user-jwt-expire');
              if (!jwtTokenExpire) {
                const expires = new Date(
                  new Date().getTime() + 15 * 24 * 60 * 60 * 1000,
                );
                Cookies.set('user-jwt-expire', expires.getTime(), {
                  expires: expires,
                });
              }
            }
            this.login();
            if (history.location.state && history.location.state.from) {
              const location = history.location.state.from;
              if (location) {
                if (
                  location.pathname &&
                  location.search &&
                  location.pathname === '/guardian/bind'
                ) {
                  history.push({
                    pathname: location.pathname,
                    search: location.search,
                  });
                }
              }
            }
            this.isRefreshingNewToken = false;
          }
        } catch (error) {
          console.log('error', error);
          this.logout();
        }
      }
    } else {
      this.logout();
    }
  };

  @observable loadingAccountInfo = false;

  // 获取账户信息
  @action
  getAccount = async (callback) => {
    if (!this.isAuthenticated) {
      return;
    }

    try {
      this.loadingAccountInfo = true;
      this.getUserContactInfo();

      await this.getSubAccountInfo(callback);
      await this.getSubAccountPaymentInfo();

      await this.getSecondCoinSubAccountPaymentInfo();
      await this.getSecondCoinSubAccountInfo();
      // await this.getAccountInfo();
      this.loadingAccountInfo = false;
    } catch (error) {
      this.loadingAccountInfo = false;
    }
  };

  // // 账户信息， 首先必须创建子账户
  // // 只在设置的地方调用
  // @action
  // getAccountInfo = async (successCallback) => {
  //   if (
  //     !this.isAuthenticated ||
  //     !this.accountInfo.puid ||
  //     this.accountInfo.puid === 0
  //   ) {
  //     return;
  //   }
  //   const response = await axios.get(`account/info`);
  //   if (response && response.data) {
  //     runInAction(() => {
  //       this.accountInfo = {
  //         ...this.accountInfo,
  //         ...response.data,
  //       };
  //       if (!this.isUserReadOnly) {
  //         localStorage.setItem('accountInfo', JSON.stringify(this.accountInfo));
  //       }

  //       successCallback && successCallback();
  //     });
  //   }
  // };

  /**
   * 获取子账户支付信息
   * @memberof UserStore
   */
  @action
  getSubAccountPaymentInfo = async (callback, show_hidden = 0) => {
    if (!this.isAuthenticated) {
      return;
    }
    const response = await axios.get(`sub-account/payment/info`, {
      params: {
        show_hidden: this.accountInfo.puid ? 1 : 0,
        version: 'v1',
      },
    });
    if (response && response.data) {
      runInAction(() => {
        this.paymentInfo = {
          ...this.paymentInfo,
          ...response.data,
        };
        // localStorage.setItem('paymentInfo', JSON.stringify(this.paymentInfo));
        if (callback) {
          callback(response.data);
        }
      });
    }
  };
  @action
  getSecondCoinSubAccountPaymentInfo = async (callback) => {
    let account = this.accountInfo;
    let params = getSecondCoinParams(account?.coin_type);
    if (!params) {
      return;
    }
    if (!this.isAuthenticated) {
      return;
    }
    const response = await axios.get(`sub-account/payment/info`, {
      params: {
        show_hidden: this.accountInfo.puid ? 1 : 0,
        version: 'v1',
        ...params,
      },
    });
    if (response && response.data) {
      runInAction(() => {
        this.secondCoinPaymentInfo = {
          ...this.secondCoinPaymentInfo,
          ...response.data,
        };
        // localStorage.setItem('paymentInfo', JSON.stringify(this.paymentInfo));
        if (callback) {
          callback(response.data);
        }
      });
    }
  };
  /**
   * 获取子账户信息
   * 该方法可以不传puid, 所以登录后得通过这个获取puid
   * callback: 用处1. 注册、登陆后没有子账户的，跳转创建子账户页面
   * 这里 show_hidden 默认为0，表示不返回已隐藏的子账户
   * TODO: 最好合并到accout/info接口中
   * @memberof UserStore
   */
  @action
  getSubAccountInfo = async (callback, show_hidden = 0) => {
    if (!this.isAuthenticated) {
      return;
    }
    if (!this.accountInfo.puid) {
      this.fetchingSubAccount = true;
    }

    const response = await axios.get(`account/sub-account/info`, {
      params: {
        show_hidden: this.accountInfo.puid ? 1 : 0,
        version: 'v2',
      },
    });
    if (response && response.data) {
      runInAction(() => {
        this.accountInfo = {
          ...this.accountInfo,
          ...response.data,
        };
        if (this.isUserReadOnly) {
          global.puid = this.accountInfo.puid;
        } else {
          localStorage.setItem('accountInfo', JSON.stringify(this.accountInfo));
          if (this.accountInfo.puid) {
            localStorage.setItem('puid', this.accountInfo.puid);
          }
        }
        if (callback) {
          callback(response.data);
        }
      });
      this.fetchingSubAccount = false;
    }
  };
  @action
  getSecondCoinSubAccountInfo = async () => {
    let account = this.accountInfo;
    let params = getSecondCoinParams(account?.coin_type);
    if (!params) {
      return;
    }
    if (!this.isAuthenticated) {
      return;
    }
    if (!this.secondCoinAccountInfo.puid) {
      this.fetchingSecondCoinSubAccount = true;
    }

    const response = await axios.get(`account/sub-account/info`, {
      params: {
        show_hidden: this.secondCoinAccountInfo.puid ? 1 : 0,
        version: 'v2',
        ...params,
      },
    });
    if (response && response.data) {
      runInAction(() => {
        this.secondCoinAccountInfo = {
          ...this.secondCoinAccountInfo,
          ...response.data,
        };
        if (this.isUserReadOnly) {
          global.puid = this.secondCoinAccountInfo.puid;
        } else {
          localStorage.setItem(
            'secondCoinAccountInfo',
            JSON.stringify(this.secondCoinAccountInfo),
          );
          if (this.secondCoinAccountInfo.puid) {
            localStorage.setItem('puid', this.secondCoinAccountInfo.puid);
          }
        }
      });
      this.fetchingSecondCoinSubAccount = false;
    }
  };
  /**
   *
   * @param {*} successCallback
   * @returns
   */
  @action
  getUserContactInfo = async () => {
    if (!this.isAuthenticated) {
      return;
    }
    // let cacheUserInfo = localStorage.getItem('userContactInfo');
    // if (cacheUserInfo) {
    //   this.userContactInfo = JSON.parse(cacheUserInfo);
    //   return;
    // }
    const response = await axios.get(`user/info`);
    if (response && response.data) {
      runInAction(() => {
        this.userContactInfo = response.data;
        if (!this.isUserReadOnly) {
          localStorage.setItem(
            'userContactInfo',
            JSON.stringify(this.userContactInfo),
          );
        }
      });
    }
  };

  // 切换子账户
  // account 参数形式 account:{puid,name,region_text,coin_type} 其中puid为必传参数，其他可选参数
  @action
  changeAccount = (account, isJump = true) => {
    try {
      if (account.puid) {
        if (!this.isUserReadOnly) {
          localStorage.setItem('puid', parseInt(account.puid, 10));
        }
        const cacheAccount = this.subAccounts.filter(
          (item) => item.puid?.toString() === account?.puid?.toString(),
        )?.[0];

        const {
          name = cacheAccount?.name,
          coin_type = cacheAccount?.coin_type,
          region_text = cacheAccount?.region_text,
        } = account;

        runInAction(() => {
          if (coin_type && name) {
            this.accountInfo = {
              ...this.accountInfo,
              ...account,
              name,
              coin_type,
              region_text: region_text,
            };

            if (!this.isUserReadOnly) {
              localStorage.setItem(
                'accountInfo',
                JSON.stringify(this.accountInfo),
              );
            }
          } else {
            this.accountInfo.uid = 0;
          }
          this.getAccount();
          if (isJump) {
            history.push(`/dashboard?puid=${account.puid}`);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 切换到非当前传参（exludedPuid）puid 的其他子账号列表中的第一个子账号
   * @param {string[]| number[] } exludedPuids 要排除进入的子账号，比如删除操作时，排除掉要删除的子账户，进入别的子账号
   * @param {*} isJump
   * @param {*} jumpWelcomeIfNoneAccount 如果除排除的puid外剩余的子账号列表为空，是否跳转到欢迎页
   */
  @action
  changeAccountExcluded = (
    exludedPuids,
    isJump = true,
    jumpWelcomeIfNoneAccount = true,
  ) => {
    // 在执行删除等操作的时候子账户列表会有变化，因此需要先重新获取子账户列表
    this.getSubAccountList()
      .then(() => {
        const cacheOtherAccountsExluded = this.subAccounts.filter(
          (item) =>
            !exludedPuids
              .map((item) => item.toString())
              .includes(item.puid?.toString()),
        );

        if (cacheOtherAccountsExluded && cacheOtherAccountsExluded.length > 0) {
          const otherFirstAccount = cacheOtherAccountsExluded[0];

          otherFirstAccount?.puid &&
            this.changeAccount(
              {
                puid: otherFirstAccount.puid,
              },
              isJump,
            );
        } else {
          // 如果本地列表除了要排除的puid外为空，则清除本地缓存并重新请求
          this.cleanCacehCurrentAccount();
          this.getAccount();
          if (jumpWelcomeIfNoneAccount) history.push(`/welcome`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  @action
  cleanCacehCurrentAccount = () => {
    localStorage.removeItem('accountInfo');
    localStorage.removeItem('puid');
    this.accountInfo = this.__default_account;
  };

  // 切换账号后，重新加载数据
  // TODO: 目前暴力刷新，应该可以更好的实现
  @action
  reloadAccount = () => {
    this.initAccount();
    this.getAccount();
  };

  //隐藏账户
  hideAccount = async (hidden_puid) => {
    return axios.post(`account/sub-account/set/hidden/status`, {
      puidstr: hidden_puid,
      action: 'setHidden',
    });
  };

  @action
  recoverAccount = async (cancle_hidden_puid) => {
    return axios.post(`account/sub-account/set/hidden/status`, {
      puidstr: cancle_hidden_puid,
      action: 'cancelHidden',
    });
  };

  // 子账户列表
  @action
  getSubAccountList = async () => {
    this.algorithmAccounts = { ...this.algorithmAccounts, loading: true };

    const response = await axios.get(
      `account/sub-account/algorithms/morelist`,
      {
        params: {
          is_hidden: 0,
        },
      },
    );
    if (response && response.data) {
      runInAction(async () => {
        this.accountInfo.account.name = response.data.account;
        this.accountInfo.account.have_default = response.data.have_default;

        //分离etc子账户，针对ETC，修改区域内容为全球区（GL）
        let subAccounts = [];
        response.data.subaccounts.forEach((v) => {
          let subAccountsTemp = [];
          let algorithms = v.algorithms;
          let etcAlgorithms = [];
          let otherCoinAlgorithms = [];
          let isEtcFirstOne = false;
          algorithms.forEach((algorithm, i) => {
            if (algorithm.current_coin === 'etc') {
              isEtcFirstOne = i === 0;
              etcAlgorithms.push(algorithm);
            } else {
              otherCoinAlgorithms.push(algorithm);
            }
          });
          if (otherCoinAlgorithms.length > 0) {
            v = Object.assign({}, v);
            v.algorithms = otherCoinAlgorithms;
            subAccountsTemp.push(v);
          }
          if (etcAlgorithms.length > 0) {
            v = Object.assign({}, v);
            v.algorithms = etcAlgorithms;
            v.region_code = 'GL';
            v.region_text = 'Global';
            //etc是否最前面
            if (isEtcFirstOne) {
              subAccountsTemp.unshift(v);
            } else {
              subAccountsTemp.push(v);
            }
          }
          subAccounts = subAccounts.concat(subAccountsTemp);
        });
        this.algorithmAccounts = {
          ...this.algorithmAccounts,
          data: subAccounts,
          loading: false,
        };
        if (this.algorithmAccounts.firstTimeLoading) {
          this.algorithmAccounts.firstTimeLoading = false;
        }

        await this.getHashrateMiners(this.subAccounts, (res) => {
          if (res && res.data) {
            this.hashrateMiners = { ...this.hashrateMiners, ...res.data };
          }
        });
      });
    }
  };

  //  判断子账号是否被删除
  @action
  checkDelete = async (del_puid) => {
    const params = { del_puid: del_puid.toString() };
    if (!localStorage.getItem('puid')) {
      params.puid = del_puid.toString();
    }

    return axios.post(`account/sub-account/checkDelete`, params);
  };

  // 删除子账号
  @action
  deleteAccount = async (del_puid) => {
    const params = { del_puid: del_puid.toString() };
    if (!localStorage.getItem('puid')) {
      params.puid = del_puid.toString();
    }
    return axios.post(`account/sub-account/delete`, params);
  };

  // 通过获取的子账户列表，请求算力信息
  // 目前子账户管理、高级子账户管理、子账户下拉列表均需要
  // TODO: 合并接口后，应该删除
  getHashrateMiners = async (subAccounts, callback) => {
    // 按照币种分组，分组后结果为{ '1': [...], '2': [...] }
    const regionGroups = groupBy(subAccounts, (account) => {
      return account.region_id;
    });

    Object.keys(regionGroups).forEach(async (regionId) => {
      const puids = regionGroups[regionId].map((account) => {
        return account.puid;
      });
      let data = await this.getHashrateMinersByRegion(puids, regionId);
      if (callback) {
        callback(data);
      }
    });
  };

  // 获取子账户算力信息
  // TODO: 合并接口后，应该删除
  @action
  getHashrateMinersByRegion = (puids, regionId) => {
    return new Promise(async (resolve, reject) => {
      const response = await axios.get(`account/sub-account/hashrate-miners`, {
        params: {
          puids: puids.join(','),
          puid: '',
          region_id: regionId,
        },
      });
      resolve(response);
    });
  };

  // 获取高级子账户详细列表
  @action
  getDetailSubAccountList = async (is_guardian = 1) => {
    this.advanceAccount = { ...this.advanceAccount, loading: true };
    const response = await axios.get(`account/sub-account/morelist`, {
      params: {
        is_guardian,
      },
    });
    runInAction(async () => {
      if (response.data) {
        const data = response.data;

        const { display, hidden } = data;
        // 只显示当前正在挖的币种
        // filter hidden guard
        let allAccounts = [...display, ...hidden].filter((account) => {
          return !(account.is_guard && account.is_hidden);
        }); // 合并显示、隐藏账户列表
        this.advanceAccount = { loading: false, data: allAccounts };

        await this.getHashrateMiners(allAccounts, (res) => {
          if (res && res.data) {
            this.hashrateMiners = { ...this.hashrateMiners, ...res.data };
          }
        });
      }
      if (response.errorMsg) {
        this.advanceAccount = {
          ...this.advanceAccount,
          loading: false,
          errorMsg: response.errorMsg,
        };
      }
    });
  };

  // 修改联合挖矿地址
  @action
  updateMergedMiningAddress = async (coin, address) => {
    return axios.post(`/account/merged-mining/${coin}/address/update`, {
      new_address: address,
    });
  };

  @action
  watcherExpired = () => {
    const location = history.location;
    history.push({
      pathname: '/watcher/expired',
      search: location.search,
    });
  };

  @action
  watcherIncorrect = () => {
    const location = history.location;
    history.push({
      pathname: '/watcher/incorrect',
      search: location.search,
    });
  };
  @action
  watcherDelete = (errmsg) => {
    const location = history.location;
    history.push({
      pathname: '/watcher/delete',
      state: errmsg,
      search: location.search,
    });
  };

  @action
  hasPageAuthorities = (type) => {
    return this.isWatcher && this.userAuthority.page_authorities.includes(type);
  };

  @action
  getUserAuthority = async () => {
    // 如果不是观察者不用判断观察者权限
    if (!this.isWatcher) {
      return;
    }
    try {
      this.loadingGetUserAuthority = true;
      const response = await axios.get(`account/getUserAuthority`);
      if (response && response.data) {
        runInAction(() => {
          this.userAuthority = {
            ...this.userAuthority,
            ...response.data,
          };
          // 观察者是否有收益页面的权限
          this.hasUserAuthorityForEarnings = this.hasPageAuthorities(
            WATCHER_AUTHORITIES_VALUES.EARNINGS,
          );
          // 观察者是否有矿机页面的权限
          this.hasUserAuthorityForMiners = this.hasPageAuthorities(
            WATCHER_AUTHORITIES_VALUES.MINERS,
          );
          // 观察者是否有挖矿送币页面的权限
          this.hasUserAuthorityForGetCoin = this.hasPageAuthorities(
            WATCHER_AUTHORITIES_VALUES.GETCOIN,
          );
          this.completedGetUserAuthority = true;
          this.loadingGetUserAuthority = false;
        });
      }
    } catch (error) {
      this.loadingGetUserAuthority = false;
      console.log(error);
    }
  };
}

const userStore = new UserStore();
userStore.initAccount();
export default userStore;
