import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BtcModal, Collection, LoadingIcon } from 'components/widget';
import { upperCase } from 'common/utils';
import SvgIcon from 'components/SvgIcon';
import { useStores } from 'stores';
import CoinSelectorContent from './CoinSelectorContent';
import GroupLabel from './GroupLabel';
import styles from '../index.module.scss';

const SWITCH_OP = {
  select: {
    key: 'select',
    title: 'panel.One-button Switch',
  },
  confirm: {
    key: 'confirm',
    title: 'create.Attention',
  },
  switching: {
    key: 'switching',
    title: 'create.Attention',
  },
  switched: {
    key: 'switched',
    title: 'create.Switch Success',
  },
  failed: {
    key: 'failed',
    title: 'create.Switch Failed',
  },
};
// 首期迭代产品需求只考虑sha256算法
const OneSwitchModal = ({ visible, hideModal, switchData }) => {
  const { t } = useTranslation('account');
  const { settingsStore, userStore } = useStores();

  const switchCoinsMap = switchData?.algorithms
    ?.filter(
      (item) =>
        item.support_coins.length > 1 && item.algorithm_name === 'sha256',
    ) //首期迭代产品需求只考虑sha256算法
    ?.map((item) => {
      const { algorithm_name, current_coin, support_coins, current_puid } =
        item;
      return { algorithm_name, current_coin, support_coins, current_puid };
    });

  const sourceCoin = switchCoinsMap?.[0].current_coin;

  const [op, setOp] = useState(SWITCH_OP.select.key);
  const [, setSwitchErrorMsg] = useState('');
  const [destCoin, setDestCoin] = useState(sourceCoin);
  const [switchedDest, setSwitchedDest] = useState({
    dest_puid: null,
    dest_puid_name: '',
    region_name: '',
  });

  const handleSelectCoin = (coin) => {
    setDestCoin(coin);
  };

  const handleClickOK = () => {
    if (op === SWITCH_OP.select.key) {
      setOp(SWITCH_OP.confirm.key);
    } else if (op === SWITCH_OP.confirm.key) {
      settingsStore.switchHashrate(
        {
          source: sourceCoin,
          dest: destCoin,
          puid: switchCoinsMap?.[0].current_puid,
        },
        (data) => {
          setOp(SWITCH_OP.switched.key);
          setSwitchedDest(data);
        },
        (errMsg) => {
          setOp(SWITCH_OP.failed.key);
          setSwitchErrorMsg(errMsg);
        },
      );
      setOp(SWITCH_OP.switching.key);
    } else if (op === SWITCH_OP.switched.key) {
      // 进入到切换后的子账号
      userStore.changeAccount({
        puid: switchedDest.dest_puid,
        coin_type: upperCase(destCoin),
        name: switchedDest.dest_puid_name,
      });
      userStore.getSubAccountList();
      hideModal();
    } else {
      hideModal();
    }
  };

  const handleClickCancel = () => {
    hideModal?.();
  };

  return (
    <BtcModal
      visible={visible}
      title={t(SWITCH_OP[op]?.title)}
      width={540}
      onOk={handleClickOK}
      onCancel={handleClickCancel}
      okButtonProps={{
        disabled: destCoin === sourceCoin,
      }}
      closable={false}
      hideCancelButton={op === SWITCH_OP.switching.key}
      hideOkButton={op === SWITCH_OP.switching.key}
      okText={op === SWITCH_OP.switched.key ? t('create.Check now') : null}
    >
      <div className={styles.oneSwitch}>
        {op === SWITCH_OP.select.key && (
          <>
            <GroupLabel
              labels={[
                { title: t('create.Sub-account'), value: switchData.name },
                { title: t('create.Region'), value: switchData.region_text },
              ]}
            />
            <div className="margin-top-xlg" style={{ marginBottom: 60 }}>
              {switchCoinsMap?.map((item) => {
                return (
                  <Collection
                    key={item.algorithm_name}
                    title={upperCase(item.algorithm_name)}
                    onSelect={handleSelectCoin}
                    activeClassName={styles.selectorActive}
                    defaultSelect={item.current_coin}
                    value={item.current_coin}
                  >
                    {item.support_coins.map((coin) => {
                      return (
                        <Collection.Item key={coin} value={coin}>
                          <CoinSelectorContent coin={coin} />
                        </Collection.Item>
                      );
                    })}
                  </Collection>
                );
              })}
            </div>
          </>
        )}
        {op === SWITCH_OP.confirm.key && (
          <>
            <p>
              {t('create.aboutToSwitch', { destMode: upperCase(destCoin) })}
            </p>
          </>
        )}
        {op === SWITCH_OP.switching.key && (
          <div className="flex items-center">
            <LoadingIcon marginRight={16} size="sm" />
            <span>
              {t('create.switching', {
                destMode: upperCase(destCoin),
              })}
            </span>
          </div>
        )}
        {op === SWITCH_OP.switched.key && (
          <div className="flex items-center">
            <SvgIcon
              iconName="icon_feedback_success"
              className="margin-right-md"
              size="md"
            />
            <span>
              {t('create.hasSwitched', {
                subAccountName: switchData.name,
                subAccountRegionNode: switchData.region_text,
                destMode: upperCase(destCoin),
              })}
            </span>
          </div>
        )}
        {op === SWITCH_OP.failed.key && (
          <div className="flex items-center">
            <SvgIcon
              iconName="icon_feedback_failed"
              className="margin-right-md"
              size="md"
            />
            <span>{t('create.switchErrorMsg')}</span>
            {/* <span>{switchErrorMsg}</span> */}
          </div>
        )}
      </div>
    </BtcModal>
  );
};

export default React.memo(OneSwitchModal);
