import appLocaleData from 'react-intl/locale-data/es';
import common from './es-ES.common';
import pages from './es-ES.pages';
import pagination from './es-ES.pagination';
import invite from './es-ES.invite';

window.appLocale = {
  messages: Object.assign({}, common, pages, pagination, invite),

  locale: 'es',
  data: appLocaleData,
};

export default window.appLocale;
