import React from 'react';
import { Skeleton } from 'antd';
import './index.scss';
const BtcCardFormItem = (props) => {
  const {
    label,
    value,
    suffix,
    isLargeText = false,
    align = 'right',
    isHtml = false,
    className = '',
    tooltip,
    style,
    variant = 'horizontal', // 默认为水平排列， variant="vertical" 时为垂直排列，
    borderLeftColor,
  } = props;
  return (
    <dl
      className={`card-form-item ${variant} ${
        isLargeText ? 'larget-text' : ''
      } ${className}`}
      style={{
        ...style,
        borderLeft: borderLeftColor ? `1px solid ${borderLeftColor}` : 'unset',
      }}
    >
      <dt className="secondary-text">{label}</dt>
      <dd style={{ textAlign: align }}>
        {!isHtml ? value : <div dangerouslySetInnerHTML={{ __html: value }} />}

        {suffix && <span className="suffix">{suffix}</span>}
        {tooltip}
      </dd>
    </dl>
  );
};
export { BtcCardFormItem };

const BtcCard = (props) => {
  const {
    style,
    ghost = false,
    className = '',
    title,
    size = 'md', //others:sm
    showHeader = true,
    extra,
    children,
    cardBodyStyle,
    noMarginBottom,
    loading,
    showDecorator = false,
  } = props;
  return (
    <div
      className={`btc-card btc-card-${size} ${ghost ? 'btc-card-ghost' : ''} ${
        showDecorator ? 'show-decorator' : ''
      } ${className} ${noMarginBottom ? 'noMarginBottom' : ''}`}
      style={{ width: '100%', height: 'auto', ...style }}
    >
      {showHeader && (
        <div className="btc-card-header">
          <div className="btc-card-title">{title}</div>
          {extra && <div className="btc-card-extra">{extra}</div>}
        </div>
      )}
      <div className="btc-card-body" style={cardBodyStyle}>
        <Skeleton loading={loading ? loading : false} active>
          {children}
        </Skeleton>
      </div>
    </div>
  );
};
export { BtcCard };

export default BtcCard;
