import React from 'react';
import { coinsConfig } from 'common/config';
import { upperCase, lowerCase } from 'common/utils';
import styles from '../index.module.scss';

export default function CoinSelectorContent({ coin, className }) {
  const coinsInfo = coinsConfig[lowerCase(coin)];
  const showCoinName = coinsInfo.showName || coin;
  return (
    <div className={`${className} ${styles.coinSelectorItem}`}>
      <img alt={coin} src={coinsInfo.icon} />
      <span>{upperCase(showCoinName)}</span>
    </div>
  );
}
