import flatten from 'flat';
const inviteMessage = {
  invite: {
    inviteTitle: '邀請獎勵',
    bindPhoneTip:
      '綁定手機號即可參與有獎邀請，雙向返佣，永久有效。<br>請到<span style="font-weight:500;color:#333"> 個人中心  -> 賬號設置 </span>中綁定手機號。',
    needStep: '只差一步',
    createInviteAccountLoadingMessage: '正在創建您的專屬邀請碼和專屬子賬戶…',
    heading:
      '邀請好友一起挖礦，拿<span style="color:#0A66C2"> 0.2% </span>的返佣獎勵',
    cutoff: '受邀人傭金<span style="color:#0A66C2"> 95折 </span>',
    ruleDetails: '規則詳情',
    totalRebate: '返佣總額',
    totalRebateTip:
      '返佣以原幣幣種結算，此處為法幣估值，估值方式為以每筆應付原幣乘以結算日0點(UTC+0)的匯率，然加總求和。',
    details: '詳情',
    totalAccept: '總受邀人',
    minedAccept: '已挖礦受邀人',
    inviteCode: '邀請碼',
    copy: '複製',
    inviteLink: '邀請鏈接',
    acceptInviteCode: '被邀碼',
    acceptInviteCodeTip:
      '您是受邀礦工，礦池費率享受95折，您可以到 礦池管理面板 > 收益 > 收益詳情中查看。',
    generatePoster: '生成海報',
    inviteRecord: '邀請記錄',
    export: '導出',
    rebateRecord: '返佣記錄',
    invitee: '受邀人',
    inviteeStatus: '受邀人狀態',
    'inviteeStatus-unlocked': '已解除',
    'inviteeStatus-registered': '已註冊',
    'inviteeStatus-mined': '已挖礦',
    registerTime: '註冊時間',
    miningTime: '開始挖礦時間',
    miningTimeTip:
      '受邀請人在接收您的邀請之後第一天開始挖礦的時間，採用UTC時間。',
    coinType: '幣種',
    region: '挖礦分區',
    inviteSubAccount: '返佣專屬子賬戶',
    rebatePercent: '返佣比例',
    op: '操作',
    originCoinCount: '原幣數量',
    specialSubAccountTitle:
      '我們為您生成了用於接收返佣的專屬子賬戶，您的受邀人開始挖礦後，挖礦收益的返佣將按不同幣種和不同分區結算至您相應的子賬戶中。',
    specialSubAccountTip:
      '默認生成BTC幣種美國分區和歐洲分區的專屬子賬戶，當您的受邀人創建了其他幣種的挖礦子賬戶時，我們會為您生成相對於的專屬子賬戶。',
    rebateOpAddress: '地址管理',
    rebateOpDetail: '返佣明細',
    downloadPoster: '下載海報',
    noData: '暫無數據',
    gotoBind: '去綁定',
    boundPhone: '已綁定完畢',
    headtips: '活動',
  },
};
const inviteMessageFlatten = flatten(inviteMessage);
export default inviteMessageFlatten;
