import appLocaleData from 'react-intl/locale-data/ja';
import common from './ja.common';
import pages from './ja.pages';
import pagination from './ja.pagination';
import invite from './ja.invite';

window.appLocale = {
  messages: Object.assign({}, common, pages, pagination, invite),

  locale: 'ja',
  data: appLocaleData,
};
export default window.appLocale;
