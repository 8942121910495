import {
  observable,
  action,
  runInAction,
  computed,
  makeObservable,
} from 'mobx';
import axios from 'axios';
import Ts from 'components/LocaleTranslator';
import { currencyConfig, electricConfig } from 'common/utils/constants';

class MinerRankingStore {
  //#region Stats Info
  @observable
  miners = [];
  @observable
  coins = [];
  @observable
  updateTime = '';
  @observable
  currencyList = currencyConfig.currencyList;
  @observable
  currency = currencyConfig.currency;
  @observable
  currentCoin = 'ALL';
  @observable
  electricList = electricConfig.electricList;
  @observable
  electric = electricConfig.electric;
  @observable
  priceShow = 'dailyEarning'; // 日收益/关机币价
  @observable
  sortShow = 'desc'; // 排序 由高到低、由低到高
  @observable
  keywords = ''; // 搜索内容
  @observable
  isShowMore = false;
  @observable
  isIncomeWithDoge = true;
  @observable
  loading = true;

  constructor() {
    makeObservable(this);
  }

  @computed get coinList() {
    return [
      {
        name: new Ts().getLocaleString('pages.minerRanking.ALL'),
        symbol: 'ALL',
      },
    ].concat(...this.coins);
  }

  @computed get currencySymbol() {
    return this.currency === 'CNY' ? '￥' : '$';
  }

  @action
  getData = async () => {
    this.loading = true;
    try {
      const response = await axios.get('/pool/mining-rank');
      if (response && response.data) {
        runInAction(() => {
          this.miners = response.data?.miners;
          this.coins = response.data?.coins;
          this.updateTime = response.data?.updateTime;
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  };

  @action
  changeCurrentCoin = (coin) => {
    this.currentCoin = coin;
    // this.isShowMore = false;
  };

  @action
  changeIncomeWithDoge = (isIncomeWithDoge) => {
    this.isIncomeWithDoge = isIncomeWithDoge;
  };

  @action
  changeCurrency = (currency) => {
    localStorage.setItem('currency', currency);
    this.currency = currency;
    this.electricList =
      currency === 'CNY'
        ? electricConfig.electricListCN
        : electricConfig.electricListEN;
    this.electric =
      currency === 'CNY'
        ? electricConfig.electricCN
        : electricConfig.electricEN;
  };
  @action
  changeElectric = (ele) => {
    this.electric = ele;
  };
  @action
  changePriceShow = (data) => {
    this.priceShow = data;
  };
  @action
  changeSortShow = (data) => {
    this.sortShow = data;
  };
  @action
  changeKeywords = (data) => {
    this.keywords = data;
  };

  @action
  changeShowMore = (data) => {
    this.isShowMore = data;
  };
}

const minerRankingStore = new MinerRankingStore();

export { minerRankingStore };
