import React from 'react';
import styles from '../index.module.scss';

export default function GroupLabel({ labels }) {
  return (
    <ul className={styles.groupLabel}>
      {labels.map((item) => {
        return (
          <li className={styles.labelItem} key={item.title}>
            <span className={styles.lableTitle}>{item.title}</span>
            <span className={styles.lableValue}>{item.value}</span>
          </li>
        );
      })}
    </ul>
  );
}
