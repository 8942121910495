import flatten from 'flat';
const paginationMessage = {
  pagination: {
    first: 'トップページ',
    last: 'ページ終わり',
    pageSize: '{pageSize}アイテム/ページ',
    currentPage: '第{current}ページ，合計{total}ページ',
    totalInfo: '共{total}アイテム',
    goto: 'にジャンプ',
  },
};
const paginationMessageFlatten = flatten(paginationMessage);
export default paginationMessageFlatten;
