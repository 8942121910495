import styles from './GroupEntry.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { useStores } from 'stores';
import { useLocation } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const NewDomainAlert = ({ t, lang }) => {
  const [show, setShow] = useState(false);
  const explorerUrl = 'https://cloverpool.com';

  let langChange = {
    'zh-CN': 'zh-cn',
    'zh-HK': 'zh-cn',
  };
  let hcLang = langChange[lang] ? langChange[lang] : 'en-us';
  const activityUrl = `https://help.cloverpool.com/hc/${hcLang}/articles/37699204257945`;

  let miningLangChange = {
    'zh-CN': 'zh-cn',
    'zh-HK': 'zh-cn',
    ru: 'ru',
  };
  let hcMiningLang = miningLangChange[lang] ? miningLangChange[lang] : 'en-us';

  const activityUrlMinnerAddress = `https://help.cloverpool.com/hc/${hcMiningLang}/articles/360020105472`;
  const getStorageItem = (key) => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(key);
    }
  };
  const setStorageItem = (key, value) => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, value);
    }
  };
  useEffect(() => {
    let date = moment().format('YYYY-MM-DD');
    if (getStorageItem('newDomainAlert') === date) {
      return;
    }
    setStorageItem('newDomainAlert', date);
    setShow(true);
  }, []);
  if (!show) {
    return <></>;
  }
  return (
    <Modal
      wrapClassName={styles['newDomainAlert']}
      open={show}
      onCancel={() => setShow(false)}
      maskClosable={false}
      width="400px"
      centered
      title={
        <div className="flex justify-center items-center">
          <div className={`text-center ${styles['newDomainAlert-title']}`}>
            {t('common:topNav.newDomainAlert.title', { lng: lang })}
          </div>
        </div>
      }
      footer={null}
    >
      <div title="">
        <div className={styles['newDomainAlert-desc']}>
          <div>{t('common:topNav.newDomainAlert.content1', { lng: lang })}</div>
          <div>
            <Trans
              i18nKey="common:topNav.newDomainAlert.content2" // optional -> fallbacks to defaults if not provided
              components={[<span key={1} style={{ fontWeight: 'bold' }} />]}
              t={t}
              tOptions={{ lng: lang }}
            />
          </div>
          <div>
            <Trans
              i18nKey="common:topNav.newDomainAlert.content3" // optional -> fallbacks to defaults if not provided
              values={{ link: activityUrl }}
              components={[
                <a
                  target="_blank"
                  href={activityUrl}
                  key={0}
                  rel="noreferrer"
                />,
              ]}
              t={t}
              tOptions={{ lng: lang }}
            />
          </div>
          <div>
            <Trans
              i18nKey="common:topNav.newDomainAlert.content5" // optional -> fallbacks to defaults if not provided
              values={{ link: explorerUrl }}
              components={[
                <span key={2} style={{ color: 'red' }} />,
                <a
                  target="_blank"
                  href={activityUrlMinnerAddress}
                  key={0}
                  rel="noreferrer"
                />,
              ]}
              t={t}
              tOptions={{ lng: lang }}
            />
          </div>
          <div>
            <Trans
              i18nKey="common:topNav.newDomainAlert.content4" // optional -> fallbacks to defaults if not provided
              values={{ link: explorerUrl }}
              components={[
                <span key={2} style={{ fontWeight: 'bold' }} />,
                <a
                  target="_blank"
                  href={explorerUrl}
                  key={0}
                  rel="noreferrer"
                />,
                <span key={1} style={{ fontWeight: 'bold' }} />,
              ]}
              t={t}
              tOptions={{ lng: lang }}
            />
          </div>
        </div>
      </div>
      <div className={styles['newDomainAlert-btn-wrap']}>
        <Button
          type="primary"
          size="md"
          shape="round"
          className={styles['newDomainAlert-btn']}
          onClick={() => setShow(false)}
        >
          {t('common:topNav.newDomainAlert.btn', { lng: lang })}
        </Button>
      </div>
    </Modal>
  );
};

const MiningAddressAlert = ({ t, lang }) => {
  const [show, setShow] = useState(false);

  let langChange = {
    'zh-CN': 'zh-cn',
    'zh-HK': 'zh-cn',
    ru: 'ru',
  };
  let hcLang = langChange[lang] ? langChange[lang] : 'en-us';
  const activityUrl = `https://help.cloverpool.com/hc/${hcLang}/articles/360020105472`;
  const getStorageItem = (key) => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(key);
    }
  };
  const setStorageItem = (key, value) => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, value);
    }
  };
  const location = useLocation();

  const dashbardPage = [
    '/dashboard',
    '/miners',
    '/earning-history',
    '/merge/getCoin',
    '/settings/address',
    '/settings/watcher',
    '/settings/alert',
    '/settings/apikey',
  ];
  useEffect(() => {
    let date = moment().format('YYYY-MM-DD');
    let key = `MiningAddressAlert-${location.pathname}`;
    if (dashbardPage.includes(location.pathname)) {
      key = `MiningAddressAlert-dashboard`;
    }
    if (getStorageItem(key) === date) {
      return;
    }
    setStorageItem(key, date);
    setShow(true);
  }, [location]);
  if (!show) {
    return <></>;
  }
  return (
    <Modal
      wrapClassName={styles['newDomainAlert']}
      open={show}
      onCancel={() => setShow(false)}
      maskClosable={false}
      width="550px"
      centered
      getContainer={() => document.getElementById('root')}
      title={
        <div className="flex justify-center items-center">
          <div className={`text-center ${styles['newDomainAlert-title']}`}>
            <ExclamationCircleOutlined className={`${styles['icon']}`} />
            {t('common:topNav.miningAddressAlert.title', { lng: lang })}
          </div>
        </div>
      }
      footer={null}
    >
      <div title="">
        <div className={styles['newDomainAlert-desc']}>
          <div>
            {t('common:topNav.miningAddressAlert.content1', { lng: lang })}
          </div>
          <div>
            <Trans
              i18nKey="common:topNav.miningAddressAlert.content2" // optional -> fallbacks to defaults if not provided
              components={[<span key={1} style={{ color: 'red' }} />]}
              t={t}
              tOptions={{ lng: lang }}
            />
          </div>
          <div>
            <Trans
              i18nKey="common:topNav.miningAddressAlert.content3" // optional -> fallbacks to defaults if not provided
              values={{ link: activityUrl }}
              components={[
                <span key={2} style={{ fontWeight: 'bold' }} />,
                <a
                  target="_blank"
                  href={activityUrl}
                  key={0}
                  rel="noreferrer"
                />,
              ]}
              t={t}
              tOptions={{ lng: lang }}
            />
          </div>
          <div>
            <div>
              {t('common:topNav.miningAddressAlert.content4', { lng: lang })}
            </div>
            <div>
              {t('common:topNav.miningAddressAlert.content5', { lng: lang })}
            </div>
            <div>
              {t('common:topNav.miningAddressAlert.content6', { lng: lang })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['newDomainAlert-btn-wrap']}>
        <Button
          type="primary"
          size="md"
          shape="round"
          className={styles['newDomainAlert-btn']}
          onClick={() => setShow(false)}
        >
          {t('common:topNav.miningAddressAlert.btn', { lng: lang })}
        </Button>
      </div>
    </Modal>
  );
};
const GroupEntry = () => {
  const { t } = useTranslation('common');
  const { appStore: store } = useStores();
  const { lang } = store;
  const [showPage, setShowPage] = useState('');
  const location = useLocation();
  const dashbardPage = [
    '/dashboard',
    '/miners',
    '/earning-history',
    '/merge/getCoin',
    '/settings/address',
    '/settings/watcher',
    '/settings/alert',
    '/settings/apikey',
    '/advance-account',
  ];
  useEffect(() => {
    let page = 'newDomainAlert';
    console.log(location.pathname);
    if (dashbardPage.includes(location.pathname)) {
      page = 'miningAddressAlert';
    }
    setShowPage(page);
  }, [location]);
  return (
    <>
      <div className={`${styles['group-entry']} ${styles['xs-hide']}`}>
        <div className={styles['wordContaner']}>
          {t('topNav.viewAndCollectDesc', { lng: lang })}
        </div>
        {/* {showPage === 'newDomainAlert' && <NewDomainAlert t={t} lang={lang} />}
        {showPage === 'miningAddressAlert' && (
          <MiningAddressAlert t={t} lang={lang} />
        )} */}
      </div>
    </>
  );
};
export default GroupEntry;
