import account from './account.json';
import common from './common.json';
import invite from './invite.json';
import welcome from './welcome.json';
import dashboard from './dashboard.json';
import earnings from './earnings.json';
import giftcoin from './giftcoin.json';
import miners from './miners.json';
import minersInfo from './minersInfo.json';
import settings from './settings.json';
import guardian from './guardian.json';
import guardianBind from './guardianBind.json';
const locale = {
  account,
  common,
  invite,
  welcome,
  dashboard,
  earnings,
  giftcoin,
  miners,
  minersInfo,
  settings,
  guardian,
  guardianBind,
};

export default locale;
