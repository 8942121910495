import flatten from 'flat';
const paginationMessage = {
  pagination: {
    first: 'Premier',
    last: 'Dernier',
    pageSize: '{pageSize}/page',
    currentPage: 'Page {current}/{total}',
    totalInfo: 'Total: {total} objets',
    goto: 'Aller',
  },
};
const paginationMessageFlatten = flatten(paginationMessage);
export default paginationMessageFlatten;
