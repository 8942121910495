import flatten from 'flat';
const paginationMessage = {
  pagination: {
    first: 'Erste',
    last: 'Letzte',
    pageSize: '{pageSize}/Seite',
    currentPage: 'Seite {current} von {total}',
    totalInfo: '{total} Gegenstände gesamt',
    goto: 'Gehe zu',
  },
};
const paginationMessageFlatten = flatten(paginationMessage);
export default paginationMessageFlatten;
