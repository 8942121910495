import flatten from 'flat';
const commonMessage = {
  common: {
    locale: 'chino simplificado',
    header: {
      home: 'Casa de la piscina',
      dashboard: 'interfaz',
      getCoin: 'moneda regalada',
      help: 'asistencia',
      miners: 'mineros',
      poolStats: 'estadística',
      commissionEarnings: 'Ganancias por comisiones',
      miningEarnings: 'Ganancias mineras',
      service: 'servicio al cliente',
      tools: 'herramientas',
      setting: 'configuración',
      guardian: 'acceso',
      guardianPermission:
        'Usted sólo tiene acceso de visualización a datos de la cuenta. No puede realizar ninguna modificación.',
      watcher: 'observador',
      watcherPermission:
        'Un observador tiene acceso de visualización a datos de la subcuenta, pero no puede realizar ninguna modificación.',
      userCenter: 'centro de usuario',
      SignOut: 'cerrar sesión',
      accountSetting: 'Ajustes',
      poolManagePanel: 'Panel',
      watcherMode: 'Observador Modo',
      scanToService: 'escanear para recibir asistencia',
      bitdeer: 'sesión de Bitdeer',
      hashnest: 'hashnest',
      app: 'App',
      explorer: 'navegador',
      getBCHA: 'obtener BCHA',
      candy: 'dulce',
      alertBar:
        '<b>Entre las 22:00 y 24:00 horas (UTC+8) del 10 de agosto</b>, el pool de CloverPool realizará un mantenimiento de datos, durante el que algunas funciones no estarán disponibles mientras que servicios de minería no quedarán afectados.<a href="https://help.cloverpool.com/hc/zh-cn/articles/900002124306" rel="nofollow noopener noreferrer" target="_blank">para más información, véase</a>',
      introTip:
        'Las funciones como el panel de control, los mineros, las ganancias y la configuración de la subcuenta se unifican en el "Panel de la piscina".',
    },
    nav: {
      'nav-explorer': 'Explorador',
      'nav-explorer-btc': 'Bitcoin',
      'nav-explorer-eth': 'Ethereum',
      'nav-explorer-bch': 'Bitcoin Cash',
      'nav-explorer-etc': 'Ethereum Classic',
      'nav-explorer-ltc': 'Litecoin',
      'nav-pool': 'Piscina',
      'nav-pool-home': 'Casa de la piscina',
      'nav-pool-data': 'Datos de la agrupación',
      'nav-pool-candy': 'Dulce',
      'nav-pool-tools': 'Herramientas',
      'nav-pool-cs': 'Servicio al Cliente',
      'nav-wallet': 'Cartera',
      'nav-help': 'Ayuda',
      'nav-navigation': 'Navegación',
      'nav-inscriptions': 'Inscribir',
      'nav-app': 'APP',
      'nav-collaborate': 'Cooperación',
      'nav-brc20': 'BRC-20',
      'nav-tools': 'Herramienta',
      'nav-tools-address-monitoring': 'Address Monitoring',
      'nav-tools-address-detail': 'Address Txn Details',
      'nav-tools-address-report': 'Address Report',
      'nav-tools-address-increaseminerfee': 'Increase Txn Fees',
      'nav-tools-address-txreverse': 'Transaction Reversal',
      'nav-tools-address-doublespend': 'Txn Double-spending',
      'nav-tools-APIService': 'API Service',
      'nav-tools-accelerate': 'Txn Accelerator(CPFP)',
      'nav-tools-acceleratebatch': 'Batch Txns Accelerator',
      'nav-tools-TransactionBroadcast': 'Transaction Broadcast',
      'nav-tools-special': 'Rare Sats Query',
      'nav-tools-receipt': 'Transaction Receipt',
      'nav-tools-pickupsats': 'Transfer Rare Sats',
      'nav-tools-bulkpayment': 'Batch Payment',
      'nav-tools-address-graph': 'Address Graph',
      'nav-runes': 'Runes Mint',
      'nav-runes-explorer': 'Runes Explorer',
      'nav-runes-main': 'Runes',
      tags: {
        'runes-free': '0 Fees and Giveaway GOODS',
        new: 'New',
      },
    },
    menu: {},
    setting: {
      balance: 'saldo actual',
      coinAddress: '{coinType} dirección de recibo',
      setAddress: 'haga clic para configurar dirección de recibo {coinType}',
      multAddressEnabled: 'El pago en varias direcciones está habilitado',
      menu: {
        hide: 'ocultar',
        address: 'cambiar dirección de recibo',
        minPay: 'monto de pago mínimo',
        payWithBtc: 'pagar con BTC',
        earingsHistory: 'ganancia',
        shareData: 'cuenta de observador',
        watcher: 'Establecer vigilante',
        apikey: 'API KEY',
        alertSettings: 'Establecer alertas',
        onebuttonSwitch: 'cambiar con bóton único',
        userCenter: 'centro de usuario',
        signOut: 'cerrar sesión',
        delete: 'borrar',
      },
      confirm: 'OK',
      cacel: 'Cancelar',
      deleteConfirm: 'Eliminar confirmación',
      cannotDelete: 'No se puede eliminar la subcuenta',
      cannotDeleteView:
        'No se puede eliminar la subcuenta que se está viendo actualmente',
      deleteCondition:
        'No se puede eliminar la subcuenta<span class="subAccountName">{{accountMessage}}</span>,Solo se pueden eliminar las subcuentas que cumplan las dos condiciones siguientes:',
      cannotDeleteReason:
        '1、El hashrate de la subcuenta en los últimos 180 días es 0;<br/>2、La criptomoneda no pagada de la subcuenta (incluidas las monedas de regalo) es 0;<br/>3、La subcuenta no pertenece a ningún tipo de subcuenta especial.',

      deleteName: 'Está eliminando la subcuenta :',
      deleteInfo:
        '* Después de hacer clic en "Aceptar", la subcuenta se eliminará de forma permanente y no se podrá recuperar；<br/>* Después de la eliminación, la curva de hashrate, el registro de ingresos y otros datos de la subcuenta no se pueden recuperar.',
      deleteSuccess: 'Subcuenta eliminada correctamente',
    },
    footer: {
      services: 'prestar servicio',
      Products: 'productos de CloverPool',
      Help: 'centro de asistencia',
      btcTool: 'herramienta',
      btcSmartAgent: 'agente inteligente',
      btcComFirmware: 'firmware personalizado',
      blockchainExplorer: 'navegador',
      App: 'App',
      apiDocs: 'documentos API',
      vipApplication: 'solicitud de VIP',
      cloudMinePoolService:
        'servicio de construcción de pool de minería en la nube',
      miningTutorial: 'tutorial de minería',
      FAQ: 'preguntas frecuentes',
      Announcements: 'anuncios históricos',
      ticketSystem: 'sistema de orden de trabajo',
    },
    pageTitle: {
      home: 'CloverPool(formerly BTC.com): el grupo de minería multidivisa líder en el mundo',
      dashboard: 'interfaz - CloverPool(formerly BTC.com)',
      miners:
        'administración de máquinas de minera - CloverPool(formerly BTC.com)',
      earningHistory: 'historial de ganancia - CloverPool(formerly BTC.com)',
      earningHistoryForInvite:
        'Ganancias por comisiones - CloverPool(formerly BTC.com)',
      poolStats: 'datos de la agrupación - CloverPool(formerly BTC.com)',
      guardian:
        'enlace de invitación de guardián - CloverPool(formerly BTC.com)',
      guardianBind:
        'vincular enlace de invitación de guardián - CloverPool(formerly BTC.com)',
      subAccount: 'crear subcuenta - CloverPool(formerly BTC.com)',
      subAccountManage: 'administrar subcuenta - CloverPool(formerly BTC.com)',
      settings: 'configuraciones - CloverPool(formerly BTC.com)',
      getCoin: 'moneda regalada - CloverPool(formerly BTC.com)',
      tools: 'herramientas - CloverPool(formerly BTC.com)',
      bitdeer: 'sesión de Bitdeer - CloverPool(formerly BTC.com)',
      watcherExpired:
        'acceso de observador ha caducado - CloverPool(formerly BTC.com)',
      watcherIncorrect:
        'enlace de observador incorrecto - CloverPool(formerly BTC.com)',
      fundAuthorization:
        'acuerdo de autorización de cambio de fondos - CloverPool(formerly BTC.com)',
      app: 'descargar App - CloverPool(formerly BTC.com)',
      invite: 'Invitation Reward - CloverPool(formerly BTC.com)',
    },
    breadCrumbs: {
      home: 'portal',
      dashboard: 'interfaz',
      miners: 'minero',
      earningHistory: 'historial de ganancia',
      poolStats: 'datos de la agrupación',
      shareAccount: 'configurar cuenta compartida',
      guardianBindingInvitation: 'vincular enlace de invitación de guardián',
      guardianBindingLink: 'vincular enlace de guardián',
      subAccountManage: 'administración de subcuenta ',
      hiddenSubAccounts: 'ocultar administración de subcuenta',
      settings: 'configuración',
      getCoin: 'moneda regalada',
      tools: 'herramientas',
      fundAuthorization: 'acuerdo de autorización de cambio de fondos',
    },
    currency: {
      currency: 'CNY',
    },
    allSwitchMode: {
      smart_sha256: 'piscina inteligente',
      otcNotice: `Está a punto de cambiar su hashrate a Smart Pool, Al hacer clic en "Aceptar" se considerará que lee y acepta el {fundLink}`,
      fundLink: `Acuerdo de Servicio de Pool de Minería piscina inteligente CloverPool`,
      autoTip: `Adoptar el modo piscina inteligente significa encomendar su hashrate a CloverPool, y el pool de minería cambiará entre las monedas BTC, BCH y BSV de acuerdo con la rentabilidad óptima.`,
      originalTip:
        'Al cambiar al modo de moneda original, la hashrate SHA256 excavará la moneda seleccionada exclusivamente sin cambios automáticos.',
    },
    users: {
      userCenter: 'centro de usuario',
      SignOut: 'cerrar sesión',
    },
    operation: {
      name: 'operación',
      success: 'operación exitosa!',
      failed: 'operación fracasada!',
    },
    earning: {
      balanceTip:
        'Una vez que se complete el acuerdo, generalmente completaremos el pago dentro de las 24 horas, excepto en las siguientes situaciones:<br>1. No se establece ninguna dirección;<br>2.La dirección está establecida pero el monto de liquidación acumulativo no ha alcanzado el umbral de pago mínimo;<br>3.Operaciones como agregar una dirección, modificar una dirección, eliminar una dirección o modificar una proporción han activado la regla de suspensión de pago de 48 horas;<br>4. Se activa la regla de control de riesgos;<br>5.La moneda se somete a una bifurcación dura, o se encuentra con un ataque del 51% u otras mejoras o accidentes importantes.',
    },
    meta: {
      keywords:
        'Bitcoin,BTC,BCH,Bitcoin Cash,LTC, Litecoin,ETC,KAS,Kaspa,via,viabtc,f2pool,antpool,foundry,binance,luxor,poolin,pool,miners,worker,machine,tools,mint,brc20,runes,cloverpool,bitdeer,bitmain',
      description:
        'CloverPool es una opción completamente nueva para los mineros, que admite la minería de Bitcoin, Litecoin, Bitcoin Cash, Kaspa y otras criptomonedas. Con una experiencia de usuario mucho mejor, tarifas mucho más bajas y un servicio mucho más sólido.',
    },
    gotIt: 'Ya lo sé',
    foldMe: 'plegar todo',
    showAll: 'mostrar todo',
    copied: 'copiado',
    more: 'más',
    none: 'nada',
    on: 'activado',
    off: 'desactivado',
    all: 'todo',
    modify: 'moficiar',
    submit: 'entregar',
    ok: 'confirmar',
    cancel: 'cancelar',
    new: 'crear',
    address: 'dirección',
    notFoundTip: 'Disculpe, esta página web no está disponible',
    export: 'exportar',
    startDate: 'fecha de comienzo',
    endDate: 'fecha de finalización',
    smartPool: 'piscina inteligente',
    status: 'estatus',
    notice: 'notificación',
    yes: 'sí',
    no: 'no',
    coin: 'moneda',
    custom: 'personalizar',
    hide: 'ocultar',
    less: 'menos',
    details: 'Detalles',
    region: {
      cn: 'Beijing(China)',
      sz: 'Shenzhen(China)',
      us: 'US',
      eu: 'EU',
      sg: 'Singapore',
    },
    LegalStatement:
      'Por la presente confirmo y afirmo: No soy residente de Cuba, Irán, Corea del Norte, Siria, Rusia u otros países o regiones sujetos a sanciones administradas o aplicadas por países, gobiernos o agencias internacionales relevantes, y no soy residente de China continental. El servicio de grupo de minería proporcionado por CloverPool cumple con las leyes, regulaciones y políticas relacionadas en el país o región donde estoy ubicado.',
    LegalStatementConfirm:
      'Todos los riesgos y responsabilidades legales causados ​​por el uso ilegal de los servicios proporcionados por CloverPool en el país o región donde vivo, serán de mi exclusiva responsabilidad.',
  },
};
const commonMessageFlatten = flatten(commonMessage);
export default commonMessageFlatten;
