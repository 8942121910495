import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import dayjs from 'dayjs';
import { message } from 'antd';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/es';
import 'dayjs/locale/ru';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/ja';
import 'dayjs/locale/tr';
import 'dayjs/locale/ko';
import './i18n';
import IntlProviderWrap from './components/IntlProviderWrap';
import stores from './stores';
import App from './App';
import history from 'common/utils/history';
import 'common/utils/ajax';
import { mobile as isMobile } from 'common/utils/browserDetect';
import './icons'; // svg icon
import * as serviceWorker from './serviceWorker';
import { configure } from 'mobx';

// config mobx
// https://mobx.js.org/configuration.html
// https://mobx.js.org/migrating-from-4-or-5.html
configure({
  enforceActions: 'never',
});

const isProduction = process.env.NODE_ENV === 'production';

console.info(
  `%cCloverPool(formerly BTC.com) Web Version:${process.env.REACT_APP_GIT_SHA}`,
  'font-size: 24px; background: #3c78c2; color: #fff; font-weight: bold;padding: 5px;',
);

const root = document.getElementById('root');

if (root && isMobile) {
  root.classList.add('page-mobile');
}

// dayjs
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
const weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

// antd global config
message.config({
  top: 250,
});

ReactDOM.render(
  <Provider store={stores}>
    <IntlProviderWrap>
      <Router key={Math.random()} history={history}>
        <App />
      </Router>
    </IntlProviderWrap>
  </Provider>,
  root,
);

//
// 2
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// reportWebVitals();
