import appLocaleData from 'react-intl/locale-data/ko';
import common from './ko.common';
import pages from './ko.pages';
import pagination from './ko.pagination';
import invite from './ko.invite';

window.appLocale = {
  messages: Object.assign({}, common, pages, pagination, invite),

  locale: 'ko',
  data: appLocaleData,
};

export default window.appLocale;
