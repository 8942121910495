import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';

export const CollectionContext = React.createContext();
export const useCollectionContext = () => React.useContext(CollectionContext);

const CollectionItem = ({ children, value, disabled }) => {
  const {
    onSelect,
    selected,
    setSelected,
    value: customeValue,
    activeClassName,
  } = useCollectionContext();

  useEffect(() => {
    setSelected(customeValue);
  }, [customeValue, setSelected]);
  return (
    <div
      onClick={(e) => {
        if (!disabled) {
          setSelected(value);
          onSelect(value);
        }
        e.stopPropagation();
      }}
      className={`${styles.wrapper} ${disabled ? styles.disabled : ''}`}
    >
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          className: `${child.props.className} ${styles.collectorItem} ${
            value === selected && !disabled ? activeClassName : ''
          }`,
        });
      })}
    </div>
  );
};

const Collection = ({
  title,
  children,
  defaultSelect,
  onSelect,
  activeClassName,
  value,
}) => {
  const [selected, setSelected] = useState(defaultSelect);
  return (
    <CollectionContext.Provider
      value={{ onSelect, selected, setSelected, activeClassName, value }}
    >
      <div className={styles.collector}>
        {title && <span className={styles.collectorTitle}>{title}</span>}
        <div className={styles.collectorItems}>{children}</div>
      </div>
    </CollectionContext.Provider>
  );
};

Collection.Item = CollectionItem;

export default Collection;
