import React, { Component } from 'react';
import { Modal } from 'antd';
import Ts from 'components/LocaleTranslator';
import './index.scss';
class BtcModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      visible,
      title,
      onCancel,
      onOk,
      confirmLoading,
      children,
      hideCancelButton = false,
      hideOkButton = false,
      closable = true,
      okButtonProps,
      cancelButtonProps,
      width = 560,
      ...otherProps
    } = this.props;

    return (
      <Modal
        title={title}
        visible={visible}
        okText={<Ts id="common.ok" />}
        onOk={onOk}
        onCancel={() => {
          // loading 状态不可关闭Modal,且disable取消按钮
          if (confirmLoading) {
            return;
          }
          onCancel?.();
        }}
        confirmLoading={confirmLoading}
        maskClosable={false}
        closable={closable}
        wrapClassName={`${hideOkButton ? 'modal-hide-ok' : ''} ${
          hideCancelButton ? 'modal-hide-cancel' : ''
        }`}
        width={width}
        okButtonProps={okButtonProps}
        cancelButtonProps={{ ...cancelButtonProps, disabled: confirmLoading }}
        {...otherProps}
      >
        {children}
      </Modal>
    );
  }
}

export default BtcModal;
