import flatten from 'flat';
const inviteMessage = {
  invite: {
    inviteTitle: 'Einladung',
    bindPhoneTip:
      'Rufnummer zum Mitmachen binden, beide Seiten bekommen Provision, dauerhaft gültig.<br>Bitte gehe zu <span style="font-weight:500;color:#333"> Benutzercenter -> Kontoeinstellungen </span>, um Ihre Telefonnummer zu binden.',
    needStep: 'Noch ein Schritt',
    createInviteAccountLoadingMessage:
      'Einladungscode und Unterkonto erstellen...',
    heading:
      'Laden Sie Freunde ein, zusammen zu minen, <br>und erhalten Sie eine Prämie von <span style="color:#0A66C2">  0,2% </span>Rabatt',
    cutoff:
      '<span style="color:#0A66C2">5% </span>Rabatt auf die Provision für Eingeladene',
    ruleDetails: 'Regeldetails',
    totalRebate: 'Gesamtprovision',
    totalRebateTip:
      'Die Provision wird in der Kryptowährung abgerechnet, hier ist die Fiat-Währungsbewertung, die Bewertungsmethode besteht darin, jede zu zahlende Kryptowährung mit dem Wechselkurs von 0 Uhr (UTC + 0) am Abrechnungstag zu multiplizieren und dann die Summe zu addieren.',
    details: 'Einzelheiten',
    totalAccept: 'Eingeladene',
    minedAccept: 'Geminte eingeladene',
    inviteCode: 'Einladungscode',
    copy: 'Kopieren',
    inviteLink: 'Einladungslink',
    acceptInviteCode: 'Eingeladener Code',
    acceptInviteCodeTip:
      'Sie sind ein eingeladener Miner und erhalten 5% Rabatt auf den Mining-Pool-Preis. Sie können dies im Pool-Dashboard > Einnahmen > Einnahmendetails überprüfen.',
    generatePoster: 'Poster erstellen',
    inviteRecord: 'Einladungs datensatz',
    export: 'Export',
    rebateRecord: 'Provisionssatz',
    invitee: 'Eingeladener',
    inviteeStatus: 'Status',
    'inviteeStatus-unlocked': 'Entlassen',
    'inviteeStatus-registered': 'Eingetragen',
    'inviteeStatus-mined': 'Abgebaut',
    registerTime: 'Eingeladene Zeit',
    miningTime: 'Bergbau starten',
    miningTimeTip:
      'Wenn der Eingeladene am ersten Tag nach Erhalt Ihrer Einladung mit dem Mining beginnt, wird die UTC-Zeit übernommen.',
    coinType: 'Währung',
    region: 'Bergbauregion',
    inviteSubAccount: 'Provisionsunterkonto',
    rebatePercent: 'Provisionsverhältnis',
    op: 'Arbeiten',
    originCoinCount: 'Betragen',
    specialSubAccountTitle:
      'Wir haben einige spezielle Unterkonten erstellt, damit Sie eine Provision erhalten. Nachdem Ihre Eingeladenen mit dem Mining begonnen haben, wird die Provision der Mining-Einnahmen in verschiedenen Währungen und Regionen auf Ihre entsprechenden Unterkonten abgerechnet.',
    specialSubAccountTip:
      'Standardmäßig werden Provisionsunterkonten für die USA und Europa der BTC-Währung generiert. Wenn Ihr Eingeladener ein Mining-Unterkonto für andere Währungen erstellt, erstellen wir ein entsprechendes spezielles Unterkonto für Sie.',
    rebateOpAddress: 'Adressverwaltung',
    rebateOpDetail: 'Provisionsdetails',
    downloadPoster: 'Herunterladen',
    noData: 'Keine Daten',
    gotoBind: 'Gehe zu binden',
    boundPhone: 'Gebunden',
    headtips: 'Veranstaltung',
  },
};
const inviteMessageFlatten = flatten(inviteMessage);
export default inviteMessageFlatten;
