import zhCN from 'antd/lib/locale-provider/zh_CN';
import zhHK from 'antd/lib/locale-provider/zh_TW';
import enUS from 'antd/lib/locale-provider/en_US';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import esES from 'antd/lib/locale-provider/es_ES';
import deDE from 'antd/lib/locale-provider/de_DE';
import frFR from 'antd/lib/locale-provider/fr_FR';
import jaJP from 'antd/lib/locale-provider/ja_JP';
import koKR from 'antd/lib/locale-provider/ko_KR';

const downloadSiteUrl = process.env.REACT_APP_DOWNLOAD_SITE_URL;

export const LOCALES_MAP = {
  en: {
    key: 'en',
    text: 'English',
    short: 'en',
    serverLang: 'en',
    bannerId: 2,
    apiDocPath: '',
    ucPath: 'en', // 用户中心语种路径
    h5: 'en-us', // app
    bitdeerPath: 'en',
    hcPath: 'en-us',
    candy: 'en', // 糖果社区
    footerLang: 'en',
    explorerPath: ['en', 'en-us', 'en-US'],
    appIOSDownloadUrl: 'https://itunes.apple.com/us/app/id1490997527',
    appAndroidDownloadUrl:
      'https://play.google.com/store/apps/details?id=com.btcpool.app.android',
    dashboardMinerGroupDisplayCount: 5,
    antdLocale: enUS,
  },
  // 俄语
  ru: {
    key: 'ru',
    text: 'Pусский',
    short: 'ru',
    serverLang: 'ru',
    bannerId: 2,
    apiDocPath: '',
    ucPath: 'en', // 用户中心语种路径
    h5: 'en-us', // app
    bitdeerPath: 'ru',
    hcPath: 'ru',
    candy: 'en', // 糖果社区
    footerLang: 'ru',
    explorerPath: ['ru', 'ru-ru'],
    appIOSDownloadUrl: 'https://itunes.apple.com/us/app/id1490997527',
    appAndroidDownloadUrl:
      'https://play.google.com/store/apps/details?id=com.btcpool.app.android',
    dashboardMinerGroupDisplayCount: 4,
    antdLocale: ruRU,
  },
  // 西班牙语
  es: {
    key: 'es',
    text: 'Español',
    short: 'es',
    serverLang: 'es',
    bannerId: 2,
    apiDocPath: '',
    ucPath: 'en', // 用户中心语种路径
    h5: 'en-us', // app
    bitdeerPath: 'en',
    hcPath: 'en-us', // 帮助中心暂无西语，用英语代替
    candy: 'en', // 糖果社区
    footerLang: 'es',
    explorerPath: ['es', 'es-es'],
    appIOSDownloadUrl: 'https://itunes.apple.com/us/app/id1490997527',
    appAndroidDownloadUrl:
      'https://play.google.com/store/apps/details?id=com.btcpool.app.android',
    dashboardMinerGroupDisplayCount: 4,
    antdLocale: esES,
  },
  // 德语
  de: {
    key: 'de',
    text: 'Deutsch',
    short: 'de',
    serverLang: 'de',
    bannerId: 2,
    apiDocPath: '',
    ucPath: 'en', // 用户中心语种路径
    h5: 'en-us', // app
    bitdeerPath: 'en',
    hcPath: 'en-us', // 帮助中心暂无西语，用英语代替
    candy: 'en', // 糖果社区
    footerLang: 'de',
    explorerPath: ['de'],
    appIOSDownloadUrl: 'https://itunes.apple.com/us/app/id1490997527',
    appAndroidDownloadUrl:
      'https://play.google.com/store/apps/details?id=com.btcpool.app.android',
    dashboardMinerGroupDisplayCount: 4,
    antdLocale: deDE,
  },
  // 法语
  fr: {
    key: 'fr',
    text: 'Français',
    short: 'fr',
    serverLang: 'fr',
    bannerId: 2,
    apiDocPath: '',
    ucPath: 'en', // 用户中心语种路径
    h5: 'en-us', // app
    bitdeerPath: 'en',
    hcPath: 'en-us', // 帮助中心暂无西语，用英语代替
    candy: 'en', // 糖果社区
    footerLang: 'fr',
    explorerPath: ['fr'],
    appIOSDownloadUrl: 'https://itunes.apple.com/us/app/id1490997527',
    appAndroidDownloadUrl:
      'https://play.google.com/store/apps/details?id=com.btcpool.app.android',
    dashboardMinerGroupDisplayCount: 4,
    antdLocale: frFR,
  },
  // 土耳其语
  tr: {
    key: 'tr',
    text: 'Türkçe',
    short: 'tr',
    serverLang: 'tr',
    bannerId: 2,
    apiDocPath: '',
    ucPath: 'en', // 用户中心语种路径
    h5: 'en-us', // app
    bitdeerPath: 'en',
    hcPath: 'en-us', // 帮助中心暂无西语，用英语代替
    candy: 'en', // 糖果社区
    footerLang: 'tr',
    explorerPath: ['tr'],
    appIOSDownloadUrl: 'https://itunes.apple.com/us/app/id1490997527',
    appAndroidDownloadUrl:
      'https://play.google.com/store/apps/details?id=com.btcpool.app.android',
    dashboardMinerGroupDisplayCount: 4,
  },
  // 韩语
  ko: {
    key: 'ko',
    text: '한국어',
    short: 'ko',
    serverLang: 'ko',
    bannerId: 2,
    apiDocPath: '',
    ucPath: 'en', // 用户中心语种路径
    h5: 'en-us', // app
    bitdeerPath: 'en',
    hcPath: 'en-us', // 帮助中心暂无西语，用英语代替
    candy: 'en', // 糖果社区
    footerLang: 'ko',
    explorerPath: ['ko'],
    appIOSDownloadUrl: 'https://itunes.apple.com/us/app/id1490997527',
    appAndroidDownloadUrl:
      'https://play.google.com/store/apps/details?id=com.btcpool.app.android',
    dashboardMinerGroupDisplayCount: 4,
    antdLocale: koKR,
  },

  'zh-CN': {
    key: 'zh-CN',
    text: '简体中文',
    short: 'zh',
    serverLang: 'zh-cn',
    bannerId: 1, //广告位接口对应的语种id(i18n字段值)
    apiDocPath: '#/zh-cn/', // https://docs.pool.btc.com/ 语种路径
    ucPath: 'zh-cn', // 用户中心语种路径
    h5: 'zh-cn', // app
    bitdeerPath: 'zh',
    hcPath: 'zh-cn', // 帮助中心
    candy: 'zh-CN', // 糖果社区
    footerLang: 'zh-CN',
    explorerPath: ['zh-CN', 'zh-cn'],
    appIOSDownloadUrl:
      'https://apps.apple.com/cn/app/btc-com-pool/id1490997527',
    appAndroidDownloadUrl: `${downloadSiteUrl}/release_com_btcpool_app_android.apk`,
    dashboardMinerGroupDisplayCount: 5,
    antdLocale: zhCN,
  },
  // 繁体中文
  'zh-HK': {
    key: 'zh-HK',
    text: '繁體中文',
    short: 'zh-hk',
    serverLang: 'zh-hk',
    bannerId: 1, //广告位接口对应的语种id(i18n字段值)
    apiDocPath: '#/zh-cn/', // https://docs.pool.btc.com/ 语种路径
    ucPath: 'zh-cn', // 用户中心语种路径
    h5: 'zh-cn', // app
    bitdeerPath: 'zh',
    hcPath: 'zh-cn', // 帮助中心
    candy: 'zh-CN', // 糖果社区
    footerLang: 'zh-HK',
    explorerPath: ['zh-hk', 'zh-HK'],
    appIOSDownloadUrl:
      'https://apps.apple.com/cn/app/btc-com-pool/id1490997527',
    appAndroidDownloadUrl: `${downloadSiteUrl}/release_com_btcpool_app_android.apk`,
    dashboardMinerGroupDisplayCount: 5,
    antdLocale: zhHK,
  },
  // 日语
  ja: {
    key: 'ja',
    text: '日本語',
    short: 'ja',
    serverLang: 'ja',
    bannerId: 2,
    apiDocPath: '',
    ucPath: 'en', // 用户中心语种路径
    h5: 'en-us', // app
    bitdeerPath: 'en',
    hcPath: 'en-us', // 帮助中心暂无西语，用英语代替
    candy: 'en', // 糖果社区
    footerLang: 'ja',
    explorerPath: ['ja'],
    appIOSDownloadUrl: 'https://itunes.apple.com/us/app/id1490997527',
    appAndroidDownloadUrl:
      'https://play.google.com/store/apps/details?id=com.btcpool.app.android',
    dashboardMinerGroupDisplayCount: 4,
    antdLocale: jaJP,
  },
};
