import { at } from 'lodash';
/**
 * 页面配置，主要是控制页面内根据币种配置的显示情况
 * 大部分字段以isShow/isHide开头，值则为币种数组
 * 但是hashrateIncomeCoins也控制币种下收益显示顺序
 */
const pageConfig = {
  // 头部Header
  header: {
    // 是否显示联合挖矿
    isShowMergeMining: ['btc', 'bch', 'ltc'],
    // 是否隐藏收益历史
    isHideEarning: [],
  },
  // 首页
  homepage: {
    // 币种列表
    coinList: {
      // 是否显示收益模式模式FPPS/PPS切换
      isShowEarningsModelSwitch: ['btc'],
    },
  },
  // dashboard页面配置
  dashboard: {
    // 网络状态
    networkStatus: {
      // 币种下面显示哪些币种收益对比（按数组顺序显示）
      hashrateIncomeCoins: {
        btc: ['btc', 'bch', 'bsv'],
        smart_sha256: ['btc', 'bch', 'bsv'],
        bch: ['bch', 'btc', 'bsv'],
        bsv: ['bsv', 'btc', 'bch'],
        ubtc: [],
        eth: ['eth', 'etc'],
        etc: ['etc', 'eth'],
        ltc: ['ltc'],
        beam: ['beam'],
        dcr: ['dcr'],
        trb: ['trb'],
        ckb: ['ckb'],
      },
      // 是否显示下次难度
      isShowNextDiff: ['btc', 'smart_sha256', 'dcr', 'ltc'],
    },
    // 挖矿地址
    miningAddress: {
      // 是否显示nicehash地址
      isShowNicehashAddress: ['beam', 'dcr'],
    },
    // 收益计算器
    earningCalculate: {
      // 是否显示下拉菜单
      isShowEarningDropDown: ['smart_sha256'],
    },
  },
  // 收益历史
  earningHistory: {
    // 收益表格
    earningTable: {
      //是否显示挖矿难度
      isShowNetworkDifficulty: ['btc'],
      // 支付时间
      paymentTime: {
        // 是否隐藏交易链接
        isHideTxLink: [],
      },
      //地址
      address: {
        // 是否隐藏地址链接
        isHideAddressLink: [],
      },
    },
  },
  // 子账户列表
  subAccountList: {
    isShowSwitch: ['btc', 'smart_sha256', 'bch', 'bsv', 'ubtc', 'eth', 'etc'],
    isShowSwitchTip: ['smart_sha256'],
  },
  // 子账户创建
  subAccountCreate: {
    // 收款地址
    withdrawAddress: {
      // 是否显示btc.com 钱包
      isShowBTCCOMWallet: ['btc', 'bch'],
      // 是否显示特殊提示
      isShowSpecialTip: ['btc', 'bch', 'ubtc', 'sbtc'],
    },
  },
  // 设置
  setting: {
    // 是否显示最小支付设置
    isShowMinimumPayment: ['btc'],
    // 是否隐藏余额及收款地址
    isHideBalance: ['smart_sha256'],
    // 收款地址
    walletAddress: {
      // 是否隐藏收款地址提示
      isHideAddressTip: [],
    },
  },
};

/**
 * 获取页面配置
 *
 * @param {*} path 配置路径
 * @param {*} coin 币种
 * @returns Array | boolean
 */
function getPageConfig(path, coin) {
  if (!coin) return;
  coin = coin.toLowerCase();
  const config = (at(pageConfig, path) || [])[0];
  if (path === 'dashboard.networkStatus.hashrateIncomeCoins') {
    return config[coin] || [];
  }
  return config.includes(coin);
}

export { pageConfig, getPageConfig };
