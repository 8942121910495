import React, { useState } from 'react';
import { timeRangeOpitons } from 'common/config';
import { motion } from 'framer-motion/dist/framer-motion';
import styles from './index.module.scss';

const GroupSelector = ({
  options = timeRangeOpitons,
  lang,
  defaultValue,
  onChange,
  style,
  size = '',
  className,
}) => {
  const [currentKey, setCurrentKey] = useState(defaultValue);
  const handleClick = (value) => {
    setCurrentKey(value);
    onChange(value);
  };

  return (
    <div className={`${className}`} style={style}>
      <ul className={`${styles.container} ${size}`}>
        {options.map((item) => (
          <li
            className={styles.buttonItem}
            onClick={() => {
              handleClick(item.value);
            }}
            key={item.value}
          >
            <span
              className={`${styles.title} ${
                item?.value === currentKey ? styles.current : ''
              }`}
            >
              {item[`name_${lang.substr(0, 2)}`]}
            </span>
            {item?.value === currentKey ? (
              <motion.div className={styles.active} layoutId="active" />
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default GroupSelector;
