import { runInAction, makeAutoObservable } from 'mobx';
import axios from 'axios';

class InviteStore {
  rebateInfo = {};
  loadingRebateInfo = false;

  loadingInviteInfo = false;
  loadingInviteCheck = false;
  inviteInfo = {};
  inviteAccountList = [];

  loadingInvitee = false;
  inviteeListData = {};

  // 当前子账户是否绑定手机号
  hasBindPhone = false;
  // 当前子账户是否有邀请专属子账户
  hasInviteAccount = false;

  defaultPageParams = { page: 1, pageSize: 10 };

  //创建邀请账号是否成功
  createInviteAccountSuccess = false;
  loadingCreateInviteAccount = false;

  constructor() {
    makeAutoObservable(this); //升级mobx 6之后禁止使用装饰器，然后需要使用makeAutoObservable
  }

  /**
   * 判断用户是否已绑定手机号，判断用户是否已经生成过专属子账号
   */
  inviteCheck = async (successCallback, errorCallback) => {
    try {
      this.loadingInviteCheck = true;
      const res = await axios.post(`/invite/check`);
      /*
        "data":{
          "status":true, // true代表已绑定手机号，false：代表未绑定手机号
          "invite_check": true // true代表用户已经生成过专属子账号，false代表未生成
        }
       */
      if (res && res.data) {
        runInAction(() => {
          this.hasBindPhone = res.data.status === true;
          this.hasInviteAccount = res.data.invite_check === true;
          this.loadingInviteCheck = false;
          successCallback &&
            successCallback(this.hasBindPhone, this.hasInviteAccount);
        });
      }
    } catch (error) {
      console.log(error);
      let msg = error.msg || '';
      this.loadingInviteCheck = false;
      errorCallback && errorCallback(msg);
    }
  };

  setHasInviteAccount = (hasInviteAccount) => {
    this.hasInviteAccount = hasInviteAccount;
  };

  /**
   * 创建邀请账号
   */
  createInviteAccount = async (successCallback, errorCallback) => {
    try {
      this.loadingCreateInviteAccount = true;
      const res = await axios.post(`/invite/createInviteCode`);
      if (res && res.data && res.data.status) {
        runInAction(() => {
          this.createInviteAccountSuccess = true;
          this.loadingCreateInviteAccount = true;
          successCallback && successCallback();
        });
      }
    } catch (error) {
      console.log(error);
      let msg = error.msg || '';
      this.createInviteAccountSuccess = false;
      this.loadingCreateInviteAccount = false;
      errorCallback && errorCallback(msg);
    }
  };

  /**
   * 获取邀请人返点信息
   */
  getRebateInfo = async () => {
    try {
      this.loadingRebateInfo = true;
      const res = await axios.get(`/invite/inviterRebate`);
      if (res && res.data) {
        runInAction(() => {
          this.rebateInfo = res.data;
          this.loadingRebateInfo = false;
        });
      }
    } catch (error) {
      this.loadingRebateInfo = false;
    }
  };

  /**
   * 获取邀请人信息（包括邀请码，链接，受邀码及专属子账户）
   */
  getInviteInfoAndInviteAccounts = async () => {
    try {
      this.loadingInviteInfo = true;

      const res = await axios.get(`/invite/inviterInfo`);
      if (res && res.data) {
        runInAction(() => {
          const { rebate_list, ...inviteInfo } = res.data;
          this.inviteInfo = inviteInfo;
          this.inviteAccountList = rebate_list;
          this.loadingInviteInfo = false;
        });
      }
    } catch (error) {
      this.loadingInviteInfo = false;
    }
  };

  /**
   * 获取受邀请人信息列表
   */
  getInviteeList = async ({ page, pageSize }) => {
    try {
      this.loadingInvitee = true;
      const res = await axios.get(`/invite/inviteeList`, {
        params: {
          page,
          page_size: pageSize,
        },
      });
      if (res && res.data) {
        runInAction(() => {
          this.inviteeListData = res.data;
          this.loadingInvitee = false;
        });
      }
    } catch (error) {
      this.loadingInvitee = false;
    }
  };
}

const inviteStore = new InviteStore();

export { inviteStore };
