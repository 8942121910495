import flatten from 'flat';
const pageMessage = {
  pages: {
    dashboard: {
      EarningsM: 'Revenus de Minage de {coinType}',
      accountEarning: 'Revenus de Compte',
      active: 'Actif',
      activeMiner: 'Actif',
      amoutPaid: 'Montant Total Payé',
      announcement: 'Annonce',
      cannotConnect: 'Impossible de se connecter?',
      urlConfigGpu: ' prend en charge le mineur GPU normal',
      urlConfigNicehash: ' optimisé pour Nicehash',
      urlConfigGpuDcr: ' prend en charge Antminer DR3，Claymore，gominer',
      urlConfigNicehashDcr: ' prend en charge Nicehash，Innosilicon miner',
      customerTitle: 'Service Après-Vente',
      dailyHashrate: 'Quotidien',
      dashboard: 'Tableau de Bord',
      earnMore: 'Combien ai-je gagné en plus?',
      earning: 'Revenus de {coinType}',
      eventConnected: 'connecté',
      eventDisconnected: 'déconnecté',
      eventMiner: 'Mineur',
      eventName: 'événement',
      eventSkip: 'Passer',
      eventTitle: 'Événements',
      eventlatestLogs: 'Les événements ci-dessous sont passés',
      hastrateChart: 'Tableau de Taux de Hachage',
      history: 'Historique',
      hour: '{n} H',
      hours: 'Heures',
      inActive: 'Inactif',
      lastPaymentTime: 'Date de Dernier Paiement',
      markAllRead: 'Marquer Tout Comme Lu',
      minerContent:
        "Accédez au réseau local et connectez-vous au système d'arrière-plan des mineurs, remplissez le nom du mineur et enregistrez le profil. Les mineurs seront ajoutés à CloverPool en quelques secondes.<br/>Paramètres d'ID de Mineur: {sub_account}.001, {sub_account}.002, etc. Chaque ID de Mineur correspond à un mineur.",
      minerNameStand: `Norme de nom de mineur: sous-compte+'.'+numéro. Par exemple, si votre sous-compte est {sub_account}, vos mineurs pourraient être configurés comme {sub_account}.001, {sub_account}.002. Chaque mineur possède son propre nom.`,
      minerName:
        '* Définissez &lt;sub-account&gt;.&lt;miner_ID&gt; sur vos mineurs, comme {sub_account}.001, {sub_account}.002, etc.',
      miner: 'Mineur',
      miners: 'Mineurs',
      miner1: 'Mineur Type I',
      miner2: 'Mineur Type II',
      miningAddress: 'Adresse de Minage',
      miningEarnings: 'Revenus de Minage de {coinType}',
      minute: 'M',
      more: 'Plus',
      networkDiff: 'Difficulté de Réseau',
      networkHashrate: 'Taux de Hachage de Réseau',
      networkStatus: 'État de Réseau',
      nextDifficult: 'Prochaine Difficulté Estimée',
      noMiner:
        "Ajoutez des mineurs à CloverPool et commencez à miner. Il est recommandé d'utiliser: ",
      btcTools: 'BTC Tools',
      noEvent: "Aucune notification d'événement!",
      nowHashrate: 'Temps Réel',
      pendingPayouts: 'Attente de Paiment',
      poolHashrate: 'Taux de Hachage de Pool',
      realHashrate: 'Taux de Hachage de {coinType}',
      realtime: 'Temps Réel',
      timeRemain: 'Temps Restant',
      title: 'Tableau de Bord - CloverPool',
      today: 'Estimation du Jour',
      unpaid: 'Solde',
      yesterday: 'Hier',
      yesterdayEarnTip:
        "Fait référence aux gains de 00h00 à 24h00 la veille de l'heure UTC.",
      todayEarnTip:
        "Le revenu estimé du taux de hachage qui ne mine que des BTC (l'algorithme de Sha256)",
      charts: {
        hashrate: 'Taux de Hachage',
        minerCharts: 'Tableaux de Taux de Hachage de Mineur',
        myCharts: 'Mes Tableaux de Taux de Hachage',
        poolCharts: 'Tableaux de Taux de Hachage de CloverPool',
        reject: 'Rejeté',
        time: 'Date',
      },
      yesterday_earn: "Revenus d'Hier",
      yesterday_hashhrate: "Taux de Hachage Accepté d'Hier",
      earnings_per_100T: 'Revenus par 100T',
      //职责声明
      statement:
        "* Vous n'êtes pas autorisé à accéder (ou à tenter d'accéder) à ce service par d'autres moyens que ceux que nous fournissons ou avons indiqués, et vous acceptez spécifiquement de ne pas accéder (ou tenter d'accéder) à ce service par le biais de tout moyen illégal ou d'utiliser ce service pour soutenir des activités ou des objectifs illégaux. En accédant à ce site Web et en vous inscrivant à ce service, vous reconnaissez et acceptez que vous êtes une personne à qui ce service peut être légalement fourni conformément aux lois de la juridiction dans laquelle vous vous trouvez.",
      // 收益预估
      estimatedEarnings: {
        title: 'Revenus Estimés',
        hours24Earnings: 'Estimation des revenus des dernières 24 heures',
        tip24:
          'Selon la difficulté moyenne de la devise au cours des dernières 24 heures',
        realTimeEarnings: 'Estimation des revenus en temps réel',
        realTimeEarningsTip:
          'Selon la dernière difficulté de minage de la devise',
      },
    },
    subAccount: {
      title: 'Créer un sous-compte - CloverPool',
      createWorker: 'Créer un sous-compte',
      noNode: "Vous n'avez pas encore choisi le nœud!",
      noCoinType: "Vous n'avez pas encore choisi la pièce de minage!",
      selectRegion: 'Sélectionner votre région',
      setWorker: 'Définir un nom de sous-compte',
      enterWorker: 'Saisir le nom du sous-compte',
      enterAddress: "Saisir l'adresse",
      coinTypeEx:
        "CloverPool prend désormais en charge le minage de {supportCoins}. D'autres pièces viendront bientôt!",
      regionEx:
        'CloverPool déploie des serveurs dans le monde entier, et le serveur le plus proche vous apportera la meilleure expérience.',
      workerEx:
        "Ce sous-compte sera lié à la région ci-dessus. Vous pouvez créer jusqu'à 2000 sous-comptes. Définissez <sub-account>.<miner_name> sur vos mineurs, comme subaccount.001, subaccount.002, etc.",
      workerCaution:
        "* Attention: chaque sous-compte ne peut miner que dans une seule région. Ce sous-compte que vous créez ne peut pas être utilisé dans d'autres régions. Si vous souhaitez miner dans une autre région, veuillez créer un autre sous-compte dans une autre région.",
      setAddress: 'Définir une adresse de retrait',
      save: 'Créer et Miner',
      verifyWorker: '3-20 lettres ou chiffres',
      option: 'Optionnel',
      optional:
        "Optionnel. L'adresse de BCH est similaire à celle de BTC. Veuillez faire attention et ne pas remplir une adresse erronée.",
      'optional-BTC':
        "Optionnel. L'adresse héritée de BCH est similaire à celle de BTC. Veuillez faire attention et ne pas remplir une adresse erronée.",
      'optional-BCH':
        "Optionnel. L'adresse héritée de BCH est similaire à celle de BTC. Veuillez faire attention et ne pas remplir une adresse erronée. Il est recommandé d'utiliser une nouvelle adresse.",
      'optional-UBTC':
        "Optionnel. L'adresse héritée de BCH est similaire à celle de UBTC. Veuillez faire attention et ne pas remplir une adresse erronée.",
      'optional-SBTC':
        "Optionnel. L'adresse héritée de BCH est similaire à celle de SBTC. Veuillez faire attention et ne pas remplir une adresse erronée.",
      'optional-coin':
        'Optionnel. Veuillez faire attention et ne pas remplir une adresse erronée.',
      selectCoinType: 'Sélectionner votre pièce de minage',
      automatic: 'Automatique',
      recommandWallet:
        "nous vous recommandons d'utiliser le Portefeuille CloverPool",
      withdrawToExchange: "Aide d'échange",
      withdrawToWallet: 'Aide de portefeuille',
    },
    tools: {
      // title: 'Outils CloverPool',
      // tools: 'Outils',
      // fppsPps: 'Comparaison Dynamique FPPS vs PPS',
      // aboutFpps: 'Qu'y a-t-il de bien à propos de FPPS?',
      // charts: 'Les tableaux peuvent tout dire.',
      // watchCharts: 'Consulter les Tableaux',
      // miniCalc: 'Calculateur de Profit Minier',
      // miniCalcExpress:
      //   'Calculez le profit minier combiné avec le prix, la difficulté, le taux de hachage, les frais d'électricité, la consommation d'énergie, etc.',
      // miniMiniCalc: 'Calculateur de Profit Minier Mini',
      // miniMiniCalcExpress: 'Calculez rapidement le profit minier de la manière la plus simple',
      // strat: 'Commencer',
      // btcTool: 'BTC Tools',
      // btcToolIntroduce: "Processus par lots pour la configuration efficace des mineurs.",
      // download: 'Cliquez pour télécharger',
      btcSmartAgent: 'BTC Smart Agent',
      agentExpress: 'Efficace et Transparent <br/>Travailleur Unique Visible',
      agentExpressBanner:
        'Efficace et Transparent <i style="margin-right:20px"></i> Travailleur Unique Visible',
      bit: 'bit',
      document: "Document d'Aide Windows.",
      poolApp: 'App CloverPool Complète',
      poolRealTime: 'Affichez les données de pool et de mineurs en temps réel',
      poolOpeation: 'Supprimez/modifiez les mineurs directement',
      poolComming: "Fonction d'alerte d'événement à venir",
      poolDownlaod: 'Cliquez pour télécharger',
      title: 'Outils CloverPool',
      tools: 'Outils',
      fppsPps: 'Comparaison Dynamique FPPS vs PPS',
      aboutFpps: "Qu'y a-t-il de bien à propos de FPPS?",
      charts: 'Les tableaux peuvent tout dire.',
      watchCharts: 'Consulter les Tableaux',
      miniCalc: 'Calculateur de Profit Minier',
      miniCalcExpress:
        "Calculez le profit minier combiné avec le prix, la difficulté, le taux de hachage, les frais d'électricité, la consommation d'énergie, etc",
      miniMiniCalc: 'Calculateur de Profit Minier Mini',
      miniMiniCalcExpress:
        'Calculez rapidement le profit minier de la manière la plus simple',
      start: 'Commencer',
      btcTool: 'BTC Tools',
      btcToolIntroduce:
        'Processus par lots pour la configuration efficace des mineurs.',
      ipToolsTitle: 'Définissez les Outils IP Statiques des Mineurs',
      ipToolsDes:
        'Prend en charge tous les Antminers, la Configuration IP occupée, le configuration de la connexion directe au pool',
    },
    miners: {
      createGroup: 'Créer un Nouveau Groupe',
      default: 'DÉFAUT',
      addGroup: 'Créer un Nouveau Groupe',
      deleteGroupTitle: 'Supprimer le Groupe',
      editGroupTitle: 'Modifier le Groupe',
      addGroupInfo:
        'Remarque: les mineurs sélectionnés seront automatiquement ajoutés au nouveau groupe.',
      groupNameLabel: 'Nom de Groupe',
      addGroupEmpty: 'Le nom du groupe ne peut pas être vide.',
      groupRejected: 'Taux de rejet en temps réel',
      groupNameLimit: 'Le nom du groupe doit comporter entre 3 et 20 chiffres',
      all: 'TOUT',
      inactive: 'Inactif',
      active: 'Actif',
      dead: 'Mort',
      moveTo: 'Déplacer vers',
      remove: 'Supprimer',

      allTooltip:
        'Comprend: Mineurs Actifs ({active}) + Mineurs Inactifs ({inactive})',
      inactiveTooltip:
        "Le nombre de mineurs n'ayant pas envoyé de résultats de calcul (Share) pendant plus de 10 minutes.",
      activeTooltip:
        'Le nombre de mineurs envoyant normalement les résultats de calcul (Share)',
      deadTooltip:
        "Le nombre de mineurs n'ayant pas envoyé de résultats de calcul (Share) pendant plus de 24 heures",

      home: 'Accueil',
      miners: 'Mineurs',

      workerName: 'Nom de Travailleur',
      Hashrate: 'Temps Réel',
      Rejected: 'Taux de Rejet',
      dailyHashrate: 'Quotidien',
      firstShare: 'Première Connexion',
      LastShare: 'Dernier Partage',
      groupName: 'Nom de Groupe',

      delMillTitle:
        'Voulez-vous vraiment supprimer ces informations de mineur?',
      delMillContext:
        'Cette opération supprimera tous les mineurs que vous avez sélectionnés. Veuillez opérer avec précaution.',

      delGroupTitle: 'Voulez-vous vraiment supprimer le groupe {name}?',
      delGroupContext: 'Les mineurs du groupe passeront tous par défaut',
      delMillFailed: 'Impossible de supprimer',

      moveTitle: 'Déplacer les mineurs',
      notChecked:
        'Sélectionnez les mineurs et effectuez une opération par lots.',
      moveContext:
        'Cela déplacera tous les mineurs sélectionnés vers le groupe {groupName}.',
      moveFaild: 'Impossible de déplacer vers le groupe actuel',

      searchPlaceholder: 'Saisir le nom du travailleur',
      reportTime: 'Date de création',
      refresh: 'Actualiser',
      editGroupName: 'Modifier le nom de groupe',
      workListEmpty: 'Aucun mineur détecté dans ce groupe',
      exist: 'Le nom de groupe est déjà utilisé.',

      goNewVersion: 'Retour à la nouvelle version',
      goOldVersion: "Retour à l'ancienne version",
      dataExport: `Production de données de taux de hachage des mineurs du groupe "{name}"`,
      exportTip:
        "Veuillez sélectionner l'intervalle de durée des données à exporter, et les données ne peuvent être exportées que sur 30 jours maximum. Le format du fichier exporté est CSV.",
      exportTip2: `La durée de calcul du taux de hachage actuel est de 0h à 23h59, et la durée de calcul du taux de hachage d'aujourd'hui est de 0h à l'heure d'exportation.`,
    },
    minerInfo: {
      title: 'Mineurs - CloverPool',
      miners: 'Mineurs',
      hashrate: 'Taux de Hachage Unique',
      runningCondition: 'État de Fonctionnement',
      min: 'M',
      hour: '{n}H',
      hashrateAxis: 'Taux de Hachage',
      rejected: 'Rejeté',
      status: 'État',
      lastShare: 'Dernier Partage',
      lastShareIP: 'IP de Dernier Partage',
      hashrateChart: 'Tableau de Taux de Hachage',
      realTime: 'Temps Réel',
      trans: {
        seconds: 's',
        mins: ' mins',
        hours: ' heures',
        ago: ' avant',
        ACTIVE: 'Actif',
        INACTIVE: 'Inactif',
        DEAD: 'Mort',
      },
    },

    earningHistory: {
      title: 'Historique de Revenus - CloverPool',
      earn: 'Historique de Revenus',
      Unpaid: 'Non payé',
      EarningsToday: 'Estimé Miné',
      EarningsYesterday: "Revenus d'Hier",
      Time: "Date d'extraction",
      NetworkDifficulty: 'Difficulté',
      Earnings: 'Revenus',
      Payment: 'Date de Paiement',
      payWithOTCAmount: 'Payer avec {otcType}: {amount}',
      Mode: 'Mode',
      Address: 'Adresse',
      PaidAmount: 'Gains',
      yesterday:
        "Fait référence aux gains de 00h00 à 24h00 la veille de l'heure UTC.",
      unpaidReason: {
        PENDING_NON_ADDRESS:
          "Si vous n'avez pas défini l'adresse, vos revenus seront conservés dans le solde",
        PENDING_CHANGED_ADDRESS:
          "Le paiement sera effectué dans les 48 heures si l'adresse est modifiée. Attention: votre compte (e-mail/numéro de téléphone) a probablement été attaqué si ce n'est pas vous-même qui avez modifié l'adresse. Veuillez enregistrer immédiatement un nouveau compte avec d'autres e-mails ou numéros de téléphone et basculer vos mineurs vers ce nouveau compte.",
        PENDING_NOT_ENOUGH:
          'Le montant est inférieur au paiement minimum que vous avez défini (la valeur par défaut est :limit ). Il sera déposé dans votre solde.',
        PENDING_POOL_SETTLE_FAULT:
          "En raison du pool minier, cette partie du montant sera envoyée par erreur et ne sera pas déposée à l'avenir.",
      },
      depositeAmount: 'Enregistré',
      'diff-chg': 'Changement de Difficulté: ',
      'equals-pps': 'Égal à PPS',
      Chg: 'Chg.',
      status: 'Statut de paiement',
      paid: 'Payé',
      waitingPay: 'Attente',
      reason: 'Raison',
      fppsMoreEarn: '{mode} Extra:',
      fppsContain:
        "Par rapport au PPS, le revenu du modèle {mode} augmente d'environ {pps96}",
      noEarningsInformation: 'Aucune information de revenu',
      earningDetail: 'Détails des gains',
      extraProfits: 'Profit supplémentaire',
      autoSwap: 'Échange Auto',
      original: "Devise d'Origine",
      Equivalent: 'Équivalent de BTC',
      Swapped: 'Échangé',
      swapping: 'Échange...',
      otcEarningTip:
        "Le règlement des revenus en devise cible du mode Pool Intelligent par des réponses d'échange auto sur le taux de change réel de l'échange automatique dans Matrixport le jour de la facturation.",
      originEarningTip:
        "Le règlement des revenus en devise cible du mode Pool Intelligent par devise d'origine sera calculé selon le taux de change de l'échange auto dans Matrixport le jour de facturation à 8h.",
      exportEarningsData: 'Exporter les Données de Revenus',
      exportTip:
        "Il peut prendre en charge jusqu'à 180 jours de données et le format de fichier exporté est XLS.",
      originStatusTip:
        "Les revenus sont échangés dans la devise cible par l'échange auto. Vous pouvez vérifier plus de détails dans le menu des Revenus du mode Pool Intelligent. ",
      swappingTip:
        "Le revenu actuel est en cours d'échange ou le montant minimum d'échange n'est pas atteint en raison du faible revenu",
      swappedTip:
        "Les revenus sont échangés dans la devise cible par l'échange auto. Vous pouvez vérifier plus de détails dans le menu des Revenus du mode Pool Intelligent",
      // upgrade TODO 收益改版新增
      settlementHashrate: 'Hashrate de règlement',
      addressRatio: "Rapport d'adresse",
      addressEarnings: 'Adresse Gains',
      paymentTime: 'Délai de paiement',
      EarningsTodayTip:
        "Il s'agit du montant estimé des gains PPS qui ont été extraits de 0:00 (UTC) à ce moment, et ce montant peut différer de vos gains réels.",
      TimeTip:
        'La date de minage est en heure UTC. Par exemple, le 1er janvier 2020 fait référence à 00:00:00 le 1er janvier 2020-00:00:00 le 2 janvier 2020 en heure UTC.',
      EarningsTip:
        'Le montant des gains est calculé par le "Centre de règlement CloverPool" en fonction de plusieurs facteurs : la méthode de distribution des gains, la difficulté du réseau, le hashrate de règlement, la récompense de base et la récompense des frais de Tx de Block, et les taux de commission, etc., il peut y avoir des écarts dans les gains théoriques que vous avez calculés dans le calculateur de gains.',
      // upgrade TODO 邀请新增
      inviteEarning: 'Gains de commissions',
      inviteWorkerAccount: "Sous-compte d'invité",
      inviteRebateRatio: 'Taux de commission',
      inviteRebateAmount: 'Montant de la commission',
      rebateExportTip:
        "Il peut prendre en charge jusqu'à 60 jours de données et le format de fichier exporté est XLS.",
    },

    //统计
    poolStats: {
      title: 'Stats de Pool - CloverPool',
      poolStats: 'Stats de Pool',
      hashrate: 'Taux de Hachage',
      miners: 'Mineurs',
      coinType: 'Type de Pièce',
      found: 'Trouvé',
      hashrateChart: 'Tableau de Taux de Hachage',
      blocksRelayed: 'Blocs Relayés',
      height: 'Hauteur',
      time: 'Date',
      blocks: 'Blocs',
      blockHash: 'Hachage de Bloc',
      ddaScore: 'DAA Score',
      reward: 'Récompense',
      day: 'Jours',
      region: 'Région',
      lucky: 'Chanceux',
      btc: 'BTC',
      nmc: 'NMC',
      zec: 'ZEC',
      bcc: 'Bitcoin Cash',
      bch: 'Bitcoin Cash',
      eth: 'Ethereum',
      dcr: 'Decred',
      etc: 'Ethereum Classic',
      sbtc: 'Super Bitcoin',
      ubtc: 'United Bitcoin',
      grin: 'Grin',
    },

    // 守护者
    guardian: {
      expiredDeadline: ' (:day Supprimé automatiquement)',
      guardianRemark:
        'Vous pouvez partager tout (ou une partie) des informations de vos sous-comptes (y compris le taux de hachage, les mineurs) avec un autre utilisateur qui ne peut que voir, sans pouvoir modifier, les paramètres.',
      createGuardian: 'Ajouter un Utilisateur de Partage',
      remark: 'Remarque:',
      user: 'Utilisateur:',
      manage: 'Gérer',
      status: 'État:',
      theGuardian: 'Compte de Partage:',
      creationDate: 'Date de Création:',
      bounded: 'Lié',
      datePrevious: "Date d'Utilisation Précédente:",
      delete: 'Supprimer',
      deleteGuardianLink: ' Supprimer le Lien du Compte de Partage',
      createGuardianInvitation: 'Créer le Lien du Compte de Partage',
      guardianAccountManagement: 'Gestion du Compte de Partage',
      modifyRemark: 'Modifier la Remarque',
      validateRemarkEmpty: 'La remarque ne peut pas être vide',
      enterRemark: 'Ajouter une Remarque',
      noteInformation:
        "Remarque: cette remarque est utilisée pour distinguer plusieurs comptes de partage. Nous vous suggérons d'utiliser les informations de contact.",
      confirm: 'Confirmer',
      create: 'Créer',
      cancel: 'Annuler',
      guardianSuccessfullyCreated:
        "Le lien d'invitation au compte de partage a été créé",
      copyLink: 'Copier le Lien',
      authorized:
        "Après la suppression, les autres perdront l'autorisation de gestion de votre sous-compte.",
      noteSendGuardian:
        'Conseil: veuillez envoyer ce lien à des personnes fiables ayant un besoin pour la gestion des mineurs. Ce lien peut être utilisé une fois. Si le lien est expiré, veuillez le recréer. ',
      close: 'Fermer',
      subaccount: 'Sous-Compte',
      coinType: 'Type de Pièce',
      region: 'Région',
      guardianStatus: 'Partage',
      guardianSelectExpress:
        "Grâce à l'interrupteur dans l'état de partage, vous pouvez choisir si le compte d'un autre utilisateur peut gérer votre sous-compte ou non. (Le paramètre par défaut est que le Tuteur peut visiter tous vos sous-comptes.)",
      invitTitle: "Partager mon Compte avec d'Autres",
      acceptTitle: 'Autres Comptes Acceptés par Moi',
      losePower:
        'Si vous supprimez ceci, vous perdrez le droit de gérer le sous-compte.',
      show: 'Afficher',
    },
    // 绑定守护者邀请链接
    guardianBind: {
      bindingGuardianInvitation:
        "Lien d'Invitation de Liaison du Compte de Partage",
      advance: 'Avancer',
      guardianAccountManagement: 'Lien de Liaison du Compte de Partage:',
      confirmGuardianBind: 'Gestion du Compte de Partage',
      remark: 'Remarque',
      enterRemark: 'Ajouter une Remarque',
      suggestName:
        "Nous suggérons d'utiliser le nom pour rendre la gestion plus pratique. Si vous laissez le champ vide, cela signifie que vous utiliserez le numéro de téléphone ou l'adresse e-mail par défaut.",
      guardian: 'Lier',
      cancel: 'Annuler',
      confirm: 'Confirmer',
      manageView: 'pour gérer tous vos sous-comptes?',
      suggestUsingName:
        "Nous suggérons d'utiliser le nom pour rendre la gestion plus pratique. Si vous laissez le champ vide, cela signifie que vous utiliserez le numéro de téléphone ou l'adresse e-mail par défaut.",
      sms: 'Téléphone',
      email: 'E-mail',
      code: 'Code de Vérification de {mode}',
      send: 'Envoyer',
    },
    account: {
      subAccountManage: 'Gestion de Sous-Compte',
      currentAccount: 'Compte Actuel',
      locationRegion:
        'Votre emplacement est dans la région <strong>{coin}</strong> <strong>{region}</strong>',
      locationEx:
        "Chaque sous-compte correspond à une icône et une zone fixes, et la devise et la zone ne peuvent pas être modifiées une fois créées. S'il y a d'autres exigences, vous pouvez créer un nouveau sous-compte.",
      general: 'Version Générale',
      advance: 'Version Avancée',
      create: 'Créer',
      algorithm: ' Algorithme',
      oneButtonSwitch: 'Interrupteur à un Bouton',
      addOthersCoins: "Ajouter d'autres pièces",
      supportCoins: 'Prise en Charge',
      hiddenSubAccounts: 'Gestion de Sous-Comptes Masqués',
      hashAlert: 'Fonctionne bien',
      viewHideSubAccount: 'Afficher le sous-compte masqué',
      addSubAccount: 'Ajouter un sous-compte',
      recover: 'Restaurer',
      latestAlert: {
        hashrate: 'Taux de Hachage {symbol} {expect} {unit}',
        miners: 'Nombre de mineurs {symbol} {expect}',
      },
    },
    settings: {
      subAccount: 'Sous-Compte',
      currency: 'Devise',
      region: 'Région',
      walletAddressAndMinimumPayment: 'Adresse de Portefeuille',
      walletAddress: 'Adresse de Portefeuille',
      shareData: 'Partage de Compte',
      shareDataTip:
        "Vous pouvez partager tout ou partie des informations de votre sous-compte avec d'autres personnes qui peuvent uniquement les consulter mais ne peuvent pas les modifier ; vous pouvez également recevoir tout ou partie des informations de sous-compte d'autres personnes en mode lecture seule.",
      alertSettings: 'Définir une alerte',
      mywatcher: "Définir l'observateur'",
      apikey: 'API KEY',
      payWithBtc: 'Payer avec BTC',
      //wallet address & minimum payment
      setAddress: 'Veuillez définir une Adresse de {coinType}.',
      address: 'Adresse',
      createAddress: 'Ajoutez une adresse',
      modifyAddress: 'Modifier votre Adresse de Portefeuille',
      modify: 'Modifier',
      minPay: 'Seuil',
      lastUpdateTime: "Date de mise à jour de l'adresse",
      updateBitconAddressTip:
        'Remarque: pour des raisons de sécurité, vos revenus seront conservés sous forme de solde pendant 48 heures.',

      //watcher
      old: 'Ancien',
      new: 'Nouveau',
      continue: 'Continuer',
      emptyAddress: "L'adresse ne peut pas être vide",
      addAddress: 'Définir',
      enterCode: 'Saisir le code',
      newBit: 'Votre nouvelle adresse',
      way: ' Nous vous enverrons un code pour confirmer cette modification. Comment souhaitez-vous le recevoir?',
      sendCode:
        'Nous vous avons envoyé un code pour confirmer cette modification',
      sendVerifyCode:
        'Nous vous avons envoyé un {contactType} pour confirmer cette modification',
      sms: 'message',
      mail: 'e-mail',
      mailVia: 'Envoyer le code de vérification par e-mail ',
      smsVia: 'Envoyer le Code de vérification au téléphone ',
      errorLater: 'Veuillez réessayer plus tard...',
      updateSuccess: "L'adresse a été mise à jour!",
      // 多地址收款
      multAddress: {
        tag: 'Adresses Multiples',
        addMultAddress: 'Ajoutez une adresse',
        adjustProportion: 'Ajuster la Proportion',
        adjustMinPay: 'Ajuster le Seuil',
        address: 'Adresse',
        proportion: 'Proportion',
        remark: 'Remarque',
        lastUpdateTime: 'Date la plus Récente',
        operation: 'Opération',
        emptyText: "Vous n'avez ajouté aucune adresse",
        emptyTextForMult:
          "Aucune donnée! Jusqu'à 20 adresses peuvent être ajoutées.",
        cancel: 'Annuler',
        nextStep: 'Étape Suivante',
        delete: 'Supprimer',
        modify: 'Modifier',
        newAddressPlaceholder: 'Nouvelle Adresse',
        addressMaxCountTip:
          "Vous pouvez actuellement ajouter jusqu'à {maxCount} adresses de réception dans un sous-compte. Si vous avez besoin de prendre en charge plus d'adresses, veuillez contacter le service client: support@connectbtc.com",
        updateBitconAddressTip:
          'Pour la sécurité des fonds, le paiement sera suspendu dans les 48 heures suivant cette opération, et vos gains à recevoir seront payés le premier jour de règlement après 48 heures.',
        emptyRemark: 'Aucune remarque ajoutée',
        noAddressForAdjust:
          "Vous n'avez ajouté aucune adresse. Veuillez d'abord ajouter des adresses avant d'ajuster le ratio ou le seuil.",
        balanceMoveTip:
          'La crypto-monnaie impayée du sous-compte actuel sera transférée vers "Nouvelle adresse 1", veuillez confirmer votre opération.',
        confirmModalTitle: 'Vérification de Sécurité',
        authentication: 'Authentification',
        operationConfirm: "Confirmation d'Opération",
        addAddressConfirmTip: 'Vous ajoutez {count} adresses',
        modifyProportionConfirmTip:
          'Vous ajustez la proportion de chaque adresse de réception',
        deleteAddressConfirmTip: "Vous supprimez l'adresse: <br/>{address}",
        selectBalanceMoveAddress: "Choisissez une adresse d'héritage",
        selectBalanceMoveAddressTip:
          "La crypto-monnaie non payée de l'adresse en cours de suppression est de {balance}{coinType}. Après cette opération, il sera transféré à la nouvelle adresse sélectionnée ci-dessus dans les 24 heures",
        cannotDeleteAddress: "Impossible de supprimer l'adresse",
        deleteAddressRule:
          'Seules les adresses avec une proportion de paiement égale à 0,00% peuvent être supprimées.',
        // deleteAddressRuleDetail:
        //   '1. La proportion est égale à 0,00%.<br/>2. L'adresse de minage intelligent du même sous-compte n'est pas définie comme cette adresse.',
        // usedBySmart:
        //   'Le minage intelligent du sous-compte actuel sélectionne l'adresse actuelle. Cette modification modifiera donc simultanément l'adresse de minage de la mitrailleuse.',
        operationSuccess: 'Fonctionne avec succès',
        validate: {
          emptyAddress: 'Veuillez remplir une nouvelle adresse',
          emptyAddressForUpdate: "L'adresse ne peut pas être vide",
          emptyProportion: "Veuillez remplir la proportion de l'adresse",
          emptyProportioAfter: '',
          proportionInvalidate:
            'La proportion de chaque adresse doit être supérieure ou égale à 0 %, et la somme de toutes les proportions doit être égale à 100 %',
          maxCount:
            "Jusqu'à 20 adresses peuvent être définies pour chaque sous-compte",
          duplicateAddressPrefix:
            "La même adresse ne peut être ajoutée qu'une seule fois, mais l'adresse ",
          duplicateAddressSuffix: ' est ajouté à plusieurs reprises.',
          limitAddress: 'This type of address is not supported at this time',
        },
      },
      //min pay
      currentMinPay: 'Paiement Minimum Actuel:',
      minPayAllSubAccount:
        'Le paiement minimum est égal pour tous les sous-comptes de BTC',
      todayUpdateMinPay:
        "Le paiement minimum des revenus miniers a été modifié à partir d'aujourd'hui: {minPay}",

      //Share to watcher
      watcher: 'Surveillant',
      availableWatchers: 'Surveillants Disponibles',
      watcherExpress:
        "* Les Surveillants peuvent consulter vos données via l'URL ci-dessous, mais ne peuvent pas modifier les paramètres de votre compte.",
      createWatcher: 'Créer un Nouveau Surveillant',
      modifyWatcher: 'Modifier',
      authoritySettings: "Paramètres d'Autorisation",
      setWatcherName: 'Définir un nom pour ce Surveillant',
      watcherName: 'Nom de Surveillant',
      watcherNameTip: '2-20 caractères',
      notEmptyWatcherNote: 'Le nom du Surveillant ne peut pas être vide',
      tips: 'Conseils:',
      tip_dashboard:
        "1. Le « tableau de bord » n'inclut pas les informations sur les gains telles que « non payé » et « payé » ;",
      tip_earning:
        '2. Les « gains » comprennent des informations telles que les « non payés » et « payés » de chaque adresse.',
      moreSettings: 'Plus de Paramètres',
      guardianRemark:
        'Vous pouvez partager tout (ou une partie) des informations de vos sous-comptes (y compris le taux de hachage, les mineurs) avec un autre utilisateur qui ne peut que voir, sans pouvoir modifier, les paramètres.',
      guardian: 'Partage de Compte',
      shareAccountsSetting: 'Part de compte',
      accessKey: "Clé d'Accès",
      generateAK: "Générer une Clé d'Accès",
      puid: 'PUID',
      apiDoc: 'Document API',
      confirmDeleteWatcher: 'Confirmer et supprimer le Surveillant?',
      watcherAuths_Dashboard: 'Tableau de Bord',
      watcherAuths_Dashboard_Tip: '(Hors Revenus)',
      watcherAuths_Miners: 'Mineurs',
      watcherAuths_Earnings: 'Revenus',
      watcherAuths_GetCoin: 'Cadeau',

      // Alert setting
      alertItem: "Objet d'Alerte",
      alertContacts: "Contacts d'Alerte",
      hashrateAlert: 'Alerte de Taux de Hachage',
      minerAlert: 'Alerte de Mineur',
      alertFrequency: "Fréquence d'Alerte",
      alertFrequencyTip:
        'Pour des raisons politiques, la fréquence des alertes SMS dans certains pays et régions est fixée à une fois toutes les 24 heures. Ceci est notifié par la présente.',
      warnMeWhenHashrate: "Ligne d'alerte de taux de hachage ≤",
      warnMeWhenActiveMiner: "M'avertir lorsque les mineurs actifs ≤",
      dontWarnMeTwice: "Ne pas m'avertir deux fois en ",
      hours: 'Heures',
      apply: 'Appliquer',
      operateFailed: ' Opération échouée!',
      contactsList: "Contacts d'Alerte",
      cancel: 'Annuler',
      ok: 'OK',
      save: 'Enregistrer',
      reset: 'Réinitialiser',
      newContact: 'Ajouter un nouveau contact',
      editContact: 'Modifier le contact',
      deleteContact: 'Supprimer le contact',
      confirmDeleteContact: "Confirmer et supprimer le contact d'alerte?",
      name: 'Nom',
      phone: 'Téléphone',
      email: 'E-mail',
      validateAlert: {
        nameEmpty: 'Le nom ne peut pas être vide',
        phoneRegionFormat: 'Code de région invalide',
        phoneFormat: 'Numéro de téléphone invalide',
        emailFormat: 'E-mail invalide',
        contactEmpty:
          "Le champ e-mail est obligatoire lorsque le téléphone n'est pas présent.",
      },
      max_email_contacts:
        "Prise en charge d'un maximum de 10 contacts d'e-mail",
      max_phone_contacts:
        "Prise en charge d'un maximum de 5 contacts de téléphone",
      enabled_alert_need_hashrate:
        "Aucune puissance de hachage détectée, impossible d'activer l'alerte.",
      alertItemUpdateSuccess: "Objet d'alerte mis à jour",

      done: 'Terminé',

      Okay: 'OK',

      invalidAddress: 'Adresse invalide',
      emptyCode: 'Ne peux pas être vide',
      wrongCode: 'Code erroné',
      emailVia: 'Envoyez-moi un e-mail via',
      textVia: 'Envoyez-moi un message via',
      help: 'Aide',

      contact: 'Contact:',
      close: 'Fermer',
      receiveAddress: 'Adresse:',

      updateAddress: "Date de mise à jour de l'adresse:",
      // pay with btc
      payWithBtcTip:
        "* Une fois la conversion de devise activée, votre profit minier sera converti en Bitcoin à une heure fixe chaque jour et payé à l'adresse de BTC individuelle suivante. Normalement, ce sera à 4h (UTC), et vous serez notifié en cas de circonstances spéciales.",
      convertedCurrency: 'Devise Convertie: ',
      setBtcAddressTip: 'Veuillez définir une adresse de BTC',
      btcAddress: 'Adresse de BTC: ',
      conversionRate: 'Taux de Conversion de Référence',
      convert: 'Convertir',
      getValue: 'Vous obtiendrez ',
      conversionTip:
        '* Le taux de conversion est à titre indicatif uniquement, sous réserve du résultat de conversion réel.',
      lastUpdate: 'Dernières mise à jour: ',
      on: 'Activé',
      off: 'Avis',
      turnOnPaywith: 'Voulez-vous vraiment activer le paiement avec BTC?',
      turnOffPaywith: 'Voulez-vous vraiment désactiver le paiement avec BTC?',
      payOn: 'Activer',
      payOff: 'Désactiver',
      resend: 'Envoyer à Nouveau',
      send: 'Envoyer le Code',
      resendTip:
        'Si vous ne recevez pas le code, veuillez patienter un moment ou réessayer plusieurs fois.',

      // 一键切换 One Switch
      oneSwitch: {
        attention: 'Attention',
        optionCurrentMode: 'Veuillez sélectionner le mode',
        switch:
          'Vous êtes sur le point de basculer votre taux de hachage en {destMode}',
        switchAnotherMining: 'Vous basculez en minage {destMode}.',
        hashsHaveSwitched:
          'Les hachages de minage ont tous basculé dans le pool {destMode}.',
        ethSwitchWarning:
          "Attention: si votre machine de minage ne prend pas en charge le changement de DAG. Cela entraînera des revenus nuls. Il faut redémarrer le mineur manuellement pour revenir à la normale. (Il est connu que l'AntMiner E3 ne le prend pas en charge. Le passage d'ETC à ETH nécessite un redémarrage manuel).",
        ethSwitchWarningLinkTxt: 'Détails.',
        checkSubAccount:
          'Veuillez vérifier votre sous-compte {subAccountName}- {subAccountRegionNode}',
        enter: 'Entrer',
      },
      // 机枪收款地址
      smartAddress: {
        // autoSwapSettlement: 'Règlement d'Échange Auto',
        original: "Règlement en devise d'origine",
        autoTip:
          'Les devises générées par le Pool Intelligent seront réglées quotidiennement dans la devise cible.',
        originalTip:
          'Le règlement du Pool Intelligent sera réglé en BTC, BCH et BSV pris en charge par le Pool Intelligent.',
        originalCurrency: "Devise d'Origine",
        targetCurrency: 'Devise Cible',
        earningsInSmartPool: 'Revenu dans le Pool Intelligent',
        activated: 'Activé',
        unactivated: 'Non Activé',
        modifyTip:
          "La devise cible du règlement de l'échange auto du Pool Intelligent est associée et cohérente avec l'adresse de portefeuille de la même devise sous le même sous-compte.",
        autoHistory:
          "Échanger automatiquement les revenus de l'historique en BTC ou non?",
        notice1:
          'Pour des raisons de sécurité, vos revenus seront conservés sous forme de solde pendant 48 heures.',
        notice2:
          "Les revenus du Pool Intelligent seront automatiquement échangés dans la devise cible après l'activation de l'échange auto. Cliquez sur OK sera considéré comme une acceptation de {fundLink}.",
        notice3:
          "L'adresse dans le mode intelligent est associée et cohérente avec l'adresse de pièce unique sous le même sous-compte (Si plusieurs adresses sont définies, elle sera cohérente avec la première adresse)",
        originalNotice1:
          "L'adresse dans le mode intelligent est associée et cohérente avec l'adresse de pièce unique sous le même sous-compte (Si plusieurs adresses sont définies, elle sera cohérente avec la première adresse)",
        currentPayoutMode: 'Mode de paiement actuel: {mode}',
        fundLink: `Contrat de Licence de l'Échange Auto de devises CloverPool`,
        originBtn: "Activer le règlement en devise d'origine",
        otcBtn: 'Activer le règlement par échange auto',
        otcSuccess:
          'Le règlement par échange auto du mode Pool Intelligent est activé et nous effectuerons le paiement avec BTC.',
        otcModalNotice2: `Avec l'activation de l'échange auto des revenus de l'historique, les revenus précédents du compte seront échangés. Sans cela, seuls les revenus récents seront échangés.`,
        originSuccess:
          "Le règlement en devise d'origine du mode Pool Intelligent est activé et nous effectuerons le paiement avec la devise d'origine extraite par taux de hachage.",
        // 2021-06-04新增
        mode_otc: "Règlement d'Échange Auto",
        mode_origin: "Règlement en devise d'origine",
        settlementMode: 'Mode de Règlement',
        settlementModeDescription: 'Description de Règlement',
        originDescription:
          "Règlement en devise d'origine: règlement dans la devise minière réelle, la fourchette de devises est BTC, BCH et BSV.",
        autoSwapDescription:
          "Règlement par échange auto: effectue d'abord l'extraction dynamique de BTC/BCH/BSV selon l'algorithme, puis les convertit en BTC pour le règlement.",
        settlementSwitch: 'Interrupteur de Règlement',
        originalSettlement: "Règlement en devise d'origine",
        autoSwapSettlement: "Règlement d'Échange Auto",
        currency: 'Devise',
        address: 'Adresse',
        addressRules: "Règles d'adresse",
        switchAddress: "Changer d'adresse",
        noAddressTip:
          "Le {coin}  du même sous-compte n'a pas d'adresse ajoutée",
        noAddressSelected: 'Aucune adresse sélectionnée',
        selectFrom:
          'Sélectionnez dans la liste des BTC adressés du même sous-compte',
        settingTip:
          "Conseil: si vous souhaitez distinguer l'Adresse du Pool Intelligent de l'Adresse de la devise unique du même sous-compte, veuillez ajouter une nouvelle adresse dans la gestion des adresses de devise unique et définir la proportion à 0%, puis revenez ici et basculez à la nouvelle adresse.",
      },
    },
    homepage: {
      btcSmartAgent: 'BTC Smart Agent',
      agentExpress: 'Efficace et Transparent Travailleur Unique Visible',
      bit: 'bit',
      bit64: 'Win7 ou supérieur 64 bits',
      bit32: 'WinXP 32 bits',
      document: "Document d'Aide Windows.",
      accessLinuxVersion: 'Accès pour la Version Linux',
      cuttingEdgeTechnology: 'Technologie de Pointe',
      latestArchitecture: 'Architecture Récente & Originale',
      openSourceCode: 'Code Source Ouvert',
      highTestPressure: 'Test de Tension Ultra-Élevée',
      lowOrphanedRate: 'Taux de Bloc Orphelin Ultra-Faible',
      transparentAgent: 'Agent Transparent',
      standardsServices: 'Nouvelles Normes pour les Services Miniers',
      customerHotline: 'Hotline Client',
      highHashrateConsultation: 'Consultation à Taux de Hachage Élevé',
      professionalCustomerService: 'Service Client Professionnel',
      bestVipServer: 'Serveur VIP avec Spécifications Ultra-Élevées',
      oneToOne: 'Analyse de Cas Individuelle',
      appAvailable:
        "L'Application CloverPool Prend en Charge la Gestion Facile des Mineurs",
      earningsTimelyEveryday: 'Recevez des Revenus Tous les Jours',
      transnationalTeamOperation: "Opération d'Équipe Internationale",
      currencies: 'Devises',
      dailyEarnings: 'Revenus Quotidiens',
      hashrate: 'Taux de Hachage',
      hashrateChart: 'Tableau de Taux de Hachage',
      explorer: ' Explorateurr',
      prices: 'Prix',
      earningsModels: 'Modèles de Revenus',
      minPayment: 'Seuil de Paiement',
      help: 'Aide',
      notePaytime: "Tous les jours de 0h00 à 8h00 (UTC) payer les gains d'hier",
      yuanKwhElectric: "USD/kW·h (Prix d'Électricité) ",
      todayEarnings: 'Revenus du Jour:',
      piece: 'Pièce',
      signIn: 'Connexion',
      signUp: 'Inscription',
      power: 'Puissance',
      hashrateProfitEst: 'Estimation de Profit de Taux de Hachage',
      hashrateProfitEstTips:
        'There may be a deviation from your actual earnings, which is for reference only.',
      miningProfitEst: 'Estimation de Profit de Minage',
      fee: 'Frais',
      desc: `Le premier pool minier de services complets multidevises au monde`,
      tutorial: 'Tutoriel',
      diff: 'Difficulté',
      nextDifficultyEstimated: 'Prochaine Difficulté Estimée',
      dateToNextDifficulty: 'Date de Prochaine Difficulté',
      Algorithm: 'Algorithme',
      backup: 'Backup',
      whyMe: {
        title: 'Pourquoi choisir CloverPool(formerly BTC.com)?',
        leadingTechnology: 'Technologie de Pointe',
        convenientManagement: 'Gestion Pratique',
        revenueAssurance: 'Assurance de Revenu',
        professionalServices: 'Services Professionnels',
        desc1: `Code open source, logiciel de gestion gratuit des mineurs et des fermes minières, le firmware officiel d'overclocking personnalisé augmente considérablement les revenus, données de la chaîne de blocs et fournisseur de services techniques.`,
        desc2:
          "Fonctionnement concis de l'app et de la page Web, inscription de compte facile avec numéro de téléphone portable ou e-mail, fonction d'alarme indépendante, adresse de revenu, regroupement de mineurs et lien de Surveillant pour le partage de données.",
        desc3: `Données minières réelles et transparentes, modèle de revenus FPPS avancé, centre de règlement puissant, paiement ponctuel, en tout temps.`,
        desc4: `CloverPool(formerly BTC.com) dispose d'une équipe de service client professionnelle, du plus haut niveau de services VIP personnalisés et des meilleurs chercheurs qui analysent les cas de personne à personne dans l'industrie.`,
      },
      app: {
        title: '(formerly BTC.com) App',
        description: `Le premier pool minier intégré multidevises au monde`,
        property1: `Prend en charge le minage multi-devises, la gestion de plusieurs sous-comptes, 
  et la gestion facile du partage de lien de Surveillant`,
        property2: `Affichage en temps réel des données monétaires complètes,
     et compréhension plus rapide des tendances minières`,
        property3:
          'Nouvelle interface interactive pour une expérience utilisateur plus fluide',
      },
      coinList: {
        eth: {
          earningsModelsTip: {
            FPPS: `Le mode de règlement des ETH est le mode double FPPS/PPS+ et le mode actuel est le mode FPPS. Nous l'ajusterons en fonction de facteurs complets, tels que les coûts de transaction en chaîne, la part de marché de CloverPool, les conditions du marché et les revenus de l'utilisateur.`,
            'PPS+': `Le mode de règlement des ETH est le mode double FPPS/PPS+ et le mode actuel est le mode PPS+. Nous l'ajusterons en fonction de facteurs complets, tels que les coûts de transaction en chaîne, la part de marché de CloverPool, les conditions du marché et les revenus de l'utilisateur.`,
          },
        },
        ltc: {
          giftCoin: '+DOGE,BEL',
        },
      },
      globalZone: 'Global',
      zilDiff1: 'Shard',
      zilDiff2: 'DS',
      etcMinerAddressTips: `L'équipement qui exploite uniquement ETC se connecte à cette adresse minière`,
      zilMinerAddressTips: `L'équipement minier double ETC et ZIL est connecté à cette adresse minière`,
    },
    advanceAccount: {
      title: 'Gestion  de Compte - CloverPool',
      all: 'TOUT',
      btc: 'BTC',
      bch: 'BCH',
      sbtc: 'SBTC',
      ubtc: 'UBTC',
      dcr: 'DCR',
      ltc: 'LTC',
      grin: 'Grin',
      beam: 'BEAM',
      unit: 'Unité',
      subAccount: 'Sous-Compte',
      coin: 'Pièce',
      hashrate: 'Taux de Hachage',
      guard: 'Remarque',
      allMiners: 'Tous les Mineurs',
      activeMiners: 'Actif',
      inactiveMiners: 'Inactif',
      deadMiners: 'Mort',
      region: 'Région',
      more: 'Plus',
      setting: 'Paramètres',
      totalHashrate: 'Taux de Hachage Total',
      search: 'Recherche',
      status: 'État',
      hidden: '已隐藏',
      shareTome: '共享给我的',
      hide: '隐藏',
      recover: '恢复',
      delete: '删除',
      refersh: '刷新',
      export: '导出',
      name: '名称',
      note: '备注',
      manageShareAccount: '管理共享子账户',
    },
    getCoin: {
      tip: {
        btc: 'Dès à présent, vous pouvez obtenir gratuitement des Namecoin (NMC), des Elastos (ELA) si vous minez des Bitcoin ou des Bitcoin Cash dans le pool de minage CloverPool.',
        bch: 'Dès à présent, vous pouvez obtenir gratuitement des Namecoin (NMC), des Elastos (ELA) si vous minez des Bitcoin ou des Bitcoin Cash dans le pool de minage CloverPool.',
        ltc: 'Dès à présent, vous pouvez obtenir gratuitement des Dogecoin (PPLNS) si vous minez des Litecoin dans le pool de minage CloverPool.',
        bcha: `<p>Le  fork fort de BitcoinCash (BCH) a divisé le réseau Bitcoin Cash en deux nouvelles chaînes de bloc, BCHA est une nouvelle pièce. Pour plus d'informations, veuillez visiter <a href="https://www.bitcoinabc.org/" target="__blank">https://www.bitcoinabc.org/</a></p>
          <p><b>Période de retrait: </b><br/>07/12/2020, 0h, à 07/01/2021, 0h (UTC)</p>`,
      },
      beta: 'En bêta fermée',
      whatsThis: "Qu'est-ce que c'est?",
      address: 'Adresse:',
      newAddress: 'Nouvelle Adresse',
      addressComplete:
        'Pour protéger vos revenus, veuillez envoyer votre adresse pour recevoir le jeton gratuit dès que possible. ',
      bchaAddressComplete:
        "Après le 7 janvier 2021, les utilisateurs qui ne remplissent pas l'adresse seront effacés de BCHA et ne retireront pas. Pour protéger vos revenus, veuillez envoyer votre adresse pour recevoir le jeton gratuit dès que possible. ",
    },
    watcher: {
      expired: 'Autorité de Surveillant expirée',
      incorrect: 'Erreur de lien de Surveillant',
    },
    activity: {
      pointsToday: 'Points de Jour',
      pointsSum: 'Total',
      ranking: 'Classement',
      earnings: 'Revenus',
    },
    minerRanking: {
      title: 'Estimation de Profit de Minage',
      electric: 'Charge/Jour',
      dailyEarning: 'Profit Quotidien',
      priceShutdown: "Prix d'Arrêt",
      ALL: 'TOUT',
      CNY: 'CNY',
      USD: 'USD',
      desc: 'Profit Décroissant',
      asc: 'Profit Croissant',
      electricPercent: "Ratio de Frais d'Électricité",
      searchTip: 'Saisir un Mineur',
      updated: 'Mis à jour: ',
      coin: 'Pièces',
      emptyShow: ' Aucun mineur éligible',
      rank: 'Rang',
      miner: 'Mineur',
      power: 'Puissance',
      hashrate: 'Taux de Hachage',
      rev24h: 'Rev. 24h',
      energy: "Frais d'Électricité",
      incomeWithdoge: 'Inclusive of DOGE',
      incomeWithdogeAndBel: 'Inclusive of DOGE+BEL',
    },
  },
};
const pageMessageFlatten = flatten(pageMessage);
export default pageMessageFlatten;
