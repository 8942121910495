import appLocaleData from 'react-intl/locale-data/zh';
import common from './zh-CN.common';
import pages from './zh-CN.pages';
import pagination from './zh-CN.pagination';
import invite from './zh-CN.invite';

window.appLocale = {
  // 合并所有 messages, 加入组件的 messages
  messages: Object.assign({}, common, pages, pagination, invite),

  // locale
  locale: 'zh-CN',
  // react-intl locale-data
  data: appLocaleData,
};

export default window.appLocale;
