import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Checkbox, Tooltip } from 'antd';
import { BtcButton, SearchInput } from 'components/widget';
import SvgIcon from 'components/SvgIcon';
import styles from '../index.module.scss';

const AccountListPanel = ({
  showShadow,
  togglePopup,
  onSearch,
  searchKeyword,
  zeroHashrateChecked,
  onChangeZeroHashrate,
  onCreateSubAccount,
}) => {
  const { t } = useTranslation('account');

  return (
    <div className={`${styles.panel} ${showShadow ? styles.shadow : ''}`}>
      <div className="flex justify-between">
        <h2>{t('panel.Sub-account List')}</h2>
        <div>
          <Link to="/advance-account">
            <Tooltip title={t('panel.Manage Sub-accounts')}>
              <BtcButton variant="simple" onClick={() => togglePopup(false)}>
                <SvgIcon iconName="icon_subaccount_setting" size="sm" />
              </BtcButton>
            </Tooltip>
          </Link>
          <BtcButton
            variant="simple"
            style={{ marginLeft: 6, marginRight: 6 }}
            onClick={onCreateSubAccount}
          >
            <SvgIcon iconName="icon_subaccount_add" size="sm" />
          </BtcButton>
        </div>
      </div>
      <div
        className="flex justify-between items-center"
        style={{ marginTop: 6 }}
      >
        <div style={{ width: 320 }}>
          <SearchInput
            onSearch={onSearch}
            onChange={onSearch}
            value={searchKeyword}
          />
        </div>
        <Checkbox
          defaultChecked
          checked={zeroHashrateChecked}
          onChange={(e) => onChangeZeroHashrate(e.target.checked)}
        >
          {t('panel.0 Hashrate')}
        </Checkbox>
      </div>
    </div>
  );
};
export default AccountListPanel;
