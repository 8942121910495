import { runInAction, makeAutoObservable } from 'mobx';
import axios from 'axios';
import { defaultListPage } from 'common/config';

class InviteEarningStore {
  loadingStats = false;

  rebateData = {
    paid: '',
    unpaid: '',
  };

  loadingList = false;

  earningList = {};

  listTotal = 0;

  listPage = defaultListPage;

  constructor() {
    makeAutoObservable(this); //升级mobx 6之后禁止使用装饰器，然后需要使用makeAutoObservable
  }

  getInviteEarningList = async (puid) => {
    this.loadingList = true;
    let url = `/invite/rebateHistory`;

    const res = await axios.get(url, {
      params: {
        ...this.listPage,
        // reason: 1,
        // is_decimal: 1,
        puid,
      },
    });
    if (res && res.data) {
      runInAction(() => {
        this.earningList = res.data;
        this.loadingList = false;
      });
    }
  };

  getInviterebateData = async (puid) => {
    this.loadingStats = true;
    let url = `/invite/rebateData`;
    const res = await axios.get(url, {
      params: {
        puid,
      },
    });
    if (res && res.data) {
      runInAction(() => {
        this.rebateData = res.data;
        this.loadingStats = false;
      });
    }
  };

  setListPage = (page, page_size) => {
    this.listPage = {
      page,
      page_size,
    };
  };

  resetListPage = () => {
    this.listPage = defaultListPage;
  };
}

const inviteEarningStore = new InviteEarningStore();

export { inviteEarningStore };
