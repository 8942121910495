import flatten from 'flat';
const commonMessage = {
  common: {
    locale: '簡體中文',
    header: {
      home: '礦池首頁',
      dashboard: '用戶面板',
      getCoin: '挖礦送幣',
      help: '幫助',
      miners: '礦機',
      poolStats: '統計',
      commissionEarnings: '返佣收益',
      miningEarnings: '挖礦收益',
      service: '客服',
      tools: '工具',
      setting: '設置',
      guardian: '共享',
      guardianPermission:
        '您僅享有賬戶數據的查看權限，但無法進行任何編輯操作。',
      watcher: '觀察者',
      watcherPermission:
        '觀察者享有子賬戶數據的查看權限，但無法進行任何編輯操作。',
      userCenter: '用戶中心',
      SignOut: '退出',
      accountSetting: '賬號設置',
      poolManagePanel: '礦池管理面板',
      watcherMode: '觀察者模式',
      scanToService: '掃碼獲取幫助',
      bitdeer: 'Bitdeer專場',
      hashnest: '算力巢',
      app: 'App',
      explorer: '瀏覽器',
      getBCHA: '領取BCHA',
      candy: '糖果',
      alertBar:
        '<b>在8月10日 22:00 - 24:00(UTC+8)</b>， CloverPool將進行大規模的數據維護，一些功能將無法使用，挖礦服務不受影響。<a href="https://help.cloverpool.com/hc/zh-cn/articles/900002124306" rel="nofollow noopener noreferrer" target="_blank">詳情請點擊</a>',
      introTip:
        '用戶面板、收益、挖礦送幣、子賬號設置等功能統一集中在「礦池管理面板」中。',
    },
    nav: {
      'nav-explorer': '瀏覽器',
      'nav-explorer-btc': 'Bitcoin',
      'nav-explorer-eth': 'Ethereum',
      'nav-explorer-bch': 'Bitcoin Cash',
      'nav-explorer-etc': 'Ethereum Classic',
      'nav-explorer-ltc': 'Litecoin',
      'nav-pool': '礦池服務',
      'nav-pool-home': '礦池首頁',
      'nav-pool-data': '礦池數據',
      'nav-pool-candy': '糖果社區',
      'nav-pool-tools': '工具軟件',
      'nav-pool-cs': '聯繫客服',
      'nav-wallet': '錢包',
      'nav-help': '幫助中心',
      'nav-navigation': '區塊鏈導航',
      'nav-inscriptions': 'Ordinals銘刻',
      'nav-app': 'APP',
      'nav-collaborate': '合作專場',
      'nav-brc20': 'BRC-20',
      'nav-tools': '工具',
      'nav-tools-address-monitoring': '地址监控',
      'nav-tools-address-detail': '地址明细',
      'nav-tools-address-report': '地址报表',
      'nav-tools-address-increaseminerfee': '增加矿工费',
      'nav-tools-address-txreverse': '交易撤销',
      'nav-tools-address-doublespend': '交易双花',
      'nav-tools-APIService': 'API服务',
      'nav-tools-accelerate': '交易加速(CPFP)',
      'nav-tools-acceleratebatch': '批量加速(CPFP)',
      'nav-tools-TransactionBroadcast': '交易广播',
      'nav-tools-special': '稀有聪查询',
      'nav-tools-receipt': '交易收据',
      'nav-tools-pickupsats': '稀有聪提取',
      'nav-tools-bulkpayment': '批量付款',
      'nav-tools-address-graph': '地址图谱',
      'nav-runes': 'Runes铸造',
      'nav-runes-explorer': 'Runes浏览器',
      'nav-runes-main': 'Runes',
      tags: {
        'runes-free': '限时免费+送GOODS',
        new: 'New',
      },
    },
    menu: {},
    setting: {
      balance: '當前餘額',
      coinAddress: '{coinType} 收款地址',
      setAddress: '請點擊設置{coinType}收款地址',
      multAddressEnabled: '已設置為多個地址按比例收款',
      menu: {
        hide: '隱藏',
        address: '收款地址',
        minPay: '最小起付金額',
        payWithBtc: '以BTC結算',
        earingsHistory: '收益',
        shareData: '共享賬戶',
        watcher: '觀察者設置',
        apikey: 'API KEY',
        alertSettings: '警報設置',
        onebuttonSwitch: '一鍵切換',
        userCenter: '用戶中心',
        signOut: '退出登錄',
        delete: '刪除',
      },
      confirm: '確認',
      cacel: '取消',
      deleteConfirm: '刪除確認',
      cannotDelete: '無法刪除該子賬戶',
      cannotDeleteView: '不可刪除當前正在查看的子賬戶',
      deleteCondition:
        '無法刪除該子賬戶<span class="subAccountName">{{accountMessage}}</span>,僅可刪除同時滿足以下兩個條件的子賬戶：',
      cannotDeleteReason:
        '1、該子賬戶近180天的算力為0；<br/>2、該子賬戶(包括贈送幣)的未支付金額為0；<br/>3、該子賬號不屬於平台生成的各類專屬子賬號。',

      deleteName: '您正在刪除子賬戶 :',
      deleteInfo:
        '* 點擊「確定」後，該子賬戶將被永久刪除，無法找回；<br/>* 刪除後，該子賬戶的算力曲線、收益記錄等數據將無法找回。',
      deleteSuccess: '子賬戶刪除成功',
    },
    footer: {
      services: '提供服務',
      Products: 'CloverPool產品',
      Help: '幫助中心',
      btcTool: '批量工具',
      btcSmartAgent: '智能代理',
      btcComFirmware: '定製版固件',
      blockchainExplorer: '瀏覽器',
      App: 'App',
      apiDocs: 'API文檔',
      vipApplication: 'VIP申請',
      cloudMinePoolService: '雲礦池搭建服務',
      miningTutorial: '挖礦教程',
      FAQ: '常見問題',
      Announcements: '歷史公告',
      ticketSystem: '工單系統',
    },
    pageTitle: {
      home: 'CloverPool(原BTC.com) - 全球領先的綜合多幣種礦池',
      dashboard: '用戶面板 - CloverPool(原BTC.com)',
      miners: '礦機管理 - CloverPool(原BTC.com)',
      earningHistory: '收益歷史 - CloverPool(原BTC.com)',
      earningHistoryForInvite: '返佣收益 - CloverPool(原BTC.com)',
      poolStats: '礦池數據 - CloverPool(原BTC.com)',
      guardian: '守護者邀請鏈接 - CloverPool(原BTC.com)',
      guardianBind: '綁定守護者邀請鏈接 - CloverPool(原BTC.com)',
      subAccount: '創建子賬戶 - CloverPool(原BTC.com)',
      subAccountManage: '子賬戶管理 - CloverPool(原BTC.com)',
      settings: '設置 - CloverPool(原BTC.com)',
      getCoin: '挖礦送幣 - CloverPool(原BTC.com)',
      tools: '工具軟件 - CloverPool(原BTC.com)',
      bitdeer: 'Bitdeer專場 - CloverPool(原BTC.com)',
      watcherExpired: '觀察者權限已過期 - CloverPool(原BTC.com)',
      watcherIncorrect: '觀察者鏈接錯誤 - CloverPool(原BTC.com)',
      fundAuthorization: '資金兌換授權協議 - CloverPool(原BTC.com)',
      app: 'App下載 - CloverPool(原BTC.com)',
      invite: '邀請獎勵 - CloverPool(原BTC.com)',
    },
    breadCrumbs: {
      home: '礦池首頁',
      dashboard: '用戶面板',
      miners: '礦機',
      earningHistory: '收益記錄',
      poolStats: '礦池數據',
      shareAccount: '共享賬戶設置',
      guardianBindingInvitation: '綁定守護者邀請鏈接',
      guardianBindingLink: '綁定守護者鏈接',
      subAccountManage: '子賬戶管理 ',
      hiddenSubAccounts: '隱藏子賬戶管理',
      settings: '設置',
      getCoin: '挖礦送幣',
      tools: '工具軟件',
      fundAuthorization: '資金兌換授權協議',
    },
    currency: {
      currency: 'USD',
    },
    allSwitchMode: {
      smart_sha256: '機槍',
      otcNotice: `您即將切換算到「機槍模式」，機槍模式不支持同一幣種下多地址收款，點擊」確定」視為您閱讀並同意{fundLink}`,
      fundLink: `《CloverPool機槍池服務協議》`,
      autoTip: `使用機槍模式，將意味着把算力託管給CloverPool，礦池將會在BTC、BCH、BSV幣種中以收益最優為原則進行切換。`,
      originalTip:
        '切換為原幣模式，SHA256算力將單獨挖所選幣種，不會進行自動切換。',
    },
    users: {
      userCenter: '用戶中心',
      SignOut: '退出登錄',
    },
    operation: {
      name: '操作',
      success: '操作成功!',
      failed: '操作失敗!',
    },
    earning: {
      balanceTip:
        'CloverPool結算中心在結算完成後，一般會在24小時內完成支付，以下幾種情況除外：<br>1.未設置地址；<br>2.設置了地址但累計結算金額未達到最小起付額；<br>3.新增地址、修改地址、刪除地址或修改比例等操作觸發了48小時暫停打款規則；<br>4.觸發了平颱風控規則；<br>5.該數字貨幣發生硬分叉、遭遇51%攻擊或其他重大升級或事故。',
    },
    meta: {
      keywords:
        '比特币,矿池,挖矿,BTC,BCH,LTC,ETC,Kaspa,鱼池,蚁池,via,viabtc,f2pool,antpool,foundry,binance,luxor,poolin,pool,蚂蚁,矿机,cloverpool,bitdeer,bitmain,工具,铭刻,brc-20,runes',
      description:
        'CloverPool是礦工的全新選擇，支援比特幣、萊特幣、比特幣現金、Kaspa 和其他加密貨幣的挖礦。使用者體驗更好、費用更低、服務更強大。',
    },
    gotIt: '我知道了',
    foldMe: '全部摺疊',
    showAll: '全部展開',
    copied: '已複製',
    more: '更多',
    none: '無',
    on: '開啟',
    off: '關閉',
    all: '全部',
    modify: '修改',
    submit: '提交',
    ok: '確認',
    cancel: '取消',
    new: '新建',
    address: '地址',
    notFoundTip: '對不起，您當前訪問的頁面不存在',
    export: '導出',
    startDate: '開始日期',
    endDate: '結束日期',
    smartPool: '機槍',
    status: '狀態',
    notice: '注意',
    yes: '是',
    no: '否',
    coin: '幣種',
    custom: '自定義',
    hide: '隱藏',
    less: '收起',
    details: '詳情',
    region: {
      cn: '北方聯通',
      sz: '南方電信',
      us: '美國',
      eu: '歐洲',
    },
    LegalStatement:
      '本人在此確認並申明：本人非古巴、伊朗、朝鮮、敘利亞、俄羅斯以及其他受到相關國家或政府或國際機構管理或執行的制裁的國家或地區居民，且本人非中國大陸地區居民。CloverPool提供的礦池服務在本人所在的國家或地區是符合法律法規和相關政策的。',
    LegalStatementConfirm:
      '由於本人所在的國家或地區使用CloverPool提供的服務系違法導致的所有法律風險和責任將完全由本人獨立承擔。',
  },
};
const commonMessageFlatten = flatten(commonMessage);
export default commonMessageFlatten;
