import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Tooltip } from 'antd';
import { BtcButton } from 'components/widget';
import SvgIcon from 'components/SvgIcon';
import { useStores } from 'stores';
import AccountCoinItem from './AccountCoinItem';
import styles from '../index.module.scss';
import { coinsConfig } from 'common/config';

function isSha256(algorithm) {
  return algorithm === 'sha256';
}
/**
    subAccountName={item.name} //子账户名
    regionText={item.region_text} // 区域名称
    coinSubAccounts={item.algorithms} //最小单位的子账户（子账户-区域-单个币种）
    supportOtherCoin={item.have_other_coins} // 是否可以添加其他币种
    supportSwitch // 是否支持一键切换，如果当前子账户有某个币种的算法支持多个币种，support_coins.length>0
    scrollIfActive  // 如果是当前子账户将当前子账户传给父组件来进行定位
 */
const AccountRegionGroup = observer(
  ({
    subAccountName,
    regionText,
    coinSubAccounts,
    supportOtherCoin,
    supportSwitch,
    scrollIfActive,
    afterChangeAccount,
    onClickSwitch,
    onClickAddOtherSubAccount,
  }) => {
    const { t } = useTranslation('account');

    const { userStore } = useStores();
    const { accountInfo } = userStore;

    const isEqualCurrentPuid = useCallback(
      (puid) => {
        return puid?.toString() === accountInfo.puid?.toString();
      },
      [accountInfo.puid],
    );

    const isCurrentActiveAccount = useMemo(() => {
      return coinSubAccounts.some((item) => isEqualCurrentPuid(item.puid));
    }, [coinSubAccounts, isEqualCurrentPuid]);

    const ref = useRef(null);
    useEffect(() => {
      if (isCurrentActiveAccount) {
        scrollIfActive?.(ref.current);
      }
    }, [isCurrentActiveAccount]);

    const isGlobalZone = useMemo(() => {
      return (
        coinSubAccounts.length === 1 &&
        coinSubAccounts.some((item) => {
          return coinsConfig[item.coin_type.toLowerCase()]?.isGlobalZone;
        })
      );
    }, [coinSubAccounts]);

    return (
      <div
        className={`${styles.group} ${
          isCurrentActiveAccount ? styles.active : ''
        }`}
        ref={ref}
        key={`${subAccountName} - ${
          isGlobalZone ? t('globalZone') : regionText
        }`}
      >
        <div className={styles.header}>
          <h3>{`${subAccountName} - ${
            isGlobalZone ? t('globalZone') : regionText
          }`}</h3>
          {supportSwitch && (
            <div>
              <Tooltip title={t('panel.One-button Switch')}>
                <BtcButton
                  type="link"
                  variant="secondary-link"
                  onClick={onClickSwitch}
                >
                  <SvgIcon
                    iconName="icon_subaccount_switch"
                    size="sm"
                    className="margin-right-sm"
                  />
                  {t('panel.Switch')}
                </BtcButton>
              </Tooltip>
            </div>
          )}
        </div>
        <div>
          {coinSubAccounts.map((item, index) => {
            if (isGlobalZone) {
              item.region_text = t('account:globalZone');
              item.region_code = 'GL';
            }
            return (
              <AccountCoinItem
                key={item.puid}
                coin={item.coin_type}
                puid={item.puid}
                isCurrentLogin={isEqualCurrentPuid(item.puid)}
                // TODO 关于支持一键切换目前只有sha256的币种支持，如果以后有其他算法币种，需要修改为根据 algorithms_support_coins 来判断
                isSha256={isSha256(item.coin_algorithm)}
                isCurrentSha256={
                  isSha256(item.coin_algorithm) && item.is_current
                }
                isSiblingSha256={
                  isSha256(item.coin_algorithm) &&
                  (isSha256(coinSubAccounts[index + 1]?.coin_algorithm) ||
                    isSha256(coinSubAccounts[index - 1]?.coin_algorithm))
                }
                afterChangeAccount={afterChangeAccount}
                accountItemInfo={item}
              />
            );
          })}
        </div>
        {!isGlobalZone && supportOtherCoin > 0 && (
          <div className={styles.footer}>
            <BtcButton
              type="link"
              className="flex items-center"
              onClick={onClickAddOtherSubAccount}
            >
              <SvgIcon
                iconName="icon_add_sm"
                size="xxs"
                className="margin-right-sm"
              />
              {t('panel.Add other cryptocurrency')}
            </BtcButton>
          </div>
        )}
      </div>
    );
  },
);
export default React.memo(AccountRegionGroup);
