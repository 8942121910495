import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { IntlProvider, addLocaleData } from 'react-intl';
/**
 * 获取国际化资源文件
 *
 * @param {any} lang
 * @returns
 */
function getLocale(lang) {
  /* eslint-disable global-require */
  let result = {};
  switch (lang) {
    case 'zh-CN':
      result = require('../../locales/zh/zh-CN');
      break;
    case 'en':
      result = require('../../locales/en/en-US');
      break;
    case 'ru':
      result = require('../../locales/ru/ru-RU');
      break;
    case 'es':
      result = require('../../locales/es/es-ES');
      break;
    case 'zh-HK':
      result = require('../../locales/zh-HK/zh-HK');
      break;
    case 'de':
      result = require('../../locales/de/de');
      break;
    case 'fr':
      result = require('../../locales/fr/fr');
      break;
    case 'ko':
      result = require('../../locales/ko/ko');
      break;
    case 'ja':
      result = require('../../locales/ja/ja');
      break;
    case 'tr':
      result = require('../../locales/tr/tr');
      break;
    default:
      result = require('../../locales/en/en-US');
  }

  return result.default || result;
  /* eslint-enable global-require */
}

@inject('store')
@observer
class IntlProviderWrap extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store.appStore;
  }

  render() {
    const { lang } = this.store;

    const appLocale = getLocale(lang);
    addLocaleData(...appLocale.data);
    window.appLocale = appLocale;

    this.store.setAppLocale(appLocale);

    return (
      <IntlProvider
        key={appLocale.locale}
        locale={appLocale.locale}
        messages={appLocale.messages}
        // formats={appLocale.formats}
      >
        {this.props.children}
      </IntlProvider>
    );
  }
}

export default IntlProviderWrap;
