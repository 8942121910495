import React, { Component } from 'react';
import {
  FormattedMessage,
  FormattedDate,
  FormattedTime,
  FormattedRelative,
  FormattedNumber,
  FormattedPlural,
  FormattedHTMLMessage,
} from 'react-intl';

/**
 * 国际化，包括字符串，日期，时间，时间戳和当前时间关系
 * type: message(default)|date|time|relative
 */
class LocaleTranslator extends Component {
  /**
   *
   * 根据trans id 获取翻译后的纯字符串
   * @memberof LocaleTranslator
   */
  getLocaleString = (id) => {
    return window.appLocale.messages[id] || '';
  };

  render() {
    const {
      id,
      idPlural,
      values,
      pluralValue,
      defaultMessage = '',
      type = 'message',
      currency = 'USD',
      useGrouping = false,
      decimal,
    } = this.props;
    if (type === 'message') {
      return (
        <FormattedMessage
          tagName="span"
          id={id}
          values={values}
          defaultMessage={defaultMessage}
        />
      );
    } else if (type === 'html') {
      return (
        <FormattedHTMLMessage
          tagName="span"
          id={id}
          values={values}
          defaultMessage={defaultMessage}
        />
      );
    } else if (type === 'pluralMessage') {
      //only support one value param
      return (
        <FormattedPlural
          value={pluralValue}
          one={<FormattedMessage tagName="span" id={id} values={values} />}
          other={
            <FormattedMessage tagName="span" id={idPlural} values={values} />
          }
        />
      );
    } else if (type === 'date') {
      return (
        <FormattedDate
          tagName="span"
          value={values}
          year="numeric"
          month="short"
          day="2-digit"
        />
      );
    } else if (type === 'time' && values) {
      return <FormattedTime tagName="span" value={values} />;
    } else if (type === 'relative' && values) {
      return (
        <FormattedRelative
          tagName="span"
          value={values}
          updateInterval={1000}
        />
      );
    } else if (type === 'number') {
      if (isNaN(values)) {
        return '';
      }
      return <FormattedNumber value={values || isNaN(values) ? values : 0} />;
    } else if (type === 'currency') {
      if (isNaN(values)) {
        return '';
      }
      return (
        <FormattedNumber
          // eslint-disable-next-line
          style="currency"
          value={values ? values : 0}
          useGrouping={useGrouping}
          currency={currency}
          currencyDisplay="symbol"
          minimumFractionDigits={decimal ? decimal : 0}
          maximumFractionDigits={decimal ? decimal : 0}
        />
      );
    } else {
      return '';
    }
  }
}

export default LocaleTranslator;
