import React from 'react';
import Ts from 'components/LocaleTranslator';
import styles from './index.module.scss';
import defaultImg from './../../../images/invite/bg_table_no_data@2x.png';
import classnames from 'classnames';
const NoData = ({ placeholder, height = '270', imgUrl, className }) => {
  const cls = classnames(styles.tableEmptyPlaceholder, className);
  return (
    <div
      className={cls}
      style={{
        height,
        backgroundImage: `url(${imgUrl ? imgUrl : defaultImg})`,
      }}
    >
      <div className={styles.tableEmptyText}>
        {placeholder ? placeholder : <Ts id="invite.noData" />}
      </div>
    </div>
  );
};

export default NoData;
