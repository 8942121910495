import flatten from 'flat';
const inviteMessage = {
  invite: {
    inviteTitle: 'Invitación',
    bindPhoneTip:
      'Vincular el número de teléfono para participar, ambas partes obtienen una comisión, válida permanentemente.<br>Por favor ve a <span style="font-weight:500;color:#333"> Centro de usuario  -> Configuración de la cuenta  </span>para vincular su número de teléfono.',
    needStep: 'Un paso más',
    createInviteAccountLoadingMessage:
      'Creando código de invitación y subcuenta ...',
    heading:
      'Invita a tus amigos a minar juntos y obtén una <br>recompensa de reembolso del <span style="color:#0A66C2"> 0,2% </span>',
    cutoff:
      '<span style="color:#0A66C2">5% </span>de descuento en comisión para invitados',
    ruleDetails: 'Detalles de la regla',
    totalRebate: 'Comisión total',
    totalRebateTip:
      'La comisión se liquida en la criptomoneda, aquí está la valoración de la moneda fiduciaria, el método de valoración es multiplicar cada criptomoneda pagadera por el tipo de cambio de las 0 en punto (UTC + 0) en la fecha de liquidación y luego sumar la suma.',
    details: 'Detalles',
    totalAccept: 'Invitados',
    minedAccept: 'Invitados minados',
    inviteCode: 'código de invitación',
    copy: 'Dupdo',
    inviteLink: 'Enlace de invitación',
    acceptInviteCode: 'Código invitado',
    acceptInviteCodeTip:
      'Eres un minero invitado y disfrutas de un 5% de descuento en la tarifa del grupo de minería. Puede comprobarlo en el Panel de la piscina> Ganancias> Detalles de ganancias.',
    generatePoster: 'Generar cartel',
    inviteRecord: 'Registro de invitación',
    export: 'Exportar',
    rebateRecord: 'Registro de comisión',
    invitee: 'Invitado',
    inviteeStatus: 'Estado',
    'inviteeStatus-unlocked': 'Despedido',
    'inviteeStatus-registered': 'Registrado',
    'inviteeStatus-mined': 'Minado',
    registerTime: 'Tiempo invitado',
    miningTime: 'Comenzar la mineria',
    miningTimeTip:
      'Cuando el invitado comienza a minar el primer día después de recibir su invitación, se adopta la hora UTC.',
    coinType: 'Divisa',
    region: 'Región Minera',
    inviteSubAccount: 'Subcuenta de comisiones',
    rebatePercent: 'Ratio de comisión',
    op: 'Funcionar',
    originCoinCount: 'Monto',
    specialSubAccountTitle:
      'Hemos creado algunas subcuentas especiales para que reciba una recompensa de comisión. Después de que sus invitados comiencen a minar, la comisión de las ganancias mineras se liquidará en sus subcuentas correspondientes en diferentes monedas y diferentes regiones.',
    specialSubAccountTip:
      'De forma predeterminada, se generan subcuentas de comisiones para EE. UU. Y Europa de la moneda BTC. Cuando su invitado cree una subcuenta minera para otras monedas, generaremos una subcuenta especial correspondiente para usted.',
    rebateOpAddress: 'Gestión de direcciones',
    rebateOpDetail: 'Detalles de la comisión',
    downloadPoster: 'Descargar',
    noData: 'Sin datos',
    gotoBind: 'Ir a unir',
    boundPhone: 'Lo he atado',
    headtips: 'Evento',
  },
};
const inviteMessageFlatten = flatten(inviteMessage);
export default inviteMessageFlatten;
