import flatten from 'flat';
const paginationMessage = {
  pagination: {
    first: '메인',
    last: '마지막 페이지',
    pageSize: '{pageSize}개/페이지',
    currentPage: '제{current}페이지, 총{total}페이지',
    totalInfo: '총{total}페이지',
    goto: '이동',
  },
};
const paginationMessageFlatten = flatten(paginationMessage);
export default paginationMessageFlatten;
