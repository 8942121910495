import flatten from 'flat';
const paginationMessage = {
  pagination: {
    first: 'İlk',
    last: 'Son',
    pageSize: '{pageSize}/sayfa',
    currentPage: 'Sayfa {current}/{total}',
    totalInfo: 'Toplam {total} öğe',
    goto: 'Git',
  },
};
const paginationMessageFlatten = flatten(paginationMessage);
export default paginationMessageFlatten;
