import React from 'react';
import { Button } from 'antd';

import './index.scss';

const BtcButton = ({ children, title, className, variant, ...otherProps }) => (
  <div className={`btc-button-container ${variant}`}>
    <Button {...otherProps} className={`${className} btc-button`}>
      {children}
    </Button>
  </div>
);
export default BtcButton;
