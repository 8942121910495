import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { throttle } from 'lodash';
import { useStores } from 'stores';
import { PageLoadingWrap, NoData } from 'components/widget';
import AccountListPanel from './components/AccountListPanel';
import AccountRegionGroup from './components/AccountRegionGroup';
import OneSwitchModal from './components/OneSwitchModal';
import CreateSubAccountModal from './components/CreateSubAccountModal';
import Locator from './components/Locator';
import styles from './index.module.scss';

function flatCoinSubAccounts(algorithms) {
  // 将不同算法的展开合并为一个子账号名里面
  let coinSubAccounts = [];
  for (const item of algorithms) {
    for (const accoun of item.coin_accounts) {
      coinSubAccounts.push({
        ...accoun,
        algorithms_support_coins: item.support_coins,
      });
    }
  }
  return coinSubAccounts;
}

function validateHashrate(shares_15m) {
  return !(shares_15m === '0' || !shares_15m);
}

/**
 * 数据结构概要（api/v1/account/sub-account/algorithms/morelist）：
 * 一个注册账号可以有多个宏观子账户 subaccounts[] （这里不是指puid，而是指一个子账户名，子账户名下可能有不同算法的多个币种）
 * 一个宏观子账户名，下面可以有多种算法 algorithms[]，每种算法下又有多种币种 coin_accounts[], 子账户名+算法+币种 构成一个最小颗粒度的子账号(即：puid)
 * 一个宏观子账户对应一个 have_other_coins 字段，该字段指示该子账号名下是否可以继续创建其他币种子账号（puid）have_other_coins=1 可以继续添加。如果该宏观子账户创建了矿池平台支持的所有币种，怎不能继续添加其他币种 have_other_coins=0
 * 一个算法下可能支持该算法下不同币种的切换，算法下的 support_coins[]  表明该算法支持的币种数量，如果数量大于1，则可以进行该算法支持的币种的一键切换
 * 一个子账户名可以唯一确定一个区，子账户名在多区全局唯一,不同区不会存在同名的子账户。
 *
 * 算力请求
 * 我们的后端服务是按区域-币种部署，因为多个子账号是跨区的，所以对某个最小单位的子账号（puid）需要单独请求该子账号的算力数据，即请求某个区某个币种的不同子账号可以放到一个请求里去请求，跨区跨币种不可请求算力数据。
 * eg: api/v1/account/sub-account/hashrate-miners?puids=666599,712866&region_id=30 去美国区(region_id=30)请求同币种LTC的不同子账号(666599,712866)
 *
 */
export const AccountListPop = observer(({ togglePopup, visible }) => {
  const containerRef = useRef(null);
  const currentAccountRef = useRef();

  const [scrolling, setScrolling] = useState(false);
  const [locatorVisible, setLocatorVisible] = useState(false);

  const { userStore } = useStores();

  const { algorithmAccounts, hashrateMiners } = userStore;

  // Scroll Control
  const detectScroll = () => {
    if (containerRef.current?.scrollTop > 0 && !scrolling) {
      setScrolling(true);
    } else if (containerRef.current?.scrollTop <= 0 && scrolling) {
      setScrolling(false);
    }
  };

  const isShowLocator = function (element, container) {
    if (element) {
      const { bottom, height, top } = element.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      return !(
        height === 0 ||
        (top <= containerRect.top
          ? containerRect.top - top <= height
          : bottom - containerRect.bottom <= height)
      );
    }
  };

  const handleContainerScroll = () => {
    detectScroll();

    if (isShowLocator(currentAccountRef.current, containerRef.current)) {
      setLocatorVisible(true);
    } else {
      setLocatorVisible(false);
    }
  };

  useEffect(() => {
    const container = containerRef?.current;
    const throttleScroll = throttle(handleContainerScroll, 500);
    container.addEventListener('scroll', throttleScroll);
    return () => {
      container?.removeEventListener('scroll', throttleScroll);
    };
  }, [scrolling, locatorVisible]);

  // Locate to Current Account
  const srollToElement = useCallback((element) => {
    if (containerRef && containerRef.current && element) {
      containerRef.current.scrollTop = element.offsetTop - 16;
      currentAccountRef.current = element;
    }
  }, []);

  const handleLocateAccount = (behavior = 'auto') => {
    if (containerRef && containerRef.current && currentAccountRef) {
      containerRef.current.scroll({
        top: currentAccountRef?.current?.offsetTop - 16,
        behavior: behavior,
      });
    }
  };

  useEffect(() => {
    if (!visible) {
      setLocatorVisible(false);
    }
    setTimeout(() => {
      if (visible) {
        handleLocateAccount('instant');
      }
    }, 40);
  }, [visible]);

  // Search Account
  const [searchKeyword, setSearchKeyword] = useState('');
  const [showZeroHashrate, setShowZeroHashrate] = useState(true);

  const filteredAccounts = useMemo(() => {
    const originAlgorithmAccounts = algorithmAccounts?.data;
    let filterdTmpAccounts = originAlgorithmAccounts;
    if (!showZeroHashrate) {
      // 先筛选算法下算力不为0的精确到币种的子账号
      filterdTmpAccounts = originAlgorithmAccounts
        .map((algorithmLevAccount) => {
          const { algorithms, ...rest } = algorithmLevAccount;

          const noZeroAlgorithms = algorithms
            .map((item) => {
              const { coin_accounts, ...others } = item;
              return {
                coin_accounts: coin_accounts.filter((puidLev) =>
                  validateHashrate(hashrateMiners[puidLev.puid]?.shares_15m),
                ),
                ...others,
              };
            })
            .filter((filterItem) => filterItem.coin_accounts.length > 0);

          return { ...rest, algorithms: noZeroAlgorithms };
        })
        .filter((item) => item?.algorithms?.length > 0); // 如果所有算力下子账号都为0，则整个组不展示
    }

    filterdTmpAccounts = filterdTmpAccounts.filter(
      (item) =>
        !searchKeyword ||
        item.name?.toLowerCase()?.includes(searchKeyword?.toLowerCase()),
    );

    return filterdTmpAccounts;
  }, [searchKeyword, showZeroHashrate, algorithmAccounts, hashrateMiners]);

  useEffect(() => {
    if (visible) {
      setSearchKeyword('');
      setShowZeroHashrate(true);
    }
  }, [visible]);

  const handleSearchAccount = (keyword) => {
    setSearchKeyword(keyword);
  };

  const filterAccountsCount = filteredAccounts?.length ?? 0;

  // 一键切换
  const [switchModalVisible, setSwitchModalVisible] = useState(false);
  const [currentGroup, setCurrentGroup] = useState();
  const handleSwitch = (groupItem) => {
    togglePopup(false);
    setCurrentGroup(groupItem);
    setTimeout(() => {
      setSwitchModalVisible(true);
    }, 200);
  };

  // 创建子账号
  const [createSubAccountModalVisible, setCreateSubAccountModalVisible] =
    useState(false);
  const [createByExist, setCreateByExist] = useState(false);

  const handleCreateSubAccount = useCallback(() => {
    setCreateByExist(null);
    togglePopup(false);
    setCreateSubAccountModalVisible(true);
  }, []);

  // 添加其他币种算法子账号
  const handleClickAddOtherSubAccount = useCallback(
    (subAccountName, regionText, regionKey) => {
      setCreateByExist({ subAccountName, regionText, regionKey });
      togglePopup(false);
      setCreateSubAccountModalVisible(true);
    },
    [togglePopup],
  );

  return (
    <div
      className={`${styles.accountPop} ${
        filterAccountsCount > 0 && scrolling ? styles.hasContent : ''
      }`}
    >
      <AccountListPanel
        showShadow={scrolling}
        togglePopup={togglePopup}
        searchKeyword={searchKeyword}
        onSearch={handleSearchAccount}
        zeroHashrateChecked={showZeroHashrate}
        onChangeZeroHashrate={(checked) => setShowZeroHashrate(checked)}
        onCreateSubAccount={handleCreateSubAccount}
      />
      <div className={`${styles.container}`} ref={containerRef}>
        <PageLoadingWrap loading={algorithmAccounts.firstTimeLoading}>
          <div style={{ width: '100%' }} className={`${styles.containerInner}`}>
            {filterAccountsCount > 0 ? (
              <>
                {filteredAccounts?.map((item) => (
                  <AccountRegionGroup
                    key={item.name + item.region_code + item.region_text}
                    subAccountName={item.name}
                    regionText={item.region_text}
                    coinSubAccounts={flatCoinSubAccounts(item.algorithms)}
                    supportOtherCoin={item.have_other_coins}
                    supportSwitch={item.algorithms.some(
                      (al) => al?.support_coins?.length > 1,
                    )}
                    scrollIfActive={srollToElement}
                    afterChangeAccount={() => togglePopup(false)}
                    onClickSwitch={() => {
                      handleSwitch(item);
                    }}
                    onClickAddOtherSubAccount={() =>
                      handleClickAddOtherSubAccount(
                        item.name,
                        item.region_text,
                        item.region,
                      )
                    }
                  />
                ))}
              </>
            ) : (
              <NoData />
            )}
          </div>
        </PageLoadingWrap>
      </div>
      <Locator
        onClick={() => handleLocateAccount('smooth')}
        visible={locatorVisible && filterAccountsCount > 0}
      />
      {/* 一键切换弹窗 */}
      {switchModalVisible && (
        <OneSwitchModal
          visible={switchModalVisible}
          switchData={currentGroup}
          hideModal={() => {
            setSwitchModalVisible(false);
          }}
        />
      )}
      {/* 创建子账号弹窗 */}
      {createSubAccountModalVisible && (
        <CreateSubAccountModal
          visible={createSubAccountModalVisible}
          createByExist={createByExist}
          hideModal={() => {
            setCreateSubAccountModalVisible(false);
          }}
        />
      )}
    </div>
  );
});

export default AccountListPop;
