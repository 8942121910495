import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Ts from 'components/LocaleTranslator';
import { message } from 'antd';

const Copy = (props) => {
  const { text = '', handleCopy, disableCopiedTip, children } = props;
  if (disableCopiedTip) {
    return (
      <CopyToClipboard text={text} onCopy={handleCopy}>
        {children ? children : <i className="icon-copy" />}
      </CopyToClipboard>
    );
  } else {
  }
  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        message.success(new Ts().getLocaleString('common.copied'), 1);
        handleCopy && handleCopy();
      }}
    >
      {children ? children : <i className="icon-copy" />}
    </CopyToClipboard>
  );
};
export default Copy;
