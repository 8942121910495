import React, { Component } from 'react';
import Ts from 'components/LocaleTranslator';
import styles from './index.module.scss';
import notFoundImage from 'images/version2022/img_not_found@2x.png';

export default class NotFound extends Component {
  render() {
    return (
      <div className={`${styles.notFound} warning-page text-center`}>
        <img src={notFoundImage} alt="Not Found" className={styles.img} />
        <p>
          <Ts id="common.notFoundTip" />
        </p>
      </div>
    );
  }
}
