import React, { useState } from 'react';
import { formatUTCDate } from 'common/utils';
import { useInterval } from 'ahooks';
import SvgIcon from 'components/SvgIcon';
const UTCTimer = () => {
  const [current, setCurrent] = useState(Date.now());

  useInterval(() => {
    setCurrent(Date.now());
  }, 1000);

  return (
    <div className="utc-time font-sm">
      <SvgIcon iconName={'icon_menu_time'} className="margin-right-sm" />{' '}
      <span>{formatUTCDate(current, 'YYYY-MM-DD HH:mm:ss', 'UTC')}</span>
    </div>
  );
};

export default React.memo(UTCTimer);
