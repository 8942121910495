import React from 'react';
import SvgIcon from 'components/SvgIcon';
import styles from '../index.module.scss';

const Locator = ({ visible, onClick }) => {
  return (
    <div
      className={styles.accountLocator}
      onClick={onClick}
      style={
        visible
          ? {
              visibility: 'visible',
              opacity: 1,
            }
          : {
              visibility: 'hidden',
              opacity: 0,
            }
      }
    >
      <SvgIcon iconName="icon_subaccount_position" />
    </div>
  );
};

export default Locator;
