import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Modal } from 'antd';
import Cookies from 'js-cookie';
import Ts from 'components/LocaleTranslator';
import LoadingIcon from 'components/widget/LoadingIcon';
import ReactGA from 'react-ga';
import './index.scss';
import { getLocaleConfig } from 'common/utils';

@withRouter
@inject('store')
@observer
class HeaderLoginButtons extends Component {
  state = {
    loading: false,
    visible: false,
    modalType: 'signIn',
    isShowIframeOverlay: true,
  };

  constructor(props) {
    super(props);
    this.userStore = this.props.store.userStore;
    this.appStore = this.props.store.appStore;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isAuthenticated, isRefreshingNewToken } = nextProps.store.userStore;
    const location = nextProps.location;
    // 根据url hash 弹出登录、注册窗口
    if (location && location.hash) {
      if (!isAuthenticated) {
        if (['#login', '#register'].indexOf(location.hash) > -1) {
          if (isRefreshingNewToken) {
            return null;
          }
          nextProps.history.replace(location.pathname, location.state);
          return {
            visible: true,
            modalType:
              nextProps.location.hash === '#register' ? 'signUp' : 'signIn',
          };
        }
      } else if (!isRefreshingNewToken && location.pathname === '/') {
        if (location.state && location.state.from) {
          const from = location.state.from;
          nextProps.history.replace(from.pathname, from.state);
        }
      }
    }

    return null;
  }

  showModal = (e, modalType = 'signIn') => {
    this.setState({
      visible: true,
      modalType,
      isShowIframeOverlay: modalType !== this.state.modalType,
    });
    this.sendEvent(modalType === 'signIn' ? 'clickLogin' : 'clickRegiter', '');
  };

  handleCancel = () => {
    this.setState({ visible: false, isShowIframeOverlay: true });
  };

  receiveMessageFromIframePage = (event) => {
    if (event && event.data) {
      if (event.data.event === 'close iframe') {
        const date = new Date();
        date.setDate(date.getDate() + 15);
        Cookies.set('user-jwt-expire', date.getTime(), {
          expires: 15,
        });
        this.userStore.login((subAccountInfo) => {
          // 判断是否有子账户，没有就回跳转欢迎页面
          if (!subAccountInfo.puid) {
            this.props.history.push('/welcome');
          }
          // 登录完成后，跳转回原地址
          const location = this.props.location;
          if (location.state && location.state.from) {
            const from = location.state.from;
            this.props.history.push(from);
          }
        });
        this.setState({
          visible: false,
          isShowIframeOverlay: true,
        });
      }
    }
  };

  logout = () => {
    this.userStore.logout();
  };

  handleFileLoad = () => {
    this.setState({
      isShowIframeOverlay: false,
    });
  };

  sendEvent = (eventAction, eventLabel) => {
    ReactGA.event(
      {
        category: 'Account',
        action: eventAction,
        label: eventLabel,
      },
      ['poolGA'],
    );
    global._hmt &&
      global._hmt.push(['_trackEvent', 'Account', eventAction, eventLabel]);
  };

  componentDidMount() {
    //监听message事件
    window.addEventListener(
      'message',
      this.receiveMessageFromIframePage,
      false,
    );
  }

  render() {
    const { visible, modalType, isShowIframeOverlay } = this.state;
    const { isAuthenticated } = this.userStore;
    const { lang } = this.appStore;
    const ucLang = getLocaleConfig(lang).ucPath;

    return (
      <div className="login-container">
        <div className="login-btns">
          <button className="login-btn-sign-in" onClick={this.showModal}>
            <Ts id="pages.homepage.signIn" />
          </button>
          <button
            className="login-btn-sign-up"
            onClick={(e) => {
              this.showModal(e, 'signUp');
            }}
          >
            <Ts id="pages.homepage.signUp" />
          </button>
        </div>
        <Modal
          className="login-modal"
          width="360"
          height="590"
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          closable={false}
        >
          <div
            className={`login-frame ${
              isShowIframeOverlay && 'overlay-container'
            }`}
            key={modalType}
          >
            {!isAuthenticated && (
              <Fragment>
                {isShowIframeOverlay && (
                  <div className="overlay-content">
                    <LoadingIcon />
                  </div>
                )}
                <iframe
                  id="login_iframe"
                  title="user center"
                  onLoad={this.handleFileLoad}
                  // src="test.html"
                  src={
                    process.env.REACT_APP_USER_CENTER +
                    (modalType === 'signUp' ? '/#/sign-up' : '')
                  }
                />
              </Fragment>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}
export default HeaderLoginButtons;
