import React, { useState } from 'react';
import { Input } from 'antd';
import SvgIcon from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { debounce } from 'lodash';

const SearchInput = ({
  onSearch,
  onChange,
  value,
  placeholder,
  width = '100%',
}) => {
  const [isFocus, setFocus] = useState(false);
  const { t } = useTranslation('common');
  return (
    <div
      className={`${styles.searchInput} ${isFocus ? styles.focused : ''}`}
      style={{ width }}
    >
      <Input
        addonBefore={
          <span style={{ color: '#8EA6BF' }}>
            <SvgIcon iconName="icon_search" size="xxs" />
          </span>
        }
        onPressEnter={debounce((e) => {
          onSearch?.(e.target.value);
        }, 1000)}
        onChange={(e) => {
          onChange?.(e.target.value);
        }}
        value={value}
        placeholder={placeholder ?? t('Search')}
        spellCheck={false}
        allowClear
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </div>
  );
};
export default SearchInput;
