import { runInAction, makeAutoObservable } from 'mobx';
import axios from 'axios';
import { lowerCase } from 'common/utils';

class EthForkStore {
  ethForkInfo = {};
  loadingStats = false;
  loadingChart = false;
  currentHashrateData = {};
  currentETCChartData = {};
  currentETHWChartData = {};
  currentETHFChartData = {};
  constructor() {
    makeAutoObservable(this); //升级mobx 6之后禁止使用装饰器，然后需要使用makeAutoObservable
  }

  /**
   * 获取ETH分叉币数据
   */
  getEthForkInfo = async () => {
    try {
      this.loadingStats = true;
      const data = await fetch(
        `https://innerapi.btcm.group/chaininfo/info/baseinfo`,
      );
      const res = await data.json();
      if (res && res.info) {
        runInAction(() => {
          this.ethForkInfo = res.info;
          this.loadingStats = false;
        });
      }
    } catch (error) {
      this.loadingStats = false;
    }
  };

  getCurrentCoinHashrateData = async (
    currentCoin,
    chartTimeRange,
    triggerLoading = true,
    callback,
  ) => {
    if (triggerLoading) {
      this.loadingChart = true;
    }
    try {
      const data = await fetch(
        `https://innerapi.btcm.group/chaininfo/info/f2pooldata?coin=${lowerCase(
          currentCoin,
        )}&days=${chartTimeRange}`,
      );
      const res = await data.json();
      if (res && res.list) {
        runInAction(() => {
          if (lowerCase(currentCoin) === 'etc') {
            this.currentETCChartData = {
              list: res.list,
            };
          } else if (lowerCase(currentCoin) === 'ethf') {
            this.currentETHFChartData = {
              list: res.list,
            };
          } else if (lowerCase(currentCoin) === 'ethw') {
            this.currentETHWChartData = {
              list: res.list,
            };
          }
          this.loadingChart = false;
        });
        callback && callback();
      }
    } catch (err) {
      this.loadingChart = false;
    }
  };

  getTimeRangeMap = (type, timeRange) => {
    let mapper = {
      hashrate: {
        '1d': {
          count: 168,
          xAxisFormatter: 'MM/DD',
        },
        '1h': {
          count: 72,
          xAxisFormatter: 'HH:mm',
        },
      },
      price: {
        '1d': {
          count: 30,
          xAxisFormatter: 'MM/DD',
        },
        '1h': {
          count: 72,
          xAxisFormatter: 'HH:mm',
        },
      },
    };
    return mapper[type][timeRange];
  };
}

const ethForkStore = new EthForkStore();

export { ethForkStore };
