import {
  observable,
  action,
  runInAction,
  reaction,
  makeObservable,
} from 'mobx';
import axios from 'axios';
import { getTimeRangeMap } from 'common/utils';
class MinerInfoStore {
  //#region Stats Info
  @observable
  loadingStats;

  @observable
  minerId;

  @observable
  minerStatsInfo;
  //#endregion

  //#region History Chart
  @observable
  chartTimeRange;

  @observable
  loadingHashHistory;

  @observable
  sharesUnit;

  @observable
  isProxy;

  @observable
  minerHashHistoryChartData;
  //#endregion

  constructor() {
    makeObservable(this);
    this.loadingStats = false;
    this.minerId = null;
    this.minerStatsInfo = {
      shares_15m: '-',
      shares_unit: 'TH/s',
      shares_1d: '-',
      shares_1d_unit: 'TH/s',
      reject_percent: '-',
      worker_status: '-',
      last_share_time: '-',
      last_share_ip: '-',
    };

    this.loadingHashHistory = false;
    this.chartTimeRange = '1h';
    this.minerHashHistoryChartData = [];
    this.sharesUnit = 'T';
    this.isProxy = 1;

    reaction(
      () => this.minerId,
      (minerId) => {
        this.getMinerStatsInfo(minerId);
        this.getMinerHashHistory(minerId);
      },
    );

    reaction(
      () => this.chartTimeRange,
      (chartTimeRange) => {
        this.getMinerHashHistory(chartTimeRange);
      },
    );
  }

  //#region Stats Section

  @action
  setMinerId = (minerId) => {
    this.minerId = minerId;
  };

  @action
  setIsProxy = (isProxy) => {
    this.isProxy = isProxy;
  };

  @action
  getMinerStatsInfo = async (triggerLoading = true) => {
    if (triggerLoading) {
      this.loadingStats = true;
    }
    const res = await axios.get(`worker/${this.minerId}`, {
      params: {
        proxy: this.isProxy || 0,
      },
    });
    if (res && res.data) {
      runInAction(() => {
        this.minerStatsInfo = res.data.data;
      });
    }
    this.loadingStats = false;
  };

  //#region History Chart
  @action
  setChartTimeRange = (timeRange) => {
    this.chartTimeRange = timeRange;
  };

  @action
  getMinerHashHistory = async (triggerLoading = true) => {
    if (triggerLoading) {
      this.loadingHashHistory = true;
    }

    let timeRangeMap = getTimeRangeMap(this.chartTimeRange);
    const { start_ts, count } = timeRangeMap;
    const res = await axios.get(`worker/${this.minerId}/share-history`, {
      params: {
        proxy: this.isProxy || 0,
        dimension: this.chartTimeRange,
        start_ts,
        real_point: 1,
        count,
      },
    });
    if (res && res.data) {
      runInAction(() => {
        this.sharesUnit = res.data.shares_unit;
        this.minerHashHistoryChartData = res.data.tickers.map((t) => {
          return {
            time: t[0] * 1000,
            hashrate: Number(t[1]),
            reject_percent: Number(t[2]) * 100,
          };
        });
        this.loadingHashHistory = false;
      });
    }
  };
  //#endregion
}

const minerInfoStore = new MinerInfoStore();

export { minerInfoStore };
