import React from 'react';
import styles from './index.module.scss';

const BetaIcon = ({ size = 'md', className = '' }) => {
  return (
    <span className={`${styles.betaIcon} ${styles[size]} ${className}`}>
      <span className={size === 'sm' ? styles.ultr01Font : ''}>Beta</span>
    </span>
  );
};

export default BetaIcon;
