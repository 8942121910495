import { LOCALES_MAP } from './localeConfig';

const explorerBaseURL = process.env.REACT_APP_EXPLORER_URL;
const toolsBtcDomain = process.env.REACT_APP_TOOLS_BTC_DOMAIN;
const helpCenterDomain = process.env.REACT_APP_HELP_CENTER_DOMAIN;
const ordinalsExplorer = process.env.REACT_APP_ORDINALS_EXPLORER_DOMAIN;
const navigationUrl = process.env.REACT_APP_NAVIGATION_SITE_URL;

export const ToolsNavsConfig = [
  {
    slug: 'nav-tools-accelerate',
    path: '/{{lang}}/tools/accelerate',
    isOuterLink: true,
    isHot: true,
    groupId: 0,
    picName: 'tools/TransactionAcceleratorCPFP',
  },
  {
    slug: 'nav-tools-address-txreverse',
    path: '/{{lang}}/tools/txreverse',
    isOuterLink: true,
    groupId: 0,
    isHot: true,
    picName: 'tools/TransactionReversal',
  },
  {
    slug: 'nav-tools-acceleratebatch',
    path: '/{{lang}}/tools/acceleratebatch',
    isOuterLink: true,
    groupId: 0,
    picName: 'tools/TransactionAcceleratorCPFP',
  },
  {
    slug: 'nav-tools-address-increaseminerfee',
    path: '/{{lang}}/tools/increaseminerfee',
    isOuterLink: true,
    groupId: 0,
    picName: 'tools/IncreaseTxnFees',
  },
  {
    slug: 'nav-runes',
    path: `/{{lang}}/tools/runes`,
    isOuterLink: true,
    groupId: 0,
    picName: 'tools/runes',
    // tagName: 'runes-free',
    isHot: true,
  },
  {
    slug: 'nav-tools-address-monitoring',
    path: '/{{lang}}/tools/addressmonitor',
    isOuterLink: true,
    isHot: true,
    groupId: 1,
    picName: 'tools/AddressMonitoring',
  },
  {
    slug: 'nav-tools-address-detail',
    path: `/{{lang}}/tools/addressdetail`,
    isOuterLink: true,
    groupId: 1,
    picName: 'tools/addressdetail',
  },
  {
    slug: 'nav-tools-address-report',
    path: `/{{lang}}/tools/addressreport`,
    isOuterLink: true,
    groupId: 1,
    picName: 'tools/addressreport',
  },
  {
    slug: 'nav-tools-address-graph',
    path: `/{{lang}}/tools/addressgraph`,
    isOuterLink: true,
    groupId: 1,
    picName: 'tools/AddressGraph',
  },
  {
    slug: 'nav-tools-APIService',
    path: '/{{lang}}/tools/btcnodeapi',
    isOuterLink: true,
    isHot: true,
    groupId: 2,
    picName: 'tools/APIService',
  },
  {
    slug: 'nav-tools-address-doublespend',
    path: '/{{lang}}/tools/doublespend',
    isOuterLink: true,
    groupId: 2,
    picName: 'tools/TransactionDouble-spending',
  },
  {
    slug: 'nav-tools-bulkpayment',
    path: '/{{lang}}/tools/bulkpayments',
    isOuterLink: true,
    groupId: 2,
    picName: 'tools/bulkpayments',
  },
  {
    slug: 'nav-tools-pickupsats',
    path: `/{{lang}}/tools/pickupraresats`,
    isOuterLink: true,
    groupId: 2,
    picName: 'tools/pickupraresats',
  },
  {
    slug: 'nav-tools-special',
    path: '/{{lang}}/tools/special',
    isOuterLink: true,
    groupId: 3,
    picName: 'tools/RareSatsQuery',
  },
  {
    slug: 'nav-tools-TransactionBroadcast',
    path: '/{{lang}}/tools/broadcast',
    isOuterLink: true,
    groupId: 3,
    picName: 'tools/TransactionBroadcast',
  },
  {
    slug: 'nav-tools-receipt',
    path: '/{{lang}}/tools/receipt',
    isOuterLink: true,
    groupId: 3,
    picName: 'tools/TransactionReceipt',
  },
  {
    slug: 'nav-navigation',
    path: '/{{lang}}/tools/navigator',
    isOuterLink: true,
    groupId: 3,
    level: 3,
  },
];
/**
 * 导航菜单配置项
 */
export const getNavigationItems = (lang) => {
  const explorerURL = `${explorerBaseURL}/${lang}`;
  const toolsURL = `${toolsBtcDomain}`;
  return [
    {
      slug: 'nav-explorer',
      path: explorerURL,
      level: 1,
      isOuterLink: true,
      subItems: [
        {
          slug: 'nav-explorer-btc',
          path: `${explorerURL}/btc`,
          isSpecificChain: true,
          tag: 'BTC',
          iconName: 'icon_chain_btc',
          isOuterLink: true,
          level: 2,
        },
        {
          slug: 'nav-explorer-eth',
          path: `${explorerURL}/eth`,
          isSpecificChain: true,
          tag: 'ETH',
          iconName: 'icon_chain_eth',
          isOuterLink: true,
          level: 2,
        },
        {
          slug: 'nav-explorer-bch',
          path: `${explorerURL}/bch`,
          isSpecificChain: true,
          tag: 'BCH',
          iconName: 'icon_chain_bch',
          isOuterLink: true,
          level: 2,
        },
        {
          slug: 'nav-explorer-etc',
          path: `${explorerURL}/etc`,
          isSpecificChain: true,
          tag: 'ETC',
          iconName: 'icon_chain_etc',
          isOuterLink: true,
          level: 2,
        },
        {
          slug: 'nav-explorer-ltc',
          path: `${explorerURL}/ltc`,
          isSpecificChain: true,
          tag: 'LTC',
          iconName: 'icon_chain_ltc',
          isOuterLink: true,
          level: 2,
        },
      ],
    },
    {
      slug: 'nav-pool',
      path: '/',
      level: 1,
      subNavExtendWidth: 0,
      subItems: [
        {
          slug: 'nav-pool-home',
          path: '/',
          iconName: 'icon_pool_home',
          level: 2,
        },
        {
          slug: 'nav-pool-data',
          path: '/pool-stats',
          iconName: 'icon_pool_data',
          level: 2,
        },
        // {
        //   slug: 'nav-pool-candy',
        //   path:
        //     'https://candy.btc.com/?utm_source=pool_web&utm_medium=none&utm_campaign=candy',
        //   iconName: 'icon_pool_candy',
        //   isOuterLink: true,
        //   level: 2,
        // },
        {
          slug: 'nav-pool-tools',
          path: '/tools',
          iconName: 'icon_pool_tools',
          level: 2,
        },
        {
          slug: 'nav-pool-cs',
          path: `${helpCenterDomain}/hc/${LOCALES_MAP[lang].hcPath}/sections/360003116872`,
          iconName: 'icon_pool_cs',
          isOuterLink: true,
          level: 2,
        },
      ],
    },
    // {
    //   slug: 'nav-wallet',
    //   path: 'https://wallet.btc.com',
    //   isOuterLink: true,
    //   level: 1,
    // },
    {
      slug: 'nav-tools',
      path: toolsURL,
      isOuterLink: true,
      hasChildren: true,
      level: 1,
      isSpecificChain: true,
      subNavExtendWidth: 760,
      subNavCnExtendWidth: 660,
      mulipleColumns: true,
      subItems: ToolsNavsConfig,
      supportLangs: ['zh-CN', 'en'],
    },
    {
      slug: 'nav-brc20',
      path: `${ordinalsExplorer}/en`,
      isOuterLink: true,
      level: 1,
      subNavExtendWidth: 0,
      subItems: [
        {
          slug: 'nav-explorer',
          path: `${ordinalsExplorer}/en`,
          isOuterLink: true,
          level: 2,
        },
        {
          slug: 'nav-inscriptions',
          path: `${ordinalsExplorer}/en/inscribe`,
          isOuterLink: true,
          level: 2,
        },
      ],
    },
    // {
    //   slug: 'nav-runes',
    //   path: `${toolsURL}/{{lang}}/tools/runes`,
    //   isOuterLink: true,
    //   level: 1,
    //   tagName: 'runes-free',
    // },
    {
      slug: 'nav-runes-main',
      path: `${ordinalsExplorer}/en/runes`,
      isOuterLink: true,
      level: 1,
      subNavExtendWidth: 0,
      tagName: 'new',
      subItems: [
        {
          slug: 'nav-runes-explorer',
          path: `${ordinalsExplorer}/en/runes`,
          // iconName: 'icon_pool_home',
          isOuterLink: true,
          level: 2,
        },
        {
          slug: 'nav-runes',
          path: `${toolsURL}/{{lang}}/tools/runes`,
          isOuterLink: true,
          // tagName: "runes-free",
          isHot: true,
          level: 2,
        },
      ],
    },
    // {
    //   slug: 'nav-help',
    //   path: `https://help.cloverpool.com/hc/${LOCALES_MAP[lang].hcPath}`,
    //   isOuterLink: true,
    //   level: 1,
    // },
    {
      slug: 'nav-app',
      path: '/{{lang}}/app-download',
      level: 1,
    },
    // {
    //   slug: 'nav-inscriptions',
    //   path: `https://explorer.btc.com/${lang}/btc/ordinals/1`,
    //   isOuterLink: true,
    //   level: 1,
    // },
    // {
    //   slug: 'nav-collaborate',
    //   path: '/bitdeer',
    //   level: 1,
    //   hide: lang && lang.indexOf('zh') > -1, // 繁体简体中文下不显示
    // },
  ];
};
export const tagLink = {
  'runes-free': {
    en: `${helpCenterDomain}/hc/en-us/articles/35965571788953-Limited-Time-Free-0-Service-Fee-Minting-Runes-and-Giveaway-of-UNCOMMON-GOODS`,
    'zh-CN': `${helpCenterDomain}/hc/zh-cn/articles/35965571788953-%E9%99%90%E6%97%B6%E7%89%B9%E5%85%8D-0%E6%9C%8D%E5%8A%A1%E8%B4%B9%E9%93%B8%E9%80%A0Runes%E7%AC%A6%E6%96%87-%E5%85%8D%E8%B4%B9%E8%B5%A0%E9%80%81UNCOMMON-GOODS`,
  },
};
