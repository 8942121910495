import appLocaleData from 'react-intl/locale-data/de';
import common from './de.common';
import pages from './de.pages';
import pagination from './de.pagination';
import invite from './de.invite';

window.appLocale = {
  messages: Object.assign({}, common, pages, pagination, invite),

  locale: 'de',
  data: appLocaleData,
};

export default window.appLocale;
